import { Injectable } from '@angular/core';
import { CanActivate, CanActivateChild, CanLoad, Route, UrlSegment, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import { Observable, Subscription } from 'rxjs';
import { Store } from '@ngrx/store';
import { AppState } from './source/appstates/appState';
import { LoginState } from './source/appstates/loginstates/loginState';
import { tokenState } from './source/appstates/sblogin/sbloginState';

@Injectable({
  providedIn: 'root'
})
export class RouteauthGuard implements CanActivate, CanActivateChild, CanLoad {
  playerLoggedIn:boolean=false;
  
  private profilestoreSubslots: Subscription;
  private bettokenstoreSub: Subscription;
  private logoutstore: Subscription;
  constructor(private store: Store<AppState>, private router:Router){

  }
  ngOnInit(){
 
    this.logoutstore = this.store.select("loginState").subscribe(
      (loginState: LoginState) => {
        if (loginState.playerLoggedIn) {
            this.playerLoggedIn = loginState.playerLoggedIn.loggedIn;
          
        }
      }
    )
   
  }
  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
      let token = sessionStorage.getItem('accessToken');
      if (this.playerLoggedIn == true) {
        return true;
      } else if (this.playerLoggedIn == false) {
        this.router.navigate(['/preMatch']);
        return false;
      }
  }
  canActivateChild(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
      let token = sessionStorage.getItem('accessToken');
      if (this.playerLoggedIn == true) {
        return true;
      } else if (this.playerLoggedIn == false) {
        this.router.navigate(['/preMatch']);
        return false;
      }
  }
  canLoad(
    route: Route,
    segments: UrlSegment[]): Observable<boolean> | Promise<boolean> | boolean {
      let token = sessionStorage.getItem('accessToken');
      if (this.playerLoggedIn == true) {
        return true;
      } else if (this.playerLoggedIn == false) {
        this.router.navigate(['/preMatch']);
        return false;
      }
  }
}
