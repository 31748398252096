import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import {environment} from '@app/env';
import { Observable } from 'rxjs';
@Injectable({
  providedIn: 'root'
})
export class TokenserviceService {

  constructor(private httpClient:HttpClient) { }
  private httpOptions = {
    headers: new HttpHeaders({
      'Content-Type':  'application/json',
      'siteid': environment.skinId,
    })
  };
  onGetSportsBookToken(data){
    return this.httpClient.post(`${environment.sbBook.sbBaseUrl}${environment.sbBook.sblogin}`,data , this.httpOptions);
  }

}
