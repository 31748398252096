import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-virtual-tc',
  templateUrl: './virtual-tc.component.html',
  styleUrls: ['./virtual-tc.component.css']
})
export class VirtualTCComponent implements OnInit {

  constructor() { }

  ngOnInit() {
    window.scroll(0,0);
  }

}
