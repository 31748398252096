import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-terms-and-condtions',
  templateUrl: './terms-and-condtions.component.html',
  styleUrls: ['./terms-and-condtions.component.css']
})
export class TermsAndCondtionsComponent implements OnInit {

  constructor() { }

  ngOnInit() {
    window.scroll(0,0);
  }

}
