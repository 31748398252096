import { Component, OnInit, ViewChild } from '@angular/core';
import { PasswordsComponent } from '../passwords/passwords.component';
import { SlotsService } from 'src/app/source/services/slots.service';
import { BehaviorSubject, Observable, Subscription } from 'rxjs';
import { Store } from '@ngrx/store';
import * as appState from 'src/app/source/appstates/appState';
import { slotsData, casinogmaelunch, KeyVsValue } from 'src/app/source/modules/slotsmodel/slotsdata';
import { RootObject, Game } from 'src/app/source/modules/slotsmodel/slotsvalue';
import { buildRootObject, buildGame } from 'src/app/source/modules/slotsmodel/builsslots';
import { environment } from '@app/env';
import { CoreEnvironment } from '@angular/compiler/src/compiler_facade_interface';
import * as cashierActions from 'src/app/source/appstates/cashierstates/cashierActions';
import { tokenState } from '../../source/appstates/sblogin/sbloginState';
import { LoginState } from 'src/app/source/appstates/loginstates/loginState';
import { CasinoGames, Casino } from 'src/app/source/modules/goldenraceinterface/goldenrace';

import { Router, ActivatedRoute } from '@angular/router';
declare var $: any;
@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.css']
})
export class FooterComponent implements OnInit {
  @ViewChild(PasswordsComponent, { static: false }) passwords: PasswordsComponent;
  private profilestoreSubslots: Subscription;
  private bettokenstoreSub: Subscription;
  private logoutstore: Subscription;
  today: Date;
  topSlotsList: RootObject;
  topSlotsGames: Array<Game>;
  playerLoggedIn: boolean = false;
  imagespath = environment.slotsiamgepath;
  keyname:string;
  tokendata:any=null;
  casinomessage:string;
  slotsdata: slotsData;
  casinogamessuccessPop:boolean = false;
  baseurl = environment.appApi.baseUrl;
  showMyContainer: boolean = false;
  gameopenstatus:boolean=false;
  constructor(private slotsservice: SlotsService, private store: Store<appState.AppState>) {
    this.slotsservice.TopSlotsJson().subscribe(topSlots => { this.topSlotsGamesList(topSlots) });
  }

  ngOnInit() {
    this.today = new Date();
    $(document).ready(function () {
      $(".scrollTop").click(function () {
        $(window).scrollTop(0);
      });

      // $(".down_topgames").click(function () {
      //   $('.topGameList').hide();
      // });

    });

    this.logoutstore = this.store.select("loginState").subscribe(
      (loginState: LoginState) => {
        if (loginState.loginResponse) {
          this.playerLoggedIn=loginState.playerLoggedIn.loggedIn;
        }
      }
    )

  
  }
  comingsoon() {
    this.passwords.comingSoonPopOpen()

  }
  
  hitService() {
    let profile = sessionStorage.getItem('profile');
    this.slotsservice.httpClient(profile).subscribe(data => { this.slotslunch(data) });
  };
  slotsinlogout(data) {
    if (this.playerLoggedIn == true) {
      this.store.dispatch(new cashierActions.CashierGetBalanceStart());
      this.keyname = data;
      if (this.tokendata == null) {
        this.hitService();
      } else if (this.tokendata != null) {
        window.open(this.slotsdata.HABANERO_GAMING_URL + 'brandid=' + this.slotsdata.BRAND_ID + '&keyname=' + data + '&token=' + this.slotsdata.TOKEN + '&mode=real&locale=en', "_blank");
        return;
      }
    } else if (this.playerLoggedIn == false) {
      this.casinomessage = "Please login";
      this.casinogamessuccessPop = true;
    }
  }
  slotslunch(data) {
    if (data) {
      this.slotsdata = data;
      this.tokendata = this.slotsdata.TOKEN;
      return window.open(this.slotsdata.HABANERO_GAMING_URL+'brandid='+this.slotsdata.BRAND_ID+'&keyname='+this.keyname+'&token='+this.slotsdata.TOKEN+'&mode=real&locale=en', "_blank");

    }
  }
  topSlotsGamesList(topSlots) {
    this.topSlotsList = topSlots;
    if (this.topSlotsList) {
      this.topSlotsGames = this.topSlotsList.Games;
    }
  }
  casinogamessuccessPopClose(){
    this.casinomessage="";
    this.casinogamessuccessPop=false;
}
gamesopen(){
  this.gameopenstatus=true;
}
gamesclose(){
  this.gameopenstatus=false;
}
}
