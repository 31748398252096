import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-our-rights',
  templateUrl: './our-rights.component.html',
  styleUrls: ['./our-rights.component.css']
})
export class OurRightsComponent implements OnInit {

  constructor() { }

  ngOnInit() {
    window.scroll(0,0);
  }

}
