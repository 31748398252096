import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import * as cashierActions from 'src/app/source/appstates/cashierstates/cashierActions';
import { Store } from '@ngrx/store';
import * as appState from 'src/app/source/appstates/appState';
import * as playerActions from 'src/app/source/appstates/playerstates/playerActions';
import { Subscription } from 'rxjs';
import { CashierState } from 'src/app/source/appstates/cashierstates/cashierState';
import { TBankAccountInfo } from 'src/app/source/modules/cashier/bankAccountInfo';

import { WitdrawSelect, Datum } from 'src/app/source/modules/cashier/witdrawselect';
@Component({
  selector: 'app-bankaccount',
  templateUrl: './bankaccount.component.html',
  styleUrls: ['./bankaccount.component.css']
})
export class BankaccountComponent implements OnInit {

  private cashierwithdrawstoreSub:Subscription;
  form: FormGroup;
  private storeSub: Subscription;
  private viewstoreSub: Subscription;
  addbankerrormessage: string;
  successmessage: string;
  bankAccountInfos: TBankAccountInfo[]; WitdrawSelect:WitdrawSelect;
  witdrawselectdata:Array<Datum>;
  errormessage: string;
  accountinfo:any;
  constructor(private store: Store<appState.AppState>) {
    this.store.dispatch(new cashierActions.CashierGetBalanceStart());
    this.store.dispatch(new cashierActions.CashierGetBankAccount());
  }

  ngOnInit() {
    this.store.dispatch(new cashierActions.ResetState());
    this.form = new FormGroup({
      "bankAccountNumber": new FormControl(null, [Validators.required, Validators.pattern('[0-9]{9,18}')]),
      "bankName": new FormControl(null),
      //"bankName": new FormControl(null, [Validators.required, Validators.pattern('^[a-zA-Z ]*$')]),
      //"accountBranchName": new FormControl(null, [Validators.required, Validators.pattern('^[a-zA-Z ]*$')]),
      "nameOnAccount": new FormControl(null, [Validators.required, Validators.pattern('^[a-zA-Z ]*$')]),
      "accountType": new FormControl(null, [Validators.required]),
      //"personalNumber": new FormControl(null, [Validators.required, Validators.pattern('^[A-Za-z]{4}[a-zA-Z0-9]{7}$')]),
    });
    this.storeSub = this.store.select("cashierState").subscribe(
      (cashierState: CashierState) => {
        if (cashierState.addBankAccountResponse) {
          if (cashierState.addBankAccountResponse.success === true) {
            this.addbankerrormessage="";
            //this.successmessage = cashierState.addBankAccountResponse.description;
            this.form.reset();
          } else if (cashierState.addBankAccountResponse.success === false) {
            this.successmessage="";
            this.addbankerrormessage = cashierState.addBankAccountResponse.description;
          }
        }
        if (cashierState.addBankAccountError) {
          this.successmessage="";
          this.addbankerrormessage = cashierState.addBankAccountError.message;
        }
      });
    this.viewstoreSub = this.store.select("cashierState").subscribe(
      (cashierState: CashierState) => {
        if (cashierState.bankAccountInfo) {
          if (cashierState.bankAccountInfo.success == true) {
            if (cashierState.bankAccountInfo.TBankAccountInfos) {
              this.errormessage = "";
              this.accountinfo=cashierState.bankAccountInfo.activeBankCount;
              this.bankAccountInfos = cashierState.bankAccountInfo.TBankAccountInfos;
            }
          }else if(cashierState.bankAccountInfo.success == false) {
            this.errormessage = cashierState.bankAccountInfo.description;
          }
        }
        if(cashierState.bankAccountInfoError) {
          this.errormessage = cashierState.bankAccountInfoError.message;
        }
      })
      this.cashierwithdrawstoreSub = this.store.select("cashierState").subscribe(
        (cashierState: CashierState) => {
          if (cashierState.witdrawselect) {
            if (cashierState.witdrawselect.status == true) {
              if(cashierState.witdrawselect.data)
             this.witdrawselectdata = cashierState.witdrawselect.data;
            } else if (cashierState.withDrawRequestResponse.success == false) {
            }
          }
         
        }
        );
      
    this.store.dispatch(new cashierActions.CashierWithdrawSelectRequest());
  }
  onFormSubmit() {
    // for(let data of this.witdrawselectdata){
    //   if(data.code == this.form.value.accountType){
            // this.form.value.bankName = data.name;
            this.form.value.bankName =this.form.value.accountType;
            this.store.dispatch(new cashierActions.CashierGetBalanceStart());
            this.store.dispatch(new cashierActions.CashierAddBankAccount(this.form.value));
            return;
    //   }
    // }
  }
  // onCloseAccount(encodedId) {
  //   this.store.dispatch(new cashierActions.CashierGetBalanceStart());
  //   let obj = { "bankEncodedIDs": [encodedId] }
  //   this.store.dispatch(new cashierActions.CashierDeleteBankAccount(obj));
  // }
  ngOnDestroy() {
    if (this.storeSub) {
      this.storeSub.unsubscribe();
    }
    if (this.viewstoreSub) {
      this.viewstoreSub.unsubscribe();

    }
    if (this.cashierwithdrawstoreSub) {
      this.cashierwithdrawstoreSub.unsubscribe();
    }
  }
}
