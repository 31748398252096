import { Actions, Effect, ofType } from '@ngrx/effects';
import { Injectable } from '@angular/core';
import * as tokenActions from './sbloginAction';
import { exhaustMap, map, catchError, tap } from 'rxjs/internal/operators';
import { TokenserviceService } from '../../services/sbtoken/tokenservice.service';
import { HttpErrorResponse } from '@angular/common/http';
import { of } from 'rxjs';
import { sBookLogin } from '../../modules/sblogin/sblogin';
import { Router } from '@angular/router';
import {SlotsService} from "../../services/slots.service"

//import { ToastrService } from 'ngx-toastr';
import { LoginService } from '../../services/login/login.service';
import { slotsData } from '../../modules/slotsmodel/slotsdata';
import { GoldenraceService } from '../../services/goldenraceservice/goldenrace.service';
import { GoldenRaceGameLaunch } from '../../modules/goldenraceinterface/goldenrace';
const handleError = (error: any, actions: any) => {
    if (error.error instanceof Error) {
        return of(new actions({ "message": error.error.message }));
    } else {
        try {
            if (error.error.message) {
                return of(new actions({ "message": error.error.message }));
            } else if (error.message) {
                return of(new actions({ "message": error.message }));
            }
            return of(new actions({ "message": "Something went wrong please contact admin" }));
        } catch (error) {
           // this.toastr.error(error.message);
            //this.router.navigate(["/"]);
        }
    }
    return of(new actions({ "message": "Something went wrong please contact admin" }));
}
@Injectable()
export class tokenEffects {
    constructor(private actions$: Actions,private SlotsService:SlotsService,
        private TokenserviceService: TokenserviceService, private goldenraceservice: GoldenraceService,
       // private toastr: ToastrService,
        private loginservice: LoginService,
        private router: Router) { }
    @Effect()
    PlayerGetToken = this.actions$.pipe(
        ofType(tokenActions.PLAYER_GET_TOKEN),
        exhaustMap((onGetSportsBookToken: tokenActions.PlayerGetToken) =>
            this.TokenserviceService.onGetSportsBookToken(onGetSportsBookToken.payload)
                .pipe(
                    map((response: sBookLogin) => {
                        return new tokenActions.PlayerGetTokenSuccess(response);
                    }),
                    catchError((error: HttpErrorResponse) => {

                        //this.toastr.error(error.message);
                        return handleError(error, tokenActions.PlayerGetTokenFail);

                    })
                )
        )
    )
    @Effect({ dispatch: false })
    PlayerGetTokenSuccess = this.actions$.pipe(
        ofType(tokenActions.PLAYER_GET_TOKEN_SUCCESS),
        tap((onGetSportsBookToken: tokenActions.PlayerGetTokenSuccess) => {
            if (onGetSportsBookToken.payload.accessToken != null) {
                sessionStorage.setItem('accessToken', onGetSportsBookToken.payload.accessToken); 
                this.loginservice.onGetPlayerBalance();
                this.loginservice.onplayerLoggedIn(true);
                return;
            }
            this.loginservice.onplayerLoggedIn(false);
        })
    )


    @Effect({ dispatch: false })
    PlayerGetTokenFail = this.actions$.pipe(
        ofType(tokenActions.PLAYER_GET_TOKEN_FAIL),
        tap((onGetSportsBookToken: tokenActions.PlayerGetTokenFail) => {
        })
    )
    @Effect({ dispatch: false })
    SlotsGamesLunch = this.actions$.pipe(
        ofType(tokenActions.SLOTS_GAME_LAUNCH_START),
        tap((onGetSlotsGamesLunch: tokenActions.SlotsGamesLunch) => 
        this.SlotsService.httpClient(onGetSlotsGamesLunch.payload)
                .pipe(
                    map((response: slotsData) => {
                        return new tokenActions.SlotsGamesLunchSuccess(response);
                    }),
                    catchError((error: HttpErrorResponse) => {
                        return handleError(error, tokenActions.SlotsGamesLunchFail);
                    })
                )
        )
    )
    @Effect({ dispatch: false })
    GrGamesLunch = this.actions$.pipe(
        ofType(tokenActions.GR_GAME_LAUNCH_START),
        tap((onGetGrGamesLunch: tokenActions.GrGamesLunch) => 
        this.goldenraceservice.httpClient1()
                .pipe(
                    map((response: GoldenRaceGameLaunch) => {
                        return new tokenActions.GrGamesLunchSuccess(response);
                    }),
                    catchError((error: HttpErrorResponse) => {
                        return handleError(error, tokenActions.GrGamesLunchFail);
                    })
                )
        )
    )

}