import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-payment-rules',
  templateUrl: './payment-rules.component.html',
  styleUrls: ['./payment-rules.component.css']
})
export class PaymentRulesComponent implements OnInit {

  constructor() { }

  ngOnInit() {
    window.scroll(0,0);
  }

}
