
import { Component, OnInit, EventEmitter, ComponentFactoryResolver, ViewChild, Output, OnDestroy, HostListener } from '@angular/core';
import { CommondataService } from '../../source/services/sports/commondata.service';
import { Betslip, OddsBetslip } from '../../source/modules/specific/odds.model';
import { BetRequest } from '../../source/modules/specific/bet-request';
import { BetResponse } from '../../source/modules/specific/bet-response';
import { couponBet } from '../../source/modules/specific/coupons';
import { BetslipService } from '../../source/services/sports/betslip.service';
import { LoginState } from '../../source/appstates/loginstates/loginState';
//import { PopupComponent } from '../popup/popup.component';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { BetEmailResponse } from '../../source/modules/specific/betemail';
import { BehaviorSubject, Observable, Subscription } from 'rxjs';
import { ElementRef } from '@angular/core';
//import { NgForm } from "@angular/forms";
import { Store } from '@ngrx/store';
import * as loginActions from 'src/app/source/appstates/loginstates/loginActions';
import * as appState from 'src/app/source/appstates/appState';
import { tokenState } from '../../source/appstates/sblogin/sbloginState';
import { BetemailComponent } from './betemail/betemail.component';
import { environment } from '@app/env';
import { CreateBetInfo } from '../../source/modules/common/livedata/create-bet-info';
import { ProfileObject } from '../../source/modules/specific/betprofile';
import { PlaceholderDirectiveDirective } from '../../source/directives/placeholder-directive.directive';
import { BonusData, BetLimits, Percentage, Payouts, BetLimitsByBetType } from '../../source/modules/specific/bonus';
import * as cashierActions from 'src/app/source/appstates/cashierstates/cashierActions';
import { sideSportService } from 'src/app/source/services/sports/sportMenu.service';
import { oddMatch, oddData, Selection } from 'src/app/source/modules/specific/oddMatches.model';
import { Router } from '@angular/router';
import { fail } from 'assert';
import { PasswordsComponent } from '../passwords/passwords.component';
import { CashierState } from 'src/app/source/appstates/cashierstates/cashierState';
import { Balance, WalletInfo } from 'src/app/source/modules/cashier/balance';

declare var $: any;
@Component({
    selector: 'app-betslip',
    templateUrl: './betslip.component.html',
    styleUrls: ['./betslip.component.css']
})
export class BetslipComponent implements OnInit, OnDestroy {
    @Output() betscountValue = new EventEmitter<number>();
    @Output() mobileBetscountValue = new EventEmitter<number>();
    private bettokenstoreSub: Subscription;
    private logoutstore: Subscription;
    private storeSub: Subscription;
    emailsent: boolean = false;
    public betArray: Array<Betslip>;
    betslip: Betslip;
    flagvalue: boolean = false;
    betSelection: boolean = false;
    betType: number = 1;
    bonusvalue: number = 0;
    betstatus: string = '1';
    seletedBetType: number = 1;
    betRequest: BetRequest;
    private closeSub: Subscription;
    betemailresponse: BetEmailResponse;
    overAllOdds: number = 0;
    comboBet: boolean = false;
    totalOdds: any;
    couponId: any;
    matchID: any;
    smartbeterrormessage: string;
    bookingNoResonse: string;
    stakeerrormessage: string;
    stakeamountbol: boolean = false;
    winnamountbol: boolean = false;
    //potentionalamountbol: boolean = false;
    potentionaleerrormessage: string;
    stakeAmount: number = null;
    total: number;
    percentagedata: any;
    profileobject: ProfileObject;
    winAmount: number = 0;
    loginbetdata: string;
    comboAmount: number = 0;
    couponform: FormGroup;
    matchidform: FormGroup;
    winning: any;
    betSlipResponse: BetResponse;
    couponbet: couponBet;
    sitekeyvalue: string;
    id: string;
    keyid: string;
    overalloddserorr: string;
    betselectionserror: string;
    bonusdata: BonusData;
    betlimits: BetLimits;
    percentage: Array<Percentage>;
    betlimitsbybetType: Array<BetLimitsByBetType>;
    payoutAmount: number = 0;
    bonuspercent: number;
    public width_2: number;
    innerWidth: number;
    betSlipBox: boolean = true;
    Payoutdata: Payouts;
    resp: string;
    selection: any;
    OddData: Array<oddData>;
    OddMatch: oddMatch;
    selectedValue: any;
    selectedoddtype: string;
    dateTime: any;
    country: string;
    smarterrormessage: string;
    betloader: boolean = false;
    matchloader:boolean=false;
    balance: any;
    cashier: Balance;
    walleteInfo: WalletInfo[];
    betselectionserrormessage: boolean = false;
    betTypeList = [
        { name: 'Accumulator', value: 3 },
    ];
    //  { name: 'Combo bet', value: 2 }
    //{ name: 'Chain bet', value: 4 },
    betList = [
        { name: 'Single Bet', value: 1 }
    ];
    
    betarrayshow: boolean = true
    removebettype: boolean = false;
    oddChange: boolean;
    betInfo: CreateBetInfo;
    captcha: any;
    potentionalAomunt: number;
    CloseBetSlipBox: boolean;
    // @ViewChild('container', { static: true, read: ViewContainerRef }) entry: ViewContainerRef;
    interval: any;
    betslipsuccessPop: boolean = false;
    beslipmessages: string = "";
    @ViewChild('openBtn', { static: false }) openBtn: ElementRef;
    @ViewChild('closeBtn', { static: false }) closeBtn: ElementRef;
    @ViewChild(PlaceholderDirectiveDirective, { static: true }) adHost: PlaceholderDirectiveDirective;
    
  @ViewChild(PasswordsComponent, { static: false }) passwords: PasswordsComponent;
    bet: OddsBetslip;
    selectedmatchid: number;
    oddstype: any;
    matchNm: string;
    selectedodds: any;
    playerLoggedIn: boolean = false;
    constructor(private router: Router, private _mainServe: sideSportService, private store: Store<appState.AppState>, private componentFactoryResolver: ComponentFactoryResolver, private CommondataService: CommondataService, private betService: BetslipService) {
        this.CommondataService.odd.subscribe(value => { this.checkIfExistfor(value) });
        this.CommondataService.getArrayOdd.subscribe(value => { this.sendbetarray(value) });
        this.betArray = new Array<Betslip>();
        this.innerWidth = (window.innerWidth);
        if (this.innerWidth < 768) {
            this.betSlipBox = false;
            this.CloseBetSlipBox = true;
        }
    
        this.betService.getBonusJSON().subscribe(bonusres => { this.bonusLimt(bonusres) });

    }
    @HostListener('window:scroll', ['$event'])


    onWindowScroll(e) {
        if (this.innerWidth > 768) {
            if (window.pageYOffset > 150) {
                let element_2 = document.getElementById('betSlips');
                // element_2.classList.add('stickySide');
            } else {
                let element_2 = document.getElementById('betSlips');
                // element_2.classList.remove('stickySide');
            }
        } else {

        }
    }
    betArrayPop() {
        if (this.innerWidth < 768) {
            this.betSlipBox = true;
        }
        // this.betSlipBox = false;
    }
    closeBetSlip() {
        this.betSlipBox = false;
    }

    ngOnInit() {
        this.width_2 = document.getElementById("betSlips").clientWidth;

        this.store.dispatch(new loginActions.ResetState());
        this.storeSub = this.store.select("loginState").subscribe(
            (loginState: LoginState) => {
              if (loginState.playerLoggedIn) {
                this.playerLoggedIn = loginState.playerLoggedIn.loggedIn;
              }
            }
          )
        this.couponform = new FormGroup({
            "coupon": new FormControl(null, [Validators.required, Validators.pattern('[a-zA-Z0-9]*')])
        })
        this.matchidform = new FormGroup({
            // "matchid": new FormControl(null, [Validators.required, Validators.pattern('[0-9]{4,8}')])
            "matchid": new FormControl(null, [Validators.required])
        })
        for (let i = 0; environment.sitekey[i]; i++)
            if (environment.captchaName == environment.sitekey[i].id) {
                this.sitekeyvalue = environment.sitekey[i].keyid;
            }
  

        $(document).ready(function () {
            $('.j_betslip').click(function () {
                $('.j_betslip_close').slideToggle();
            });
        });
        $(document).ready(function () {
            $('.smart_betslip').click(function () {
                $('.smart_betslip_close').slideToggle();
            });
        });
        this.storeSub = this.store.select("cashierState").subscribe(
            (cashierState: CashierState) => {
                if (cashierState.balance) {
                    if (cashierState.balance.success == true) {
                        this.cashier = cashierState.balance;
                        this.walleteInfo = cashierState.balance.values;
                        for (let wallet of this.walleteInfo) {
                            if (wallet.preferred === true) {
                                let totalbalance = wallet.cash.value + wallet.bonus.value;
                                this.balance = totalbalance.toString().split(".")[0];
                            }
                        }
                    }
                }

            }
        );
    }
    bonusLimt(bonusres) {
        this.bonusdata = bonusres;
        this.percentage = this.bonusdata.bonus.selections.structure.percentages;
        this.betlimitsbybetType = this.bonusdata.limits.betLimitsByBetType;
        this.betlimits = this.bonusdata.limits.betLimits;
        this.Payoutdata = this.bonusdata.limits.payouts;
    }
    resolved(captchaResponse: string) {
        this.captcha = captchaResponse;
        this.updateBet();
    }
    checkIfExistfor(betdata) {
        if (this.betArray.length == 0) {
            this.assignToBetData(betdata);
            return;
        } else {
            this.removebettype = true;
            this.betArray.forEach((data) => {
                if(betdata.flag){
                    if (betdata.oddId === data.oddId) {
                        this.removeBet(betdata);
                        return this.removebettype = false;
                    }
                }else{
                    if (betdata.mktId === data.mktId) {
                        this.removeBet(betdata);
                        return this.removebettype = false;
                    }
                }
                
            });
        }
        if (this.removebettype) {
            this.assignToBetData(betdata);
            return;
        }
    }
    assignToBetData(odd) {
        //    sessionStorage.setItem('betslipdata', JSON.stringify(data));
        //     let odd = JSON.parse(sessionStorage.getItem('betslipdata'));
        this.overAllOdds = this.calTotalOdds(this.seletedBetType);
        if (this.overAllOdds < this.Payoutdata.maxPayout) {
            for (let data of this.betlimitsbybetType) {
                if (data.betType) {
                    if (this.betArray.length < data.maxBetSelections) {
                        this.betselectionserrormessage = false;
                        this.betslip = odd;
                        this.betarrayshow = false;
                        setTimeout(() => {
                            this.betarrayshow = true;
                        }, 3000);
                        var index = this.checkIfExist(this.betslip);
                        if (index > -1) {
                            this.betArray.splice(index, 1);
                            this.betArray.push(this.betslip);

                        } else {
                            this.betArray.push(this.betslip);
                        }
                    } else {
                        this.betselectionserrormessage = true;
                        if (this.seletedBetType == data.betType) {

                            this.betselectionserror = data.bettype_error_message;
                            setTimeout(() => {
                                this.betselectionserror = "";
                                this.betselectionserrormessage = false;
                            }, 5000);
                        }
                    }
                }
            }
        } else if (this.overAllOdds >= this.Payoutdata.maxPayout) {
            this.betselectionserrormessage = true;
            this.overalloddserorr = 'max amount does not excced to 1000000';
        }
        this.betscountValue.emit(this.betArray.length);
        this.mobileBetscountValue.emit(this.betArray.length);
        this.resetBetType(this.betArray.length);
        if (this.betArray.length) {
            this.addStake();
        }
        this.resp = "";
        // this.resperror = false;
    }

    checkIfExist(betStr: Betslip): number {
        for (var i = 0; i < this.betArray.length; i++) {
            if (betStr.matchId === this.betArray[i].matchId) {
                return i;
            }
            //     else{
            //    this.overAllOdds = this.calTotalOdds(this.seletedBetType);
            //        for(let lomitsofbetType of this.betlimitsbybetType){
            //             if(this.seletedBetType == this.betType && this.betArray.length >= lomitsofbetType.maxBetSelections){

            //             }else{
            //                 if(this.seletedBetType == this.betType){
            //                     this.betselectionserrormessage = lomitsofbetType.bettype_error_message;
            //                 }
            //             } 
            //        }
            //}

            // if (betStr.oddTitle === this.betArray[i].oddTitle &&
            //     betStr.name === this.betArray[i].name &&
            //     betStr.oddstype === this.betArray[i].oddstype) {

            //     return i;
            // }


        }
    }//Check if bet present in betSlip


    removeBet(bet) {
        this.betselectionserrormessage = false;
        this.betArray.forEach((data, index) => {
            if(bet.flag){
                if (bet.oddId === data.oddId) {
                    this.betArray.splice(index, 1);
                    return;
                }
            }else{
                if (bet.mktId === data.mktId) {
                    this.betArray.splice(index, 1);
                return;
                }
            }
            // if (bet.matchId === data.matchId && bet.oddTitle === data.oddTitle &&
            //     bet.mktId === data.mktId &&
            //     bet.oddstype === data.oddstype) {
            //     this.betArray.splice(index, 1);
            //     return;
            // }
        });

        this.checkIfExist(this.betslip);
        this.overAllOdds = this.calTotalOdds(this.seletedBetType);
        this.resetBetType(this.betArray.length);
        this.winAmount = this.overAllOdds * this.stakeAmount;
        this.betscountValue.emit(this.betArray.length);
        this.mobileBetscountValue.emit(this.betArray.length);
        this.betArray.forEach(item => {
            let flag = item.flag;
            if (flag === false) {
                this.flagvalue = flag;
                this.loginbetdata = "You can now place bet";
            }
        });
        if (this.winAmount <= this.Payoutdata.maxPayout) {
            if (this.seletedBetType == 3) {
                this.percentagedata = this.percentage[0].start
                if (this.betArray.length >= this.percentage[0].start) {
                    for (let percent of this.percentage) {
                        if (this.betArray.length >= percent.start && this.betArray.length <= percent.end) {
                            this.winAmount = this.overAllOdds * this.stakeAmount;
                            this.bonusvalue = this.winAmount * (percent.bonus / 100);
                            Number(this.bonusvalue.toFixed(2));
                            this.bonuspercent = percent.bonus;
                            this.payoutAmount = this.winAmount + this.bonusvalue;
                            Number(this.payoutAmount.toFixed(2));
                        } else {
                            // this.bonusvalue = 0;
                            //this.payoutAmount = 0;
                        }
                    }
                }
                else {
                    // this.potentionalamountbol = false;
                    this.bonusvalue = 0;
                    this.payoutAmount = 0;
                    this.winAmount = this.overAllOdds * this.stakeAmount;
                }
            } else if (this.seletedBetType == 2) {
                this.total = this.betArray.length * this.stakeAmount;
                this.comboAmount = this.total;
                this.winAmount = this.overAllOdds * this.stakeAmount;
                this.bonusvalue = 0;
                this.payoutAmount = this.winAmount + this.bonusvalue;
                Number(this.payoutAmount.toFixed(2));
            }
        }
        this.addStake();
    }//remove bet from betSlip
    changeBetType(event) {
        if (event == 'Single Bet' && this.betArray.length <= 1) {
            this.seletedBetType = 1;
            this.comboBet = false;
        } else if (event == 'Combo bet') {
            this.seletedBetType = 2;
            this.comboBet = true;
        } else if (event == 'Accumulator') {
            this.seletedBetType = 3;
            this.comboBet = false;
        } else if (event == 'Chain bet') {
            this.seletedBetType = 4;
            this.comboBet = false;
        } else {
            this.seletedBetType = 1;
            this.betSelection = true;
        }
        this.overAllOdds = this.calTotalOdds(this.seletedBetType);
        this.totalOdds = this.overAllOdds;
        this.addStake();
    }//change the betType
    resetBetType(length) {
        if (length > 1) {
            if (this.seletedBetType == 3) {
                this.seletedBetType = 3;
                this.stakeAmount = null;
            } else if (this.seletedBetType == 2) {
                this.seletedBetType == 2;
                this.stakeAmount = null;
            } else {
                this.seletedBetType = 3;
                this.stakeAmount = null;
            }
        } else if (length <= 1) {
            this.seletedBetType = 1;
        }

        if (length == 0) {
            this.stakeAmount = null;
        }
        this.overAllOdds = this.calTotalOdds(this.seletedBetType);
        this.winAmount = this.overAllOdds * this.stakeAmount;
        if (this.winAmount <= this.Payoutdata.maxPayout) {
            if (this.seletedBetType == 3) {
                this.percentagedata = this.percentage[0].start
                if (this.betArray.length >= this.percentage[0].start) {
                    for (let percent of this.percentage) {
                        if (this.betArray.length >= percent.start && this.betArray.length <= percent.end) {
                            this.winAmount = this.overAllOdds * this.stakeAmount;
                            this.bonusvalue = this.winAmount * (percent.bonus / 100);
                            Number(this.bonusvalue.toFixed(2));
                            this.bonuspercent = percent.bonus;
                            this.payoutAmount = this.winAmount + this.bonusvalue;
                            Number(this.payoutAmount.toFixed(2));
                        } else {
                            // this.bonusvalue = 0;
                            //this.payoutAmount = 0;
                        }
                    }
                }
                else {
                    // this.potentionalamountbol = false;
                    this.bonusvalue = 0;
                    this.payoutAmount = 0;
                    this.winAmount = this.overAllOdds * this.stakeAmount;
                }
            } else if (this.seletedBetType == 2) {
                this.total = this.betArray.length * this.stakeAmount;
                this.comboAmount = this.total;
                this.winAmount = this.overAllOdds * this.stakeAmount;
                this.bonusvalue = 0;
                this.payoutAmount = this.winAmount + this.bonusvalue;
                Number(this.payoutAmount.toFixed(2));
            }
        }
        this.CommondataService.betArrayfind(this.betArray);
        //this.addStake();
    }// Rest the bet type
    sendbetarray(value) {
        if (value)
            this.CommondataService.betArrayfind(this.betArray);
    }
    calTotalOdds(event) {
        switch (event) {
            case 1:
                var total = 0;
                if (this.betArray != null && this.betArray.length > 0) {
                    this.betArray.forEach(x => total = x.decimalOdd);
                }
                return Number(total);
            case 2:
                var total = 0;
                if (this.betArray != null && this.betArray.length > 0) {
                    this.betArray.forEach(x => total += x.decimalOdd);
                }
                return Number(total.toFixed(2));
            case 3:
                var total = 1;
                if (this.betArray != null && this.betArray.length > 0) {
                    this.betArray.forEach(x => total *= x.decimalOdd);
                }
                return Number(total.toFixed(2));
            case 4:
                var total = 0;
                if (this.betArray != null && this.betArray.length > 0) {
                    this.betArray.forEach(x => total += x.decimalOdd);
                }
                return (total - 1);
        }
        this.addStake();
    }

    addStake() {
        this.overAllOdds = this.calTotalOdds(this.seletedBetType);
        if (this.stakeAmount >= this.betlimits.Bet_min_limit && this.stakeAmount <= this.betlimits.Bet_max_limit) {
            this.stakeamountbol = false;
            this.winAmount = this.overAllOdds * this.stakeAmount;
            if (this.winAmount <= this.Payoutdata.maxPayout) {
                this.winnamountbol = false;
                if (this.seletedBetType == 1) {
                    this.winAmount = this.overAllOdds * this.stakeAmount;
                    this.bonusvalue = 0;
                    this.payoutAmount = this.winAmount + this.bonusvalue;
                    Number(this.payoutAmount.toFixed(2));
                } else if (this.seletedBetType == 2) {
                    this.total = this.betArray.length * this.stakeAmount;
                    this.comboAmount = this.total;
                    this.winAmount = this.overAllOdds * this.stakeAmount;
                    this.bonusvalue = 0;
                    this.payoutAmount = this.winAmount + this.bonusvalue;
                    Number(this.payoutAmount.toFixed(2));
                } else if (this.seletedBetType == 3) {
                    this.percentagedata = this.percentage[0].start
                    if (this.betArray.length >= this.percentage[0].start) {
                        for (let percent of this.percentage) {
                            if (this.betArray.length >= percent.start && this.betArray.length <= percent.end) {
                                this.winAmount = this.overAllOdds * this.stakeAmount;
                                this.bonusvalue = this.winAmount * (percent.bonus / 100);
                                Number(this.bonusvalue.toFixed(2));
                                this.bonuspercent = percent.bonus;
                                this.payoutAmount = this.winAmount + this.bonusvalue;
                                Number(this.payoutAmount.toFixed(2));
                            } else {
                            }
                        }
                    }
                    else {
                        this.bonusvalue = 0;
                        this.payoutAmount = 0;
                        this.winAmount = this.overAllOdds * this.stakeAmount;
                        this.bonusvalue = 0;
                        this.payoutAmount = this.winAmount + this.bonusvalue;
                        Number(this.payoutAmount.toFixed(2));
                    }
                } else {
                }
            } else {
                this.winnamountbol = true;
                this.potentionaleerrormessage = this.Payoutdata.potentional_error_message;
                this.winAmount = 0;
                this.bonusvalue = 0;
                this.payoutAmount = 0;
                this.comboAmount = 0;
                //this.stakeAmount = 0;
            }
        } else {
            this.stakeamountbol = true;
            this.winAmount = 0;
            this.bonusvalue = 0;
            this.payoutAmount = 0;
            this.comboAmount = 0;
            this.stakeAmount = null;
            //this.total = this.betArray.length * this.stakeAmount;
            this.stakeerrormessage = this.betlimits.stake_error_message;
        }
        // if (this.winAmount == 0 || this.winAmount == null) {
        //     this.bonusvalue = 0;
        //     this.payoutAmount = 0;
        // }
        this.updateBet();
    }
    oddsChange(value: any) {
        this.oddChange = value.currentTarget.checked;
        // this.updateBet();
    }
    updateBet() {

        this.overAllOdds = this.calTotalOdds(this.seletedBetType);
        if (this.seletedBetType == 1) {
            const betcalCulation = {
                totalOdds: this.overAllOdds.toFixed(2),
                possiblePayout: this.winAmount.toFixed(2),
                possibleBonus: 0,
                possibleWinnings: this.payoutAmount.toFixed(2),
                bonusPercentage: 0,
                selections: this.betArray.length,
                totalAomunt: this.stakeAmount
            }
            //this.captcha
            this.betRequest = new BetRequest(this.seletedBetType, this.betArray, this.stakeAmount, this.oddChange, betcalCulation);
        } else if (this.seletedBetType == 2) {
            const betcalCulation = {
                totalOdds: this.overAllOdds.toFixed(2),
                possiblePayout: this.winAmount.toFixed(2),
                possibleBonus: 0,
                possibleWinnings: this.payoutAmount.toFixed(2),
                bonusPercentage: 0,
                selections: this.betArray.length,
                totalAomunt: this.comboAmount.toFixed(2)
            }
            //this.captcha,
            this.betRequest = new BetRequest(this.seletedBetType, this.betArray, this.stakeAmount, this.oddChange, betcalCulation);
        } else if (this.seletedBetType == 3 && this.betArray.length < 3) {
            const betcalCulation = {
                totalOdds: this.overAllOdds.toFixed(2),
                possiblePayout: this.winAmount.toFixed(2),
                possibleBonus: 0,
                possibleWinnings: this.payoutAmount.toFixed(2),
                bonusPercentage: 0,
                selections: this.betArray.length,
                totalAomunt: this.stakeAmount
            }
            //this.captcha,
            this.betRequest = new BetRequest(this.seletedBetType, this.betArray, this.stakeAmount, this.oddChange, betcalCulation);
        } else if (this.seletedBetType == 3 && this.betArray.length >= 3) {
            const betcalCulation = {
                totalOdds: this.overAllOdds.toFixed(2),
                possiblePayout: this.winAmount.toFixed(2),
                possibleBonus: this.bonusvalue.toFixed(2),
                possibleWinnings: this.payoutAmount.toFixed(2),
                bonusPercentage: this.bonuspercent.toFixed(2),
                selections: this.betArray.length,
                totalAomunt: this.stakeAmount
            }
            this.betRequest = new BetRequest(this.seletedBetType, this.betArray, this.stakeAmount, this.oddChange, betcalCulation);
        }

    }//Update bet

    placeBet() {

        this.betloader = true;
        let bet = [];
        if (this.stakeAmount == undefined || this.stakeAmount <= 0) {
            this.beslipmessages = 'Invalid stake amount';
            this.betslipsuccessPop = true;
            this.betloader = false;
        } else {

            this.store.dispatch(new cashierActions.CashierGetBalanceStart());

            if (this.playerLoggedIn == true) {
                if (this.stakeAmount <= this.balance) {
                    this.store.dispatch(new cashierActions.CashierGetBalanceStart());
                    this.placeloginbet();
                    return;
                } else {
                    this.beslipmessages = "Amount can't be lessthan with your balance";
                    this.betslipsuccessPop = true;
                    this.betloader = false;
                    return;
                }
                // this.betRequest.captcha = null;
                // this.betService.placeBet(this.betRequest, this.playerLoggedIn).subscribe(data => { this.betResponse(data); });
            } else if (this.playerLoggedIn == false) {
                this.betArray.forEach(item => {
                    let flag = item.flag;
                    if (flag === true) {
                        this.flagvalue = flag;
                        // this.loginbetdata = "Please login to place bet";
                    } else if (flag === false) {
                        //bet.push(this.betRequest);

                        // this.betService.placeBet(this.betRequest, this.playerLoggedIn).subscribe(data => { this.betResponse(data); });
                    }
                });
                this.placeloginbet();
            }
        }
    }
    placeloginbet() {
        this.betService.placeBet(this.betRequest, this.playerLoggedIn).subscribe(data => { this.betResponse(data) },
            err => {
                this.resp = err;
                this.betloader = false;
            });
    }
    betResponse(data) {
        this.betSlipResponse = data;
        if (this.playerLoggedIn == true) {
            this.betloader = false;
            if (this.betSlipResponse.Status == this.betstatus) {
                sessionStorage.setItem("bettransId", this.betSlipResponse.transactionId);
                this.betService.getBetCupan(this.betSlipResponse.transactionId).subscribe(data => { this.placeBetopenModalEmail(data) });
                this.clear();
            } else {
                this.resp = this.betSlipResponse.Message;
                this.betloader = false;
                this.sportsdata();
                this.clear();
                  setTimeout(() => {
              this.resp = "";
            }, 5000);
            }
        } else {
            if (this.betSlipResponse.Status == this.betstatus) {
                this.betloader = false;
                sessionStorage.setItem("bettransId", this.betSlipResponse.transactionId);
                this.betService.getBetCupan(this.betSlipResponse.transactionId).subscribe(data => { this.placeBetopenModalEmail(data) });
                this.clear();
            } else {
                this.resp = this.betSlipResponse.Message;
                this.betloader = false;
                this.sportsdata();
                this.clear();
                  setTimeout(() => {
              this.resp = "";
            }, 5000);
            }
        }
    }
    placeBetopenModalEmail(data) {
        this.sportsdata();
        this.couponbet = data;
        if (this.couponbet.Status == -1) {
            this.beslipmessages = this.couponbet.Message;
            this.betslipsuccessPop = true;
        } else {
            const alertCmpFactory = this.componentFactoryResolver.resolveComponentFactory(
                BetemailComponent
            );
            const hostViewContainerRef = this.adHost.viewContainerRef;
            hostViewContainerRef.clear();
            const componentRef = hostViewContainerRef.createComponent(alertCmpFactory);

            this.CommondataService.couponData(this.couponbet);
            this.couponform.reset();
            this.store.dispatch(new cashierActions.CashierGetBalanceStart());
            this.closeSub = componentRef.instance.close.subscribe(() => {
                this.closeSub.unsubscribe();
                hostViewContainerRef.clear();
            });
        }

    }
    openModalEmail(data) {
        this.couponbet = data;
        if (this.couponbet.Status == -1) {
            this.bookingNoResonse = this.couponbet.Message;
        } else {
            // const dialogRef = this.dialog.open(BetemailComponent, {
            //     //disableClose: true,
            //     width: '50%',
            // });
            const alertCmpFactory = this.componentFactoryResolver.resolveComponentFactory(
                BetemailComponent
            );
            const hostViewContainerRef = this.adHost.viewContainerRef;
            hostViewContainerRef.clear();
            const componentRef = hostViewContainerRef.createComponent(alertCmpFactory);

            this.CommondataService.couponData(this.couponbet);
            this.couponform.reset();
            this.store.dispatch(new cashierActions.CashierGetBalanceStart());
            this.closeSub = componentRef.instance.close.subscribe(() => {
                this.closeSub.unsubscribe();
                hostViewContainerRef.clear();
            });
        }
    }



    clearSearch() {
        if (this.stakeAmount != undefined || this.stakeAmount >= 0) {
            this.winAmount = null;
            this.bonusvalue = null;
            this.payoutAmount = null;
            this.potentionalAomunt = null;
            this.comboAmount = null;
            this.overAllOdds = null;
            this.betArray.length = null;
            this.stakeAmount = null;
            this.flagvalue = false;
            this.betscountValue.emit(this.betArray.length);
            this.mobileBetscountValue.emit(this.betArray.length);

        }
    }
    clear() {
        this.smarterrormessage = null;
        this.matchidform.reset();
        this.OddData = null;
        this.matchNm = null;
        this.dateTime = null;
        this.country = null;
        this.betloader = false;
        this.selection = null;
        this.potentionalAomunt = null;
        this.winAmount = null;
        this.bonusvalue = null;
        this.payoutAmount = null;
        this.comboAmount = null;
        this.overAllOdds = null;
        this.betArray.length = null;
        this.stakeAmount = null;
        this.flagvalue = false;
        this.betscountValue.emit(this.betArray.length);
        this.mobileBetscountValue.emit(this.betArray.length);

        this.CommondataService.betArrayfind(this.betArray);
    }

    isLoggedInCheck() {
        this.CommondataService.isLoogedIn$.subscribe(value => {
            this.response(value);
        });
    }

    response(m) {
        this.playerLoggedIn = m;
    }

    private openModal(): void {
        this.openBtn.nativeElement.click();
    }

    betprofile() {

        this.store.dispatch(new cashierActions.CashierGetBalanceStart());
        if (this.playerLoggedIn == true) {
            this.betService.getBetProfile().subscribe(data => { this.getBetProfileResponse(data); });
        } else {
        }
    }

    getBetProfileResponse(data) {
        this.profileobject = data;
    }

    onCouponFormSubmit() {
        this.couponId = this.couponform.value.coupon;
        sessionStorage.setItem("bettransId", this.couponId);
        this.betService.getBetCupan(this.couponId).subscribe(data => { this.openModalEmail(data); });
    }
    onRecentbetCouponIdSubmit(id) {
        this.couponId = id;
        sessionStorage.setItem("bettransId", this.couponId);
        this.betService.getBetCupan(this.couponId).subscribe(data => { this.openModalEmail(data); });
    }
    ngOnDestroy() {
        if (this.closeSub) {
            this.closeSub.unsubscribe();
        }
    }
    betslipPopClose() {
        this.beslipmessages = "";
        this.betslipsuccessPop = false;
    }
    matchidenter() {
        this.matchloader = true;
        if (this.matchidform.value.matchid.toString().length == 4 || this.matchidform.value.matchid.toString().length == 8) {
            this.smarterrormessage = null;
            this.OddData = null;
            this.matchNm = null;
            this.dateTime = null;
            this.country = null;
            this.selection = null;
            this.selectedmatchid = +this.matchidform.value.matchid;
            this._mainServe.searchMatchId(this.selectedmatchid).subscribe(oddId => { this.oddMarkets(oddId) },
                err => { this.smartbeterrormessage = err; 
                    this.matchloader = false;});
        } else {
            this.smartbeterrormessage = "Enter Valid ID";
            this.matchloader = false;
        }
    }
    oddMarkets(oddId) {
        this.OddMatch = oddId;
        if (this.OddMatch.Status == 200) {
            this.matchloader = false;
            this.smarterrormessage = null;
            this.OddData = this.OddMatch.data;
            this.matchNm = this.OddMatch.data[0].match_name;
            this.matchID = this.OddMatch.data[0].match_id;
            this.dateTime = this.OddMatch.data[0].start_date + "-" + this.OddMatch.data[0].start_time;
            this.country = this.OddMatch.data[0].country;
            // this.matchNm = "match name";
        } else {
            this.matchloader = false;
            this.smarterrormessage = this.OddMatch.Message;
            this.OddData = null;
            this.matchNm = null;
            this.dateTime = null;
            this.country = null;
            this.selection = null;
        }
    }
    marketselect(id) {
        this.selection = null;
        for (let ods of this.OddData) {
            if (id == ods.oddtypeid) {
                this.matchNm = ods.match_name;
                this.oddstype = ods.oddstype;
                this.selection = ods.selections;
                this.selectedValue = this.selection[0].home_title
            }
        }
    }
    selectedodd(title) {
        this.selectedoddtype = title;
        if (title == null) {
        } else {
            for (let hda of this.selection) {
                if (title == hda.home_title) {
                    this.selectedodds = hda;
                } else if (title == hda.neut_title) {
                    this.selectedodds = hda;
                } else if (title == hda.away_title) {
                    this.selectedodds = hda;
                }
            }

        }
    }
    betadd() {
        
   //this.passwords.comingSoonPopOpen();
         if (this.selectedoddtype != null) {
             this.bet = new OddsBetslip(this.selectedodds, this.oddstype, this.selectedoddtype, this.matchNm, this.matchID, this.dateTime, this.country);
             this.CommondataService.OddData(this.bet);
             this.selectedoddtype = null;
         }
    }
    errornull() {

        this.smartbeterrormessage = null; 
         this.smarterrormessage = null;
        this.OddData = null;
        this.matchNm = null;
        this.dateTime = null;
        this.country = null;
        this.selection = null;
    }
    sportsdata() {
       // if (this.router.url == '/esport' || this.router.url == '/preMatch') {
        if (sessionStorage.getItem("topleagueshow") == "123") {
            let sName = sessionStorage.getItem("SportName");
            let toleaguecountry = sessionStorage.getItem("topleaguecountry");
            let selectedleagueid = sessionStorage.getItem("selectleagueid");
            this._mainServe.sendSportCountryLeague(sName, toleaguecountry, selectedleagueid).subscribe(spCountryLeague => this.toleaguegetMatches(spCountryLeague));
            sessionStorage.removeItem("topleagueshow");
        } else if (sessionStorage.getItem("normalleaguesshow") == "456") {
            this._mainServe.getTodayMatches(sessionStorage.getItem("SportName"), sessionStorage.getItem("timescope")).subscribe(matches => this.getMatches(matches));
            sessionStorage.removeItem("normalleaguesshow");
        }
    //}
        if (this.router.url == '/sports') {
            let sport = sessionStorage.getItem("SportName");
            let country = sessionStorage.getItem("Country");
            let league = sessionStorage.getItem("League");
            this._mainServe.sendSportCountryLeague(sport, country, league).subscribe(spCountryLeague => this.sportCountryLeague(spCountryLeague));
        }
    }
    getMatches(matches) {
        let data = matches;
        this.CommondataService.prematchdata(data);
    }
    toleaguegetMatches(spCountryLeague) {

        this.CommondataService.toptenleagues(spCountryLeague);
    }
    sportCountryLeague(spCountryLeague) {
        this.CommondataService.CountryMatches(spCountryLeague);
    }
}