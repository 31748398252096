import { Component, OnInit, OnDestroy, Input, Output, EventEmitter } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Subscription } from 'rxjs';
import { Store } from '@ngrx/store';
import * as appState from 'src/app/source/appstates/appState';
import { ProfileState } from 'src/app/source/appstates/playerstates/playerState';
import { Profile } from 'src/app/source/modules/player/profile';
import * as playerActions from 'src/app/source/appstates/playerstates/playerActions';
import { Countries, Country } from 'src/app/source/modules/login/countries';
import * as cashierActions from 'src/app/source/appstates/cashierstates/cashierActions';
import { environment } from '@app/env';
@Component({
  selector: 'app-update-profile',
  templateUrl: './update-profile.component.html',
  styleUrls: ['./update-profile.component.css']
})
export class UpdateProfileComponent implements OnInit, OnDestroy {
  @Input() formState: string;
  ProfileUpdate: FormGroup;
  private storeSub: Subscription;
  private profile: Profile;
  updateProfileError: string;
  private countries: Countries;
  country: Country[];
  basedonupdateprofile = environment.skinId;
  updatePopup: boolean = false;
  countrycode:any;
  UpdateProfilemessage:string = "";
  UpdateProfilesuccessPop:boolean=false;
  updateprofilecode = [
    {id:'+66', value:"764"},
    {id:'+60', value:"458"},
    {id:'+62', value:"360"},
    {id:'+84', value:"704"},
    {id:'+91', value:"356"}
  ];
  constructor(private store: Store<appState.AppState>) {

    this.store.dispatch(new playerActions.ResetState());
    this.store.dispatch(new cashierActions.CashierGetBalanceStart());
  }

  ngOnInit() {

    this.store.dispatch(new playerActions.ResetState());
    this.ProfileUpdate = new FormGroup({
      "nickname": new FormControl('', [Validators.required, Validators.minLength(4), Validators.pattern('[a-zA-Z0-9]*')]),
      "email": new FormControl('', [Validators.required, Validators.email]),
      "firstName": new FormControl('', [Validators.minLength(4), Validators.pattern('[a-zA-Z]*')]),
      "lastName": new FormControl('', Validators.pattern('[a-zA-Z]*')),
      "address": new FormGroup({
        "city": new FormControl('', Validators.pattern('[a-zA-Z]*')),
        "phone": new FormControl('', [Validators.required, Validators.pattern('[4-9]\\d{9}')]),
        "country": new FormControl(),
      }),
    })

    // this.ProfileUpdate = new FormGroup({
    //   "nickname": new FormControl('', [Validators.required, Validators.minLength(4), Validators.pattern('[a-zA-Z0-9]*')]),
    //   "email": new FormControl('', [Validators.required, Validators.email]),
    //   "firstName": new FormControl('', [Validators.required, Validators.minLength(4), Validators.pattern('[a-zA-Z]*')]),
    //   "lastName": new FormControl('', [Validators.required, Validators.pattern('[a-zA-Z]*')]),
    //   "address": new FormGroup({
    //     "city": new FormControl('', [Validators.required, Validators.pattern('[a-zA-Z]*')]),
    //     "phone": new FormControl('', [Validators.required, Validators.pattern('[4-9]\\d{9}')]),
    //     "country": new FormControl(),
    //   }),
    // })

    this.storeSub = this.store.select("playerState").subscribe(
      (playerState: ProfileState) => {
        if (playerState.profile) {
          if (playerState.profile.success == true) {

            this.profile = playerState.profile;
            if (this.profile.nickname) {
              (<FormControl>this.ProfileUpdate.get('nickname')).setValue(this.profile.nickname);
            }
            if (this.profile.email) {
              (<FormControl>this.ProfileUpdate.get('email')).setValue(this.profile.email);
            }
            if (this.profile.firstName) {
              (<FormControl>this.ProfileUpdate.get('firstName')).setValue(this.profile.firstName);
            }
            if (this.profile.lastName) {
              (<FormControl>this.ProfileUpdate.get('lastName')).setValue(this.profile.lastName);
            }
            if (this.profile.address) {
              if (this.profile.address.city) {
                (<FormControl>this.ProfileUpdate.get('address')).get("city").setValue(this.profile.address.city);
              }
              if (this.profile.address.phone) {
                (<FormControl>this.ProfileUpdate.get('address')).get("phone").setValue(this.profile.address.phone);
              }
              if (this.profile.address.country) {
                for(let data of this.updateprofilecode){
                  if(data.value == this.profile.address.country){
                    this.countrycode = data.id;
                  }
                }
                (<FormControl>this.ProfileUpdate.get('address')).get("country").setValue(this.profile.address.country);
              }
            }

          } else if (playerState.profile.success === false) {
            this.updateProfileError = playerState.profile.description;
          }
        }
      }
    )
    this.storeSub = this.store.select("playerState").subscribe(
      (playerState: ProfileState) => {
        if (playerState.UpDateProfileModel) {
          if (playerState.UpDateProfileModel.success == true) {
            this.updatePopup = false;
            this.updateProfileError ="";
            this.UpdateProfilemessage = 'Updated Successfully';
            this.UpdateProfilesuccessPop=true;
          } else if (playerState.UpDateProfileModel.success == false) {
            this.updateProfileError = playerState.UpDateProfileModel.description;
          }
        }
        if (playerState.UpDateProfileModelError) {

          this.updateProfileError = playerState.UpDateProfileModelError.message;
        }
      })

    this.store.dispatch(new playerActions.PlayerGetProfile());

  }
  onProfileUpDateFormSubmit() {

    this.store.dispatch(new cashierActions.CashierGetBalanceStart());
    this.ProfileUpdate.value.address.country = 288;
    this.store.dispatch(new playerActions.PlayerUpdateProfile(this.ProfileUpdate.value));
    // if (this.ProfileUpdate.value.address.phone == null) {
    //   this.store.dispatch(new playerActions.PlayerUpdateProfile(this.ProfileUpdate.value));
    // } else if (this.ProfileUpdate.value.address.phone.length == 10) {
    //   this.ProfileUpdate.value.address.phone = '+234' + this.ProfileUpdate.value.address.phone;
    //   this.store.dispatch(new playerActions.PlayerUpdateProfile(this.ProfileUpdate.value));
    // } else {
    //   this.ProfileUpdate.value.address.phone;
    //   this.store.dispatch(new playerActions.PlayerUpdateProfile(this.ProfileUpdate.value));
    // }

  }
  ngOnDestroy() {
    if (this.profile) {
      this.profile = null;
    }
    if (this.storeSub) {
      this.storeSub.unsubscribe();;
    }
  }

  openUpdatePop() {
    this.store.dispatch(new playerActions.ResetState());
    this.updatePopup = true;
  }
  closeUpdatePop() {
    this.ProfileUpdate.reset();
    this.store.dispatch(new playerActions.ResetState());
    this.updatePopup = false;
    this.store.dispatch(new playerActions.PlayerGetProfile());
  }
  UpdateProfilePopClose(){
    
    this.store.dispatch(new playerActions.ResetState());
    this.UpdateProfilemessage = "";
    this.UpdateProfilesuccessPop=false;
  }
}
