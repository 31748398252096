import { Component, OnInit, ViewChild } from '@angular/core';
import { RegisterComponent } from '../../register/register.component';

@Component({
  selector: 'app-betting-rules',
  templateUrl: './betting-rules.component.html',
  styleUrls: ['./betting-rules.component.css']
})
export class BettingRulesComponent implements OnInit {
  @ViewChild(RegisterComponent, { static: false }) register: RegisterComponent;

  constructor() { }

  ngOnInit() {
    window.scroll(0,0);
  }
  registrationPopup() {
    this.register.openRegisterPop()
  }

}
