import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-how-claim-deposit-bonus',
  templateUrl: './how-claim-deposit-bonus.component.html',
  styleUrls: ['./how-claim-deposit-bonus.component.css']
})
export class HowClaimDepositBonusComponent implements OnInit {

  constructor() { }

  ngOnInit() {
    window.scroll(0,0);
  }

}
