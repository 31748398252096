import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { HttpErrorResponse } from '@angular/common/http';
import {environment} from '@app/env';
import { Observable } from 'rxjs';
import { slotsData } from '../modules/slotsmodel/slotsdata';
import { Store } from '@ngrx/store';
import { AppState } from '../appstates/appState';
import { SlotsGamesLunch } from '../appstates/sblogin/sbloginAction';
import { PlayerService } from './player/player.service';
@Injectable({
  providedIn: 'root'
})
export class SlotsService {
  private _jsonURL = 'assets/HabaneroGames.json';
  private _TablejsonURL = 'assets/habanerogamesjsonTableGames.json';
  private _CasinojsonURL = 'assets/casinogames.json';
  private _TopSlots = 'assets/topslots.json';
  constructor(
    private playerService: PlayerService, private http: HttpClient,private store:Store<AppState>) {

   }
  //  private httpOptions = {
  //   headers: new HttpHeaders({
  //     'Content-Type':  'application/json',
  //     'siteid': environment.skinId,
  //     'wsession':sessionStorage.getItem('wsession'),
  //     'Authorization': "Bearer " + sessionStorage.getItem('accessToken')
  //   })
  // };
  public getJSON(): Observable<any> {
    return this.http.get(this._jsonURL);
  }
  public getTablegamesJSON(): Observable<any> {
    return this.http.get(this._TablejsonURL);
  }
  public CasinogetJSON(): Observable<any> {
    return this.http.get(this._CasinojsonURL);
  }
  public TopSlotsJson(): Observable<any> {
    return this.http.get(this._TopSlots);
  }
  httpClient(data){
    return this.http.get<slotsData>(`${environment.appApi.baseUrl}${environment.appApi.heblounch}/${data}`, this.playerService.httpOptions());
  }
  httpClientfun():Observable<any>{
    return this.http.get(`${environment.appApi.baseUrl}${environment.appApi.hebfunlounch}`, this.playerService.httpBeforLoginOptions)
    //return this.http.get("http://africa-test.czargaming.com/rest/habanero/handler/"+data, this.httpOptions)
    //return this.http.get("http://trophy777.czargaming.com/rest/habanero/handler/"+data, this.httpOptions)
  }
  casinoBlackJackLunch(){
    return this.http.get(`${environment.appApi.baseUrl}${environment.appApi.casino_blackajack}`, this.playerService.httpOptions());
  }
  casinoBaccaratLunch(){
    return this.http.get(`${environment.appApi.baseUrl}${environment.appApi.casino_baccarat}`, this.playerService.httpOptions());
  }
  casinoEuropeanrouletteLunch(){
    try{
     return this.http.get(`${environment.appApi.baseUrl}${environment.appApi.casino_europeanroulette}`, this.playerService.httpOptions());
    }catch(e){
    }
  }
  casinoOasispokerLunch(){
    return this.http.get(`${environment.appApi.baseUrl}${environment.appApi.casino_Oasispoker}`, this.playerService.httpOptions());
  }

  getSlotsService(data){

    this.store.dispatch(new SlotsGamesLunch(data));
  }
}
