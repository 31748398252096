import { Component, OnInit, ViewChild, ElementRef, OnDestroy } from '@angular/core';
import { Store } from '@ngrx/store';
import * as appState from 'src/app/source/appstates/appState';
import { Subscription } from 'rxjs';
import * as cashierActions from 'src/app/source/appstates/cashierstates/cashierActions';
import { FormGroup, FormControl,FormArray, FormBuilder, Validators } from '@angular/forms';
import * as moment from 'moment';
//import { type } from 'os';
import { TransactionResponse } from '../../source/modules/cashier/transactionsresponsehistory';
import { CashierState } from 'src/app/source/appstates/cashierstates/cashierState';
@Component({
  selector: 'app-transaction-history',
  templateUrl: './transaction-history.component.html',
  styleUrls: ['./transaction-history.component.css'],
  providers: []
})
export class TransactionHistoryComponent implements OnInit,OnDestroy {

  private storeSub: Subscription;
  typeForm :FormGroup;
  form: FormGroup;  
   selectedType:any;
   transaction:any;
   errormessage:string="";
  //types = ["Deposit", "Cashout", "Bonus Adjustment", "Manual Deposit"];
    types = ["Deposit", "Cash Adjustment", "Chargeback",
    "Cashout", "Bonus Adjustment", "Transfer Cancellation",
    "Transfer to Agent", "Transfer from Agent", "Transfer from Player",
    "Transfer to Player", "Referring Revenue", "Cashout Reverse",
    "Manual Deposit"];
    //typesValue="Deposit,Cash Adjustment,Chargeback,Cashout,Bonus Adjustment,Transfer Cancellation,Transfer to Agent,Transfer from Agent,Transfer from Player,Transfer to Player,Referring Revenue,Cashout Reverse,Manual Deposit"
 
    startDate:Date;
    endDate:Date;
    p:number = 1;
    itemsperpagecount = [
      {num :5},
      {num :10},
      {num :15},
      {num :20},
      {num :25}
    ];
  constructor(private fb: FormBuilder, private store: Store<appState.AppState>) {
      
    this.store.dispatch(new cashierActions.CashierGetBalanceStart());
  }

  ngOnInit() {
    
    this.endDate = new Date();
    this.startDate =  new Date();
    this.typeForm = this.fb.group({
      typesValue: this.fb.array([])
    })
    this.form = new FormGroup({
      "currency": new FormControl(sessionStorage.getItem("PlayerCurrency"), Validators.required),
      "startDate": new FormControl(null, Validators.required),
      "endDate": new FormControl(null, Validators.required),
      "limit": new FormControl(20, Validators.required),
      "index": new FormControl(0, Validators.required),
      "type": new FormControl(null, Validators.required)
    })
    this.storeSub = this.store.select("cashierState").subscribe(
      (cashierState: CashierState) => {
        if (cashierState.TransactionResponse) {
          if(cashierState.TransactionResponse.success==true){
            this.errormessage="";
            this.transaction = cashierState.TransactionResponse.values;
          }else if(cashierState.TransactionResponse.success==false){
              this.errormessage = cashierState.TransactionResponse.description;
          }
          //  for (let wallete of this.walleteInfo){
          //   if(wallete.preferred === true){
          //     this.preferredBalance = wallete;
          //     break;
          //   }
          // } 
        }
      }
    )
  }
  ngOnDestroy(){
    if(this.storeSub){
      this.storeSub.unsubscribe();
    }
  }

  
  get today() {
    return new Date();
  }

  onChange(typesValue: string, isChecked: boolean) {
    const typesFormArray = <FormArray>this.typeForm.controls.typesValue;
    if (isChecked) {
      typesFormArray.push(new FormControl(typesValue));
    } else {
      let index = typesFormArray.controls.findIndex(x => x.value == typesValue)
      typesFormArray.removeAt(index);
    }
    this.selectedType = this.typeForm.value.typesValue;
  }
  onFormSubmit(){
    this.store.dispatch(new cashierActions.CashierGetBalanceStart());
    this.form.value.type = this.selectedType.toString();
    this.form.value.startDate = moment(this.form.value.startDate).format('MM-DD-YYYY');
    this.form.value.endDate= moment(this.form.value.endDate).format('MM-DD-YYYY');
    this.store.dispatch(new cashierActions.CashierTransactionHistory(this.form.value));

  }

}
