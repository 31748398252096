import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-how-deposit',
  templateUrl: './how-deposit.component.html',
  styleUrls: ['./how-deposit.component.css']
})
export class HowDepositComponent implements OnInit {

  constructor() { }

  ngOnInit() {
    window.scroll(0,0);
  }

}
