import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import {environment} from '@app/env';
import { Profile } from '../../modules/player/profile';
import { GameLaunchURLs } from '../../modules/player/gameLaunchURLs';
import { LoginService } from '../login/login.service';
import { TournamentsList } from '../../modules/player/tournamentsList';
import { Stats } from '../../modules/player/stats';
import { Store } from '@ngrx/store';
import { PlayerGetToken } from '../../appstates/sblogin/sbloginAction';
import { AppState } from '../../appstates/appState';
import { GameHistory } from '../../modules/player/gameHistory';
import { RemoteGamesHistory } from '../../modules/remotegamehistory/remotegamehistory';
import { UpDateProfileModel } from '../../modules/player/updateprofile';
function launchFlashLobby(params:object) {
  let authWindow = window.open('about:blank', '', 'left=20,top=20,width=800,height=600,toolbar=0,resizable=1');
  authWindow.location.replace("/lobby/lobbyflash.html");
  authWindow.sessionStorage.setItem("flashgamelaunch",JSON.stringify(params));
}

@Injectable({
  providedIn: 'root'
})
export class PlayerService {
  constructor(private httpClient:HttpClient,
    private store:Store<AppState>) { }
  
  onPlayerGetProfile(){
    return this.httpClient.post<Profile>(`${environment.appApi.baseUrl}${environment.appApi.player.getProfile}`,{},this.httpOptions());
  }
  onPlayerUpdateProfile(postData){
    return this.httpClient.post<UpDateProfileModel>(`${environment.appApi.baseUrl}${environment.appApi.player.updateProfile}`,postData,this.httpOptions());
  }
  onPlayerUpdatePassword(postData){
    return this.httpClient.post(`${environment.appApi.baseUrl}${environment.appApi.player.updatePassword}`,postData,this.httpOptions());
  }
  onPlayerGetLaunchURLs(){
    return this.httpClient.post<GameLaunchURLs>(`${environment.appApi.baseUrl}${environment.appApi.player.getLaunchURLS}`,{},this.httpOptions());
  }

  onPlayerGetGameHistory(postdata){
    return this.httpClient.post<GameHistory>(`${environment.appApi.baseUrl}${environment.appApi.history.game}`,postdata,this.httpOptions());
  }

  onPlayerGetRemoteGameHistory(postdata){
    return this.httpClient.post<RemoteGamesHistory>(`${environment.appApi.baseUrl}${environment.appApi.history.remotegame}`,postdata,this.httpOptions());
  }
  onPlayerGetTournamentsList(postdata){
    return this.httpClient.post<TournamentsList>(`${environment.appApi.baseUrl}${environment.appApi.player.tournamentsList}`,postdata,this.httpBeforLoginOptions);
  }

  onPlayerGetStats(){
    return this.httpClient.post<Stats>(`${environment.appApi.baseUrl}${environment.appApi.player.playerStats}`,{},this.httpBeforLoginOptions);
  }

  onPlayerGetPlayerLevels(){
    return this.httpClient.post(`${environment.appApi.baseUrl}${environment.appApi.player.playerLevels}`,{},this.httpOptions());
  }
  onPlayerGetTopCPPlayers(){
    return this.httpClient.get(`${environment.appApi.baseUrl}${environment.appApi.player.topcpplayer}`,this.httpBeforLoginOptions);
  }

  launchFlashGame(value:GameLaunchURLs){
    let params ={serverUrls:[],sessionID:"",clientType:"",LANG:"",swf2load:""};
    value.connections.forEach(value => {
      params.serverUrls.push(value.uri);
    });
    
    params.sessionID = sessionStorage.getItem('wsession').split(".")[1];
    params.clientType="POKER_LOBBY";
    params.LANG="en";
    params.swf2load="flashloader";
    launchFlashLobby(params);
  }
  getSportsBookToken(value:string){
    let data = {
      operatorId:environment.skinId,
      token:"NO",
      loginName:value
    } 
    this.store.dispatch(new PlayerGetToken(data))
  }
  httpOptions() {
    let options = {
      headers: new HttpHeaders({
        'Content-Type':  'application/json',
        'siteid': environment.skinId,
        'wsession':sessionStorage.getItem('wsession'),
        'Authorization': "Bearer " + sessionStorage.getItem('accessToken')
      })
    };
    return options;
  }

  httpBeforLoginOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
      'siteid': environment.skinId
    })
  };
}
