import { Injectable } from '@angular/core';
import { environment } from '@app/env';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, from } from 'rxjs';
import { sportMenuModel } from '../../../source/modules/specific/sportMenu.model';
import { filterSport } from '../../modules/specific/filterBySport.model';
import { bySCL } from '../../modules/specific/bySportCountryLeague.model';
import { SportsData } from '../../modules/specific/matches.model';
import { oddMatch } from '../../modules/specific/oddMatches.model';
import { SportsMenu } from '../../modules/specific/sportsmenu';
import { BetHistory } from '../../modules/BetHistory/bet-history';
import { topTenLeagues } from '../../modules/specific/topleagues';

@Injectable({
    providedIn: 'root'
})
export class sideSportService {
    public authHeader = {
        headers: new HttpHeaders({
            'Content-Type': 'application/json',
            'siteId': environment.skinId
                })
    }

    constructor(public _http: HttpClient) { }

    sportMenuData(): Observable<sportMenuModel> {
        return this._http.get<sportMenuModel>(`${environment.sbBook.sbBaseUrl}/${environment.sbBook.sportMenu}`, this.authHeader);
    }

    filterBySport(sport): Observable<filterSport> {
        const body = {
            sport: sport
        }
        return this._http.get<filterSport>(`${environment.sbBook.sbBaseUrl}/${environment.sbBook.sportName}/${body.sport}`, this.authHeader)
    }

    filterByCountryLeague(sport, country ): Observable<bySCL> {
        const SportCountry = {
            sport: sport,
            country: country
        }
        return this._http.get<bySCL>(`${environment.sbBook.sbBaseUrl}/${environment.sbBook.sportName}/${SportCountry.sport}/${SportCountry.country}`, this.authHeader)
    }
    sendSportCountryLeague(sport, country, league):Observable<SportsData>{
        const SCL = {
            sport: sport,
            country: country,
            league: league,
        }
        return this._http.get<SportsData>(`${environment.sbBook.sbBaseUrl}/${environment.sbBook.sportName}/${SCL.sport}/${SCL.country}/${SCL.league}`, this.authHeader)
    }
    sendOddsMatchId(id): Observable<oddMatch> {
        return this._http.get<oddMatch>(`${environment.sbBook.sbBaseUrl}/${environment.sbBook.sportOddsMatchId}/${id}`, this.authHeader);
    }
    minandmaxsendOddsMatchId(id, min, max): Observable<oddMatch> {
        return this._http.get<oddMatch>(`${environment.sbBook.sbBaseUrl}/${environment.sbBook.sportOddsMatchId}/${id}/${min}/${max}`, this.authHeader);
    }
    searchMatchId(id): Observable<oddMatch> {
        if (id.toString().length == 8) {
            return this._http.get<oddMatch>(`${environment.sbBook.sbBaseUrl}/${environment.sbBook.sportOddsMatchId}/${id}`, this.authHeader);
        } else {
            let sport = sessionStorage.getItem("SportName");
            return this._http.get<oddMatch>(`${environment.sbBook.sbBaseUrl}/${environment.sbBook.sportOddsMatchId}/${sport}/${id}`, this.authHeader);
        }
    }
    getTodayMatches(spName, timeMatch) {
        return this._http.get<any>(`${environment.sbBook.sbBaseUrl}/${environment.sbBook.todayMatches}/${spName}/${timeMatch}`, this.authHeader)
    }
    getTodayoddMatches(spName, timeMatch, min, max) {
        return this._http.get<any>(`${environment.sbBook.sbBaseUrl}/${environment.sbBook.todayMatches}/${spName}/${timeMatch}/${min}/${max}`, this.authHeader)
    }
    getTopTenLeagues(spName){
        return this._http.get<topTenLeagues>(`${environment.sbBook.sbBaseUrl}/${environment.sbBook.toptenleagues}/${spName}`, this.authHeader)
    }
    getNoOfMatches(): Observable<SportsMenu> {
        return this._http.get<SportsMenu>(`${environment.sbBook.sbBaseUrl}${environment.sbBook.matchNO}`, this.authHeader);
      }
      getBetHistory(betData) : Observable<BetHistory>{
        let authHeaders = {
          headers: new HttpHeaders({
            'Content-Type': 'application/json',
            'Authorization': "Bearer " + sessionStorage.getItem('accessToken')
          })
        }
        return this._http.post<BetHistory>(`${environment.sbBook.sbBaseUrl}${environment.sbBook.getBets}`,betData,authHeaders);
      }
      printFeedLeaguesData(sportname){
        return this._http.get<any>(`${environment.sbBook.sbBaseUrl}${environment.sbBook.printleagues}/${sportname}`, this.authHeader);
      }
}