import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http';
import { BetRequest } from '../../modules/specific/bet-request';
import { Observable, of } from 'rxjs';
import { environment } from '@app/env';
import { map, catchError } from 'rxjs/internal/operators';
import { HttpClientError } from '../../modules/login/httpClientError';
const handleError = (error: any, actions:any) => {
  if (error.error instanceof Error) {
      return of(new actions({"message":error.error.message}));
    } else {
      try{
          if(error.error.message){
              return of(new actions({"message":error.error.message}));
          }else if(error.message){
              return of(new actions({"message":error.message}));
          }
          return of(new actions({"message":"Something went wrong please contact admin"}));
      }catch(error){
      }
    }
    return of(new actions({"message":"Something went wrong please contact admin"}));
}
const httpOptions = {
  headers: new HttpHeaders({
    'Content-Type': 'application/json',
  })
};

@Injectable({
  providedIn: 'root'
})
export class BetslipService {
  loginName = 'BlackWidow';
  errorText : HttpClientError;
  private _bonusjsonURL = 'assets/bonusconfig.json';
  constructor(private http: HttpClient) { }


  placeBet(bet, playerLoggedIn): Observable<BetRequest> {
    if (playerLoggedIn == false) {
      let authHeaders = {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          'siteid':environment.skinId,
          'macId': '000',
        })
      }
      //return this.http.post<BetRequest>('http://10.10.20.52:9090/sbapi/bets/betslip', bet, authHeaders);
      return this.http.post<BetRequest>(`${environment.sbBook.sbBaseUrl}${environment.sbBook.anonymousbet}`, bet, authHeaders);
    } else {
      let authHeaders = {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          'Authorization': "Bearer " + sessionStorage.getItem('accessToken'),
          'siteid':environment.skinId
        })
      }
      return this.http.post<BetRequest>(`${environment.sbBook.sbBaseUrl}${environment.sbBook.betSlip}`, bet, authHeaders);
    //return this.http.post<BetRequest>('http://10.10.20.52:9090/sbapi/bets/betslip', bet, authHeaders);
    }
  }

  // createBetInfo(betInfo) {
  //   let authHeaders = {
  //     headers: new HttpHeaders({
  //       'Content-Type': 'application/json'
  //     })
  //   }
  //   return this.http.post(`${environment.sbBook.sbBaseUrl}${environment.sbBook.livebetslip}`, betInfo, authHeaders);
  // }
  
  sentEmail(data) {
     let authHeaders = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
      'siteid': environment.skinId
    })
  }
    return this.http.post<BetRequest>(`${environment.sbBook.sbBaseUrl}${environment.sbBook.emial}`, data, authHeaders);
  }
  getBetProfile(){
    let authHeaders = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': "Bearer " + sessionStorage.getItem('accessToken'),
        'siteid':environment.skinId
      })
    }
    return this.http.get(`${environment.sbBook.sbBaseUrl}${environment.sbBook.betprofile}`, authHeaders);
  
  }

  getBetCupan(data):Observable<any>{
    let authHeaders = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'siteid':environment.skinId
      })
    }
    return this.http.get(`${environment.sbBook.sbBaseUrl}${environment.sbBook.cupancheck}/${data}`, authHeaders).pipe(
      map(response => {
          return response;
      }),
      catchError((error:HttpErrorResponse)=>{
        // alert(error.message);
           return handleError(error, this.errorText);
      })
  );
  }



  public getBonusJSON(): Observable<any> {
    let authHeaders = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'siteid':environment.skinId
      })
    }
    //return this.http.get(this._bonusjsonURL);
    return this.http.get(`${environment.sbBook.sbBaseUrl}${environment.sbBook.bonus}`, authHeaders);
  }
}
