import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { NavigationComponent } from './skins/navigation/navigation.component';
import { CasinoComponent } from './skins/casino/casino.component';
import { SportsMenuComponent } from './skins/sports-menu/sports-menu.component';
import { BetslipComponent } from './skins/betslip/betslip.component';
import { LiveMatchComponent } from './skins/live-match/live-match.component';
import { PreMatchComponent } from './skins/pre-match/pre-match.component';
import { TermsComponent } from './skins/footer/terms/terms.component';
import { PrivacyComponent } from './skins/footer/privacy/privacy.component';
import { PromotionsComponent } from './skins/footer/promotions/promotions.component';
import { ResponsiblesComponent } from './skins/footer/responsibles/responsibles.component';
import { BettingRulesComponent } from './skins/footer/betting-rules/betting-rules.component';
import { FaqComponent } from './skins/footer/faq/faq.component';
import { ContactComponent } from './skins/footer/contact/contact.component';
import { WithdrawalComponent } from './skins/withdrawal/withdrawal.component';
import { GameHistoryComponent } from './skins/game-history/game-history.component';
import { CashOutHistoryComponent } from './skins/cash-out-history/cash-out-history.component';
import { TransactionHistoryComponent } from './skins/transaction-history/transaction-history.component';
import { DepositComponent } from './skins/deposit/deposit.component';
import { BalanceComponent } from './skins/balance/balance.component';
import { SportsComponent } from './skins/sports/sports.component';
import { LiveaddsComponent } from './skins/liveadds/liveadds.component';
import { GoldenraceComponent } from './skins/goldenrace/goldenrace.component';
import { HistoryComponent } from './skins/history/history.component';
import { ResultsComponent } from './skins/results/results.component';
import { PagenotfoundComponent } from './skins/pagenotfound/pagenotfound.component';
import { DepositresponseComponent } from './skins/depositresponse/depositresponse.component';

import { AboutUsComponent } from './skins/footer/about-us/about-us.component';
import { BecomePartnerComponent } from './skins/footer/become-partner/become-partner.component';
import { BonusRulesComponent } from './skins/footer/bonus-rules/bonus-rules.component';
import { CasinoTCComponent } from './skins/footer/casino-tc/casino-tc.component';
import { VirtualTCComponent } from './skins/footer/virtual-tc/virtual-tc.component';
import { TermsAndCondtionsComponent } from './skins/footer/terms-and-condtions/terms-and-condtions.component';
import { HowRegisterComponent } from './skins/footer/how-register/how-register.component';
import { HowWithdrawComponent } from './skins/footer/how-withdraw/how-withdraw.component';
import { HowClaimDepositBonusComponent } from './skins/footer/how-claim-deposit-bonus/how-claim-deposit-bonus.component';
import { HowDepositComponent } from './skins/footer/how-deposit/how-deposit.component';
import { PaymentRulesComponent } from './skins/footer/payment-rules/payment-rules.component';
import { OurRightsComponent } from './skins/footer/our-rights/our-rights.component';
import { BankaccountComponent } from './skins/bankaccount/bankaccount.component';
import { SideSportMatchesComponent } from './skins/side-sport-matches/side-sport-matches.component';
import { BlackjackguideComponent } from './skins/footer/blackjackguide/blackjackguide.component';
import { EurorouletteguideComponent } from './skins/footer/eurorouletteguide/eurorouletteguide.component';
import { OasispokerguideComponent } from './skins/footer/oasispokerguide/oasispokerguide.component';
import { BaccaratguideComponent } from './skins/footer/baccaratguide/baccaratguide.component';
import { FraudsandactionsComponent } from './skins/footer/fraudsandactions/fraudsandactions.component';
import { RouteauthGuard } from './routeauth.guard';
import { EsoccerComponent } from './skins/esoccer/esoccer.component';
import { RegisterComponent } from './skins/register/register.component';
import { EsportsComponent } from './skins/esports/esports.component';
import {PrintfeedComponent} from './skins/printfeed/printfeed.component';
import { LivecasinoComponent } from './skins/livecasino/livecasino.component';
const routes: Routes = [
  {
    path: "", component: NavigationComponent,

    children: [
      { path: "", component: LivecasinoComponent, pathMatch: "full" },
      { path: "livecasino", component: LivecasinoComponent },
      { path: "promotions", component: PromotionsComponent },
      { path: "privacy", component: PrivacyComponent },
      { path: "responsibles", component: ResponsiblesComponent },
      { path: "bettingRules", component: BettingRulesComponent },
      { path: "faq", component: FaqComponent },
      { path: "contact", component: ContactComponent },
      { path: 'results', component: ResultsComponent },
      { path: "casino", component: CasinoComponent },
      { path: 'virtuals', component: GoldenraceComponent },
      { path: "terms", component: TermsComponent },
      { path: "aboutus", component: AboutUsComponent },
      { path: "becomepartner", component: BecomePartnerComponent },
      { path: "bonusrules", component: BonusRulesComponent },
      { path: "casinotermsconditions", component: CasinoTCComponent },
      { path: "virtualtermsconditions", component: VirtualTCComponent },
      { path: "howtoregister", component: HowRegisterComponent },
      { path: "howtowithdraw", component: HowWithdrawComponent },
      { path: "howtodepositbonus", component: HowClaimDepositBonusComponent },
      { path: "howtodeposit", component: HowDepositComponent },
      { path: "paymentrules", component: PaymentRulesComponent },
      { path: "ourrights", component: OurRightsComponent },
      { path: "blackjackguide", component: BlackjackguideComponent },
      { path: "eurorouletteguide", component: EurorouletteguideComponent },
      { path: "oasispokerguide", component: OasispokerguideComponent },
      { path: "baccaratguide", component: BaccaratguideComponent },
      { path: "fraudsandactions", component: FraudsandactionsComponent },
      { path: 'history', component: HistoryComponent},
      { path: "Withdrawal", component: WithdrawalComponent},
      { path: "gameHistory", component: GameHistoryComponent},
      { path: "cashOut", component: CashOutHistoryComponent},
      { path: "transaction", component: TransactionHistoryComponent},
      { path: "deposit", component: DepositComponent},
      { path: "balance", component: BalanceComponent},
      { path: "bankaccount", component: BankaccountComponent},
      {
        path: "", component: SportsComponent,
        children: [
          {
            path: "", component: SportsMenuComponent,
            children: [
              // { path: "", component: LivecasinoComponent, pathMatch: "full" },
              // { path: "livecasino", component: LivecasinoComponent },
              { path: "preMatch", component: PreMatchComponent },
              { path: "oddsprint", component: PrintfeedComponent},
              { path: "register", component: PreMatchComponent },
              { path: "sports", component: SideSportMatchesComponent },
              { path: "esoccer", component: EsoccerComponent },
              { path: "esport", component: EsportsComponent }
            ]
          },
          {
            path: "", component: LiveaddsComponent,
            children: [
              { path: "liveMatch", component: LiveMatchComponent },
            ]
          },
        ]
      },
    ]
  },


  { path: "depositresponse", component: DepositresponseComponent, canActivate:[RouteauthGuard] },
  { path: '**', component: PagenotfoundComponent }
]


@NgModule({
  imports: [RouterModule.forRoot(routes, { onSameUrlNavigation: 'reload' })],
  exports: [RouterModule]
})
export class AppRoutingModule { }