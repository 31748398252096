import { Component, OnInit } from '@angular/core';
import { FormGroup, Validators, FormControl } from '@angular/forms';
import { Subscription, interval, timer } from 'rxjs';
import {LoginService} from 'src/app/source/services/login/login.service';
import { ContactUsResponse } from 'src/app/source/modules/login/contactusresponse';

@Component({
  selector: 'app-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.css']
})
export class ContactComponent implements OnInit {
 
  form: FormGroup;
  errorMessage:string;
  successvalue:boolean;
  contactusresponse:ContactUsResponse;
  contactUsErrorMessage:string;
  constructor(private loginservice:LoginService) {window.scroll(0,0); }

  ngOnInit() {
    window.scroll(0,0);
    this.form = new FormGroup({
      face : new FormControl(null),
      ip : new FormControl(null),
      lang : new FormControl('EN'),
      loginName : new FormControl(null),
      firstName : new FormControl(null, [Validators.required, Validators.pattern('^[a-zA-Z ]*$')]),
      lastName : new FormControl(null),
      email : new FormControl(null,  [Validators.required, Validators.email]),
      subject : new FormControl(null, [Validators.required, Validators.minLength(4)]),
      userQuestion : new FormControl(null, [Validators.required, Validators.minLength(6)]),
      captcha : new FormControl(null)
    })
  }
   
  onFormSubmit() {
    this.loginservice.onContactUsSendStats(this.form.value).subscribe(data => this.contactusresponsedata(data));
  }
  contactusresponsedata(data){
this.contactusresponse = data;
if(this.contactusresponse){
  if(this.contactusresponse.success==true){
    this.successvalue = this.contactusresponse.success;
    //this.contactUsErrorMessage = this.contactusresponse.description;
    this.form.reset();
    this.contactUsErrorMessage = 'Successfully Sent. Our support team will revert back soon.';
  }else if(this.contactusresponse.success == false){
    this.successvalue = this.contactusresponse.success;
    if(this.contactusresponse.description == 'TECHNICAL_ERROR')
    this.contactUsErrorMessage = 'Technical error';
    else
    this.contactUsErrorMessage = this.contactusresponse.description;
  }
}
  }

}
