import { Component, OnInit, HostListener, ViewChild } from '@angular/core';
import { BehaviorSubject, Observable, from, Subscription, interval } from 'rxjs';
import { sportMenuModel, Datum } from '../../source/modules/specific/sportMenu.model';
import { sideSportService } from '../../source/services/sports/sportMenu.service';
import { CommondataService } from '../../source/services/sports/commondata.service';
import { filterSport, filterSportData } from '../../source/modules/specific/filterBySport.model';
import { bySCL, bySCLdata } from '../../source/modules/specific/bySportCountryLeague.model';
import { Store } from '@ngrx/store';
import { LoginState } from 'src/app/source/appstates/loginstates/loginState';
import { ProfileState } from 'src/app/source/appstates/playerstates/playerState';

import { tokenState } from '../../source/appstates/sblogin/sbloginState';
import * as appState from 'src/app/source/appstates/appState';
import { PreMatchComponent } from '../pre-match/pre-match.component';
import { PasswordsComponent } from '../passwords/passwords.component'; 
import { Router } from '@angular/router';
import { SlotsService } from 'src/app/source/services/slots.service';
import { RootObject, Game } from 'src/app/source/modules/slotsmodel/slotsvalue';
import * as cashierActions from 'src/app/source/appstates/cashierstates/cashierActions';
import { slotsData } from 'src/app/source/modules/slotsmodel/slotsdata';
import * as loginActions from 'src/app/source/appstates/loginstates/loginActions';
import * as playerActions from 'src/app/source/appstates/playerstates/playerActions';

declare var $: any;
@Component({
  selector: 'app-sports-menu',
  templateUrl: './sports-menu.component.html',
  styleUrls: ['./sports-menu.component.css'],
})
export class SportsMenuComponent implements OnInit {
  isLoggedIn: boolean = false;
  public width_1: number;
  CountriesOpen: boolean = false;
  LeaguesOpen: boolean = false;
  testNGiF: boolean = true;

  casinogamessuccessPop: boolean = false;
  game: Array<Game>;
  sportMenu: sportMenuModel;
  DataRes: Array<Datum>;
  bySport: filterSport;
  bySportRes: Array<filterSportData>;
  sportMenuErrorMessage: string;
  bySportErrorMessage: string;
  BySCLerrorMessage: string;
  BySCL: bySCL;
  BySCLdata: Array<bySCLdata>;
  jnone: boolean = true;
  token: any;
  rootObject: RootObject;
  sportName: string
  loaderdata: boolean = false;
  casinomessage: string = "";
  slotsdata: slotsData;
  tokendata: any = null;
  keyname: string;
  values: any;
  topcpplayererrormessage: string;
  logoutstore: Subscription;
  playerstore: Subscription;
  bettokenstoreSub: Subscription;
  cpploader: boolean = false;
  p: number = 1;
  selectnum: number = 5;
  showbutton: boolean = false;
  cover_Box: boolean = false;
  lengthofdata: number;
  innerwidth:number;
  private screenWidth$ = new BehaviorSubject<number>
    (window.innerWidth);

  /*@HostListener('window:scroll', ['$event'])
  onWindowScroll(e) {
    if (window.pageYOffset > 150) {
      let element_2 = document.getElementById('sportMenu');
      element_2.classList.add('stickySide');
    } else {
      let element_2 = document.getElementById('sportMenu');
      element_2.classList.remove('stickySide');
    }
  }*/

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.screenWidth$.next(event.target.innerWidth);
  }
  getScreenWidth(): Observable<number> {
    return this.screenWidth$.asObservable();
  }
  @ViewChild(PasswordsComponent, { static: false }) passwords: PasswordsComponent;
  // @ViewChild(NavigationComponent, { static: false }) navigation: NavigationComponent;

  constructor(private slotsservice: SlotsService, private router: Router, private store: Store<appState.AppState>, private _menuServe: sideSportService, private CommondataService: CommondataService) {
    this._menuServe.sportMenuData().subscribe(sportData => this.sportMenuList(sportData));
    this.token = sessionStorage.getItem('accessToken');
    if (this.token != null) {
      this.isLoggedIn = true;
    } else {
      this.isLoggedIn = false;
    }
    let leagueid = sessionStorage.getItem("League");
    if (leagueid != null) {
      if(router.url== '/sports'){
        this._menuServe.sendSportCountryLeague(sessionStorage.getItem("SportName"), sessionStorage.getItem("Country"), sessionStorage.getItem("League")).subscribe(spCountryLeague => this.sportCountryLeague(spCountryLeague));
        // interval(2000 * 60).subscribe(x => {
        //   this._menuServe.sendSportCountryLeague(sessionStorage.getItem("SportName"), sessionStorage.getItem("Country"), sessionStorage.getItem("League")).subscribe(spCountryLeague => this.sportCountryLeague(spCountryLeague));
        // });
      }
    }
    this.slotsservice.getJSON().subscribe(slotdata => {
      this.slotsvaluelunch(slotdata);
    });

    this.innerwidth = (window.innerWidth);
    if (this.innerwidth > 768) {
    } else {
    }


  }
  slotsvaluelunch(slotdata) {
    this.rootObject = slotdata;
    if (this.rootObject) {
      this.game = this.rootObject.Games;
    }
  }

  sportMenuList(sportData) {
    this.sportMenu = sportData;
    if (this.sportMenu.Status == 200) {
      this.DataRes = this.sportMenu.data;
      this.CommondataService.sportslist(this.sportMenu);
    } else {
      this.sportMenuErrorMessage = this.sportMenu.message;
    }
  }

  

  ngOnInit() {
    $(document).ready(function () {
      $('.sub_casino, .sub_others').hide();
      $('.sportUL li.c_1').click(function () {
        $('.spoList').show()
        $('.sportUL li.c_1').removeClass('active');
        $('.subUL').hide()
        $(this).find('.subUL').show()
        $(this).addClass('active');

      });
      if (screen.width < 768) {
        $('.subUL li.sub_2').click(function () {
          $('.subUL').slideUp();
        });
      }
      $('.scrollTop').click(function () {
        $(window).scrollTop("auto");
      });
      $('.subUL_LI_list li').click(function () {
        $('.subUL_LI_list li').removeClass('active');
        $(this).addClass('active');
      })

      $('.sport_1').click(function () {
        $('.sub_sport').show();
        $('.sub_casino, .sub_others').hide();
      });
      $('.casino_1').click(function () {
        $('.sub_casino').show();
        $('.sub_sport, .sub_others').hide();
      });
      $('.others_1').click(function () {
        $('.sub_others').show();
        $('.sub_casino, .sub_sport').hide();
      });

      // if ($(window).width() < 960) {
      //   alert('Less than 960');
      // }

    });
    this.bettokenstoreSub = this.store.select("tokenState").subscribe(
      (tokenState: tokenState) => {
        if (tokenState.sBookLogin) {
          if (tokenState.sBookLogin.accessToken != null) {
            this.isLoggedIn = true;
          } else if (tokenState.sBookLogin.accessToken == null) {
            this.isLoggedIn = false;
          }
        }
      }
    )
    this.logoutstore = this.store.select("loginState").subscribe(
      (loginState: LoginState) => {
        if (loginState.logoutResponse) {
          if (loginState.logoutResponse.success === true) {
            this.isLoggedIn = false;
          } else if (loginState.logoutResponse.success === false) {

          }
        }
      }
    )
    this.playerstore = this.store.select("playerState").subscribe(
      (playerState: ProfileState) => {
        if (playerState.TopCPPlayersres) {
          if (playerState.TopCPPlayersres["success"] === true) {
            this.cpploader = false;
            this.showbutton = true;
            this.topcpplayererrormessage = null;
            this.values = playerState.TopCPPlayersres["values"];
            this.lengthofdata = playerState.TopCPPlayersres["values"].keyvalues.length;
          } else if (playerState.TopCPPlayersres["success"] === false) {
            this.cpploader = false;
            this.values = null;
            this.showbutton = false;
            this.topcpplayererrormessage = playerState.TopCPPlayersres['description']
          }
          if (playerState.TopCPPlayersErrorres) {
            this.cpploader = false;
            this.values = null;
            this.showbutton = false;
            this.topcpplayererrormessage = playerState.TopCPPlayersErrorres.message;
          }
        }
      }
    )
    this.width_1 = document.getElementById("sportMenu").clientWidth;

  } 

  sideMenuClose() {
    this.jnone = false;
    $('.j_menu').addClass('col-md-1').removeClass('col-md-2');
    $('.j_none').addClass('d_none');
    $('.j_side').addClass('col-md-11').removeClass('col-md-10');
  }
  sideMenuOpen() {
    this.jnone = false;
    $('.j_menu').removeClass('col-md-1').addClass('col-md-2');
    $('.j_none').removeClass('d_none');
    $('.j_side').removeClass('col-md-11').addClass('col-md-10');
  }

  clickSport(data) {
    this.sportName = data;
    sessionStorage.setItem("SportName", this.sportName);
    this.loaderdata = true;
    $('.CountriesOpen').show();

    this.CountriesOpen = true;
    this._menuServe.filterBySport(this.sportName).subscribe(countryData => this.countryList(countryData));
  }
  countryList(countryData) {
    $('.country_List').show();
    this.bySport = countryData;
    if (this.bySport.Status == 200) {
      this.bySportErrorMessage = null;
      this.loaderdata = false;
      this.bySportRes = this.bySport.data;
    } else {
      this.bySportRes = null;
      this.loaderdata = false;
      this.bySportErrorMessage = this.bySport.Message;
    }

  }
  selectedcountry(country, sport) {
    this.LeaguesOpen = true;
    $('.LeaguesOpen').show();

    this._menuServe.filterByCountryLeague(country, sport).subscribe(spCountryData => this.sportCountryList(spCountryData));


  }
  // @ViewChild(PreMatchComponent, {static:false})  preMatch:PreMatchComponent;
  upComing() {
    // this.child.showAll();
  }
  sportCountryList(spCountryData) {
    this.BySCL = spCountryData;
    if (this.BySCL.Status == 200) {
      this.sportMenuErrorMessage = null;
      this.BySCLdata = this.BySCL.data;
    } else {
      this.BySCLdata = null;
      this.BySCLerrorMessage = this.BySCL.message;
    }
  }
  hideMenu(){
    if (screen.width < 768) {
      $('.spoList.subUL').slideUp();
    }
  }

  atoz(){
    if(this.innerwidth < 768){
      this.cover_Box = true;
    }
  }
  cover_click(){
    this.cover_Box = false;
    $('.fd.subUL').hide()
  }

  selectLeague(sport, country, league) {
    $('.CountriesOpen').hide();
    $('.country_List').hide();
    $('.LeaguesOpen').hide();
    $('.LeaguesOpen').hide();
    this.cover_Box = false;
    if(this.innerwidth < 768){
      $('.fd.subUL').hide()
      $('.spoList.subUL').slideUp();
    }
    this.router.navigate(['/sports']);
    sessionStorage.setItem("sportName", sport);
    sessionStorage.setItem("Country", country);
    sessionStorage.setItem("League", league);
    this._menuServe.sendSportCountryLeague(sessionStorage.getItem("sportName"), sessionStorage.getItem("Country"), sessionStorage.getItem("League")).subscribe(spCountryLeague => this.sportCountryLeague(spCountryLeague));
  }

  sportCountryLeague(spCountryLeague) {
    this.CommondataService.CountryMatches(spCountryLeague);
  }
  comingsoon() {
    this.passwords.comingSoonPopOpen()
  }

  hitService() {
    let profile = sessionStorage.getItem('profile');
    this.slotsservice.httpClient(profile).subscribe(data => { this.slotslunch(data) });
  };
  slotsinlogout(data) {
    if (this.isLoggedIn == true) {
      this.store.dispatch(new cashierActions.CashierGetBalanceStart());
      this.keyname = data;
      if (this.tokendata == null) {
        this.hitService();
      } else if (this.tokendata != null) {
        window.open(this.slotsdata.HABANERO_GAMING_URL + 'brandid=' + this.slotsdata.BRAND_ID + '&keyname=' + data + '&token=' + this.slotsdata.TOKEN + '&mode=real&locale=en', "_blank");
        return;
      }
    } else if (this.isLoggedIn == false) {
      this.casinomessage = "Please login";
      this.casinogamessuccessPop = true;
    }
  }
  slotslunch(data) {
    if (data) {
      this.slotsdata = data;
      this.tokendata = this.slotsdata.TOKEN;
      return window.open(this.slotsdata.HABANERO_GAMING_URL + 'brandid=' + this.slotsdata.BRAND_ID + '&keyname=' + this.keyname + '&token=' + this.slotsdata.TOKEN + '&mode=real&locale=en', "_blank");

    }
  }
  casinogamessuccessPopClose() {
    this.casinomessage = "";
    this.casinogamessuccessPop = false;
  }
  printfeedleagues() {
    // sessionStorage.setItem('SportName', 'Soccer');
    this.router.navigate(['/oddsprint']);
    //this.store.dispatch(new loginActions.PrintFeedOfleagues('Soccer'));
    //this._mainServe.printFeedLeaguesData(this.sName).subscribe(leagues => this.printfeedres(leagues));
  }
  topcpplayer() {
    this.cpploader = true;
    this.store.dispatch(new playerActions.PlayerGetTopCPPlayers());
  }
  onChange() {
    this.cpploader = false;
    this.showbutton = false;
    this.selectnum = this.lengthofdata;
  }
}
