export const environment = {
  production: false,
  window: window.location.host,
  skinId: 'singacasino',
  logo:'singacasino',
  captchaName:'singacasino',
  // slotsiamgepath: 'http://singacasinop4m.czargaming.com/i/skin/habanero',
   slotsiamgepath: 'assets/img/habanero',
  mrslotty : "https://staging.mrslotty.com/integrations/czargaming/rpc?action=available_games&secret=9e768bf3-6bf9-4203-a0ba-a16ee8893c83&operator_id=singacasino",
  // skinId: 'singa-dev',
  //  logo:'singa-dev',
  //  captchaName:'singa-dev',
  //   slotsiamgepath: 'http://africa-test.czargaming.com/i/skin/habanero',

  sitekey: [
    {
      id: 'singa-dev',
      keyid: '6LfoBsUUAAAAADg8OhPVLdg7QTZhmCvma1xvj85i',
      betslipcontent: "Your bet has been booked. Please print it and go to any SingaCasino betting shop to lay the bet or Load the Betslip after succesful login from 'Load Bet Slip' to place the bet. Odds May Change. ",
      betsliplogin: "Bet Online! If you want to bet online, create an account using our register form. If you're already registered, close this window and login.",
      bookingnumber: "Booking number successfully registred on SingaCasino.",
      couponid: 'Bet successfully registred on SingaCasino.',
      acceptodd: ' I Accept Odd Change and SingaCasino Terms & Conditions.'
    },
    {
      id: 'singacasino',
      keyid: '6LdBOMwUAAAAAHU5yUK-DH0QfX_ibLi6uJ9jS40X',
      betslipcontent: "Your bet has been booked. Please print it and go to any SingaCasino betting shop to lay the bet or Load the Betslip after succesful login from 'Load Bet Slip' to place the bet. Odds May Change. ",
      betsliplogin: "Bet Online! If you want to bet online, create an account using our register form. If you're already registered, close this window and login.",
      bookingnumber: "Booking number successfully registred on SingaCasino.",
      couponid: 'Bet successfully registred on SingaCasino.',
      acceptodd: ' I Accept Odd Change and SingaCasino Terms & Conditions.'
    }
  ],



  sbBook: {
    sbBaseUrl: '/sbapi',
    //sbBaseUrl:'',
    cupancheck: '/bets/coupon',
    emial: '/sendmail/email',
    betprofile: '/bets/betprofile',
    history: '/bets/getbets',
    result: '/sports/result',
    sblogin: '/auth/remote',
    getBets: '/bets/getbets',
    betSlip: '/bets/betslip',
    matchNO: '/sports/matches/no',
    bonus: '/bets/bonus',
    sportMenu: "pms",
    sportName: "pms/sport",
    sportOddsMatchId: "pms/odds",
    todayMatches: "pms/timescope",
    toptenleagues: "pms/top10leagues",
    printleagues: '/pms/print',
    // printodds:'/pms/print/odds',
    printodds: '/pms/print1/odds/pdfreport',
    //spotsDate: '/sports/prematch',
    //matchNO: '/sports/matches/no',
   // league: '/sports/sportleagues',
    //markets: '/sports/markets/',
    //country:'/sports/country',
    //matchesdata:'/sports/country/matches',
    //leaguebasedmatchdata:'/sports/leagues',
    anonymousbet:'/bets/anonymousbet',


  },
  appApi: {
    baseUrl: '',
    livedealer: '/rest/ezugi/handler',
    cockfight: '/rest/sabong/handler',
    //emial: '/rest/bet/email/ticket',
    heblounch: '/rest/habanero/handler',
    casino_blackajack:'/api/player/launchCasinoGame?name=CASINO_BLACKJACK_NGN&real=1&walletCCode=NGN',
    casino_baccarat:'/api/player/launchCasinoGame?name=CASINO_BACCARAT_NGN&real=1&walletCCode=NGN',
    casino_europeanroulette:'/api/player/launchCasinoGame?name=CASINO_EUROPEANROULETTE_NGN&real=1&walletCCode=NGN',
    casino_Oasispoker:'/api/player/launchCasinoGame?name=CASINO_OASISPOKER_NGN&real=1&walletCCode=NGN',
    goldenrace: '/rest/xpress/handler',
    //goldenracefun: '/rest/xpress/handler',
    contactus:"api/player/contactUs",
    meight: 'rest/m8/handler',
    login: '/api/player/login',
    logout: '/api/player/logout',
    register: '/api/player/register',
    getCountries: '/api/player/getCountries',
    fotgotPassword: "/api/player/forgotPassword",
    resetPassword: "/api/player/resetPassword",
    generateOTP: '/api/player/generateOTP',
    allGamesUrl:'/api/player/mrGameURL',
    funGamesUrl:'/rest/mrslotty/mrGameFunURL',
    
  hebfunlounch: '/rest/habanero/habFunMode',
    //generateCaptcha: '/api/player/generateCaptcha',
    player: {
      getProfile: "/api/player/getProfile",
      updateProfile: "/api/player/updateProfile",
      updatePassword: "/api/player/updatePassword",
      verifyAccount: "/api/player/verifyAccount",
      resendToken: "/api/player/resendToken",
      getLaunchURLS: "/api/player/getLaunchURLS",
      tournamentsList: "/api/player/getPokerTournamentsList",
      playerStats: "/api/player/getStats",
      playerCurrencies: "/api/player/getCurrencies",
      games: "/api/player/getGames",
      playerLevels: "/api/cp/playerLevels",
      topcpplayer:"/api/player/getTopCPPlayers"
    },
    cashier: {
      slideplay: '/api/cashier/deposit',
      cashoutotp:'/api/cashier/generateCashOutOTP',
      balance: '/api/cashier/balance',
      resetPlayMoneyBalance: '/api/cashier/resetPlayMoneyBalance',
      getPaymentSystems: '/api/cashier/getPaymentSystems?pgid=',
      depositSystems: '/api/cashier/depositSystems',
      withdrawSystems: '/api/cashier/getCashOutInfo',
      withDrawCashout: '/api/cashier/cashout',
      deposit: '/api/cashier/buy',
      getOpenWithdrawRequests: '/api/cashier/getOpenWithdrawRequests',
      cancelWithdrawRequest: '/api/cashier/cancelWithdrawRequest',
      withdraw: '/api/cashier/withdraw',
      addBankAccount: '/api/cashier/addBankAccount',
      getBankAccounts: '/api/cashier/getBankAccounts',
      deleteBankAccounts: '/api/cashier/deleteBankAccounts',
      paymerobanks:"/api/cashier/getPayMeroBanks?currency="
    },
    history: {
      transaction: '/api/history/transaction',
      cashout:'/api/cashier/getOpenWithdrawRequests',
      //game: "/api/history/game",
      game: "/api/history/casinoGames",
      remotegame:"/api/history/remoteGames"
    }
  },
   };

