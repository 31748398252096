import { Component, OnInit } from '@angular/core';
import { SlotsService } from 'src/app/source/services/slots.service';
import { RootObject, Game } from 'src/app/source/modules/slotsmodel/slotsvalue';
import { Store } from '@ngrx/store';
import * as appState from 'src/app/source/appstates/appState';
import { Subscription } from 'rxjs';
import { LoginState } from 'src/app/source/appstates/loginstates/loginState';
import * as cashierActions from 'src/app/source/appstates/cashierstates/cashierActions';
import { slotsData } from 'src/app/source/modules/slotsmodel/slotsdata';

@Component({
  selector: 'app-searchallgames',
  templateUrl: './searchallgames.component.html',
  styleUrls: ['./searchallgames.component.css']
})
export class SearchallgamesComponent implements OnInit {
  rootObject: RootObject;
  game: Array<Game>;
  casinogamessuccessPop:boolean=false;
  casinomessage:string="";
  slotsdata: slotsData;searchgamesdata;
  tokendata:any=null;
  keyname:string;
  private logoutstore: Subscription;
  playerLoggedIn: boolean = false;
  constructor(private slotsservice: SlotsService, private store: Store<appState.AppState>) { 
    this.slotsservice.getJSON().subscribe(slotdata => {
      this.slotsvaluelunch(slotdata);
    });
   // alert("data");
  }

  ngOnInit() {
    let token = sessionStorage.getItem('accessToken');
  
    this.logoutstore = this.store.select("loginState").subscribe(
      (loginState: LoginState) => {
        if (loginState.loginResponse) {
            this.playerLoggedIn = loginState.playerLoggedIn.loggedIn;
          
        }
      }
    )
  }
  slotsvaluelunch(slotdata) {
    this.rootObject = slotdata;
    if (this.rootObject) {
      this.game = this.rootObject.Games;
    }
  }
  hitService() {
    let profile = sessionStorage.getItem('profile');
    this.slotsservice.httpClient(profile).subscribe(data => { this.slotslunch(data) });
  };
  slotsinlogout(data) {
    if (this.playerLoggedIn == true) {
      this.store.dispatch(new cashierActions.CashierGetBalanceStart());
      this.keyname = data;
      if (this.tokendata == null) {
        this.hitService();
      } else if(this.tokendata != null){
        window.open(this.slotsdata.HABANERO_GAMING_URL+'brandid='+this.slotsdata.BRAND_ID+'&keyname='+data+'&token='+this.slotsdata.TOKEN+'&mode=real&locale=en', "_blank");
        return;
      }
    } else if (this.playerLoggedIn == false) {
      this.casinomessage="Please login";
      this.casinogamessuccessPop=true;
    }
  }
  slotslunch(data) {
    if (data) {
      this.slotsdata = data;
      this.tokendata = this.slotsdata.TOKEN;
      return window.open(this.slotsdata.HABANERO_GAMING_URL+'brandid='+this.slotsdata.BRAND_ID+'&keyname='+this.keyname+'&token='+this.slotsdata.TOKEN+'&mode=real&locale=en', "_blank");

    }
  }
}
