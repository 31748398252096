
import { Component, OnInit, ComponentFactoryResolver, ViewChild, Output, EventEmitter } from '@angular/core';
import { BetslipService } from '../../../source/services/sports/betslip.service';
import { BetEmailResponse } from '../../../source/modules/specific/betemail';
import { NgForm, FormGroup, FormControl, Validators } from "@angular/forms";
import { environment } from '@app/env';
import { CommondataService } from '../../../source/services/sports/commondata.service';
import { BetResponse } from 'src/app/source/modules/specific/bet-response';
import * as appState from 'src/app/source/appstates/appState';
import { Store } from '@ngrx/store';
import { couponBet, Bet, BetString} from 'src/app/source/modules/specific/coupons';
import { CoreEnvironment } from '@angular/compiler/src/compiler_facade_interface';
import { couponBetslip } from 'src/app/source/modules/specific/coupanbeslip';
import * as loginActions from 'src/app/source/appstates/loginstates/loginActions';
//import { RegisterComponent } from '../../register/register.component';
import { Subscription } from 'rxjs';
import { CashierTransactionHistory } from 'src/app/source/appstates/cashierstates/cashierActions';
import { UpdateProfileComponent } from '../../update-profile/update-profile.component';
import { PlaceholderDirectiveDirective } from 'src/app/source/directives/placeholder-directive.directive';
import { BetRequest } from 'src/app/source/modules/specific/bet-request';
import { Betslip } from 'src/app/source/modules/specific/odds.model';
import { LoginState } from 'src/app/source/appstates/loginstates/loginState';
declare var $: any;
function showBetslipModalModal() {
  //$("#betslippopup").modal("show");
  $(".betslippopup").show();
}
function hideBetslipModalModal() {
  $(".betslippopup").hide();
}
@Component({
  selector: 'app-betemail',
  templateUrl: './betemail.component.html',
  styleUrls: ['./betemail.component.css']
})
export class BetemailComponent implements OnInit {
  transactionid: string;
  loginSub: Subscription;
  betemailresponse: BetEmailResponse;
  betSlipResponse: BetResponse;
  
  emailform: FormGroup;
  anonymousbet: string;
  loginbet: string;
  close:any;
  public betArray: Array<Betslip>;
  betRequest: BetRequest;
  date: Date = new Date();
  remotetrue: boolean;
  private closeSub: Subscription;
  couponbet: couponBet;
  betstring:BetString;
  betData: Array<couponBetslip>;
  amount: any;
  logos = environment.logo;
  bookingnumber: string;
  couponid: string;
  remote: boolean;
  betstatus: string = '1';
  seletedBetType:number;
  stakeAmount:number;
  oddChange:boolean;
  captcha:string;
  acceptodd: string;
  betemailmessages:string="";
  betemailsuccessPop:boolean=false;
  playerLoggedIn: boolean = false;
  @ViewChild(PlaceholderDirectiveDirective, { static: true }) adHost: PlaceholderDirectiveDirective;
  constructor( private componentFactoryResolver: ComponentFactoryResolver, private store: Store<appState.AppState>, private commonSevice: CommondataService, private betService: BetslipService) {
    this.commonSevice.couponresdata.subscribe(value => { this.popUpBetID(value) });
 
    for (let i = 0; environment.sitekey[i]; i++)
      if (environment.captchaName == environment.sitekey[i].id) {
        this.anonymousbet = environment.sitekey[i].betslipcontent;
        this.loginbet = environment.sitekey[i].betsliplogin;
        this.bookingnumber = environment.sitekey[i].bookingnumber;
        this.couponid = environment.sitekey[i].couponid;
        this.acceptodd = environment.sitekey[i].acceptodd;
        
      }
    this.transactionid = sessionStorage.getItem("bettransId");
  }
  ngOnInit() {
    this.loginSub = this.store
      .select("loginState")
      .subscribe((loginState: LoginState) => {
        if (loginState.playerLoggedIn) {
          this.playerLoggedIn = loginState.playerLoggedIn.loggedIn;
        }
      });
    showBetslipModalModal();
  }
  popUpBetID(data) {
    this.emailform = new FormGroup({
      "email": new FormControl(null, [Validators.required, Validators.email])
  })
    this.couponbet = data;
    if (this.couponbet.bet.betString.remote === true) {
      this.remote = true;
      this.betData = this.couponbet.bet.betString.betslip;
    } else if (this.couponbet.bet.betString.remote === false) {
      this.remote = false;
      this.betData = this.couponbet.bet.betString.betslip;
    }
  }
  onEmailFormSubmit() {
    if(this.playerLoggedIn == true){
      const betemailresponse: BetEmailResponse = {
        network:environment.skinId,
        action:'BETS',
      data :{
        betslipid: sessionStorage.getItem("bettransId"),
        email: this.emailform.value.email,
      player:sessionStorage.getItem("profile")
      }
      };
      
    this.betService.sentEmail(betemailresponse).subscribe(data => { this.onbetEmailResponse(data) },
    err=> {this.betemailmessages=err.message});
    }else{
      const betemailresponse: BetEmailResponse = {
        network:environment.skinId,
        action:'ANONYMOUS',
      data :{
        betslipid: sessionStorage.getItem("bettransId"),
        email: this.emailform.value.email,
      player:null
      }
      };
      
    this.betService.sentEmail(betemailresponse).subscribe(data => { this.onbetEmailResponse(data) },
    err=> {this.betemailmessages=err.message;
      this.betemailsuccessPop=true;});
    }
 
  }
  onbetEmailResponse(data) {
    if(data.status==1){
      this.betemailmessages=data.Message;
      this.betemailsuccessPop=true;
    }else{
      this.betemailmessages=data.Message;
      this.betemailsuccessPop=true;
    }
  }
  // onRegisterPopUp() {
  //   this.store.dispatch(new loginActions.ResetState());
  //   const alertCmpFactory = this.componentFactoryResolver.resolveComponentFactory(
  //     UpdateProfileComponent
  //   );
  //   const hostViewContainerRef = this.adHost.viewContainerRef;
  //   hostViewContainerRef.clear();
  //   const componentRef = hostViewContainerRef.createComponent(alertCmpFactory);

  //   this.closeSub = componentRef.instance.close.subscribe(() => {
  //     this.closeSub.unsubscribe();
  //     hostViewContainerRef.clear();
  //   });
  // }
  ngOnDestroy() {
    if (this.closeSub) {
      this.closeSub.unsubscribe();
    }
  }
  ananimousplaceBet(){
    if (this.playerLoggedIn == true) {
      const betcalCulation ={
         totalOdds : this.couponbet.bet.betString.totalOdds,
        possiblePayout : this.couponbet.bet.betString.possiblePayout,
        possibleBonus:this.couponbet.bet.betString.possibleBonus.toFixed(2),
        possibleWinnings:this.couponbet.bet.betString.possibleWinnings,
        bonusPercentage:this.couponbet.bet.betString.bonusPercentage,
        selections:this.couponbet.bet.betString.selections,
        totalAomunt:this.couponbet.bet.betString.totalAomunt
    }
      this.betData = this.couponbet.bet.betString.betslip;
      this.seletedBetType = this.couponbet.bet.betString.betType; 
      this.stakeAmount = this.couponbet.bet.betString.betAmount;
      this.oddChange= this.couponbet.bet.betString.acceptOddChange;
      this.captcha = this.couponbet.bet.betString.captcha;
      this.betArray = this.couponbet.bet.betString.betslip;
     // this.betArray = this.couponbet.bet.betString.betslip;  
      //this.betRequest.captcha = null;
      //this.captcha,
      this.betRequest = new BetRequest(this.seletedBetType, this.betArray, this.stakeAmount, this.oddChange, betcalCulation);
      this.betService.placeBet(this.betRequest, this.playerLoggedIn).subscribe(data => { this.emailBetResponse(data); });;
    }else{
      this.betemailmessages='session expired';
      this.betemailsuccessPop=true;
    }
    }
  emailBetResponse(data){
    this.betSlipResponse = data;
    if (this.playerLoggedIn == true) {
        if (this.betSlipResponse.Status == this.betstatus) {
            sessionStorage.removeItem("bettransId");
            sessionStorage.setItem("bettransId", this.betSlipResponse.transactionId);
            this.transactionid = sessionStorage.getItem("bettransId");
            this.betService.getBetCupan(this.betSlipResponse.transactionId).subscribe(data => { this.emailBetopenModalEmail(data); });
        } else if (this.betSlipResponse.Status != this.betstatus) {
          this.betemailmessages=this.betSlipResponse.Message;
          this.betemailsuccessPop=true;
        }
    }else{
      this.betemailmessages='session expired';
      this.betemailsuccessPop=true;
  }
  }
  emailBetopenModalEmail(data){
    this.couponbet = data;
    hideBetslipModalModal();
    if (this.couponbet.Status == -1) {
      this.betemailmessages=this.couponbet.Message;
      this.betemailsuccessPop=true;
    } else {
    showBetslipModalModal();
    }
    this.commonSevice.couponData(this.couponbet);
  }
  closeBetPop(){
    hideBetslipModalModal();
  }

  betemailPopClose(){
    this.betemailmessages="";
    this.betemailsuccessPop=false;
  }
}
