import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-eurorouletteguide',
  templateUrl: './eurorouletteguide.component.html',
  styleUrls: ['./eurorouletteguide.component.css']
})
export class EurorouletteguideComponent implements OnInit {

  constructor() { }

  ngOnInit() {
    window.scroll(0,0);
  }

}
