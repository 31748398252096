import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { Transcation } from 'src/app/source/modules/cashier/transcaionresponse';
import { WithdrawsResponse } from 'src/app/source/modules/cashier/transcaionresponse';
import { CashierService } from 'src/app/source/services/cashier/cashier.service';
import * as cashierActions from 'src/app/source/appstates/cashierstates/cashierActions';
import { AppState } from 'src/app/source/appstates/appState';
import { Store } from '@ngrx/store';
import { LoginState } from 'src/app/source/appstates/loginstates/loginState';
import { Subscription } from 'rxjs';


@Component({
  selector: 'app-cash-out-history',
  templateUrl: './cash-out-history.component.html',
  styleUrls: ['./cash-out-history.component.css']
})
export class CashOutHistoryComponent implements OnInit {

  WithdrawsResponse: WithdrawsResponse[];
  transcation: Transcation;
  loginSub: Subscription;
  withdrawerrormessage: string;
  p: number = 1;
  playerLoggedIn: boolean = false;
  constructor(private cashierservice: CashierService, private store: Store<AppState>) {
    this.store.dispatch(new cashierActions.CashierGetBalanceStart());

  }
  ngOnInit() {
    this.loginSub = this.store
      .select("loginState")
      .subscribe((loginState: LoginState) => {
        if (loginState.playerLoggedIn) {
          this.playerLoggedIn = loginState.playerLoggedIn.loggedIn;
        }
      });
    if (this.playerLoggedIn == true) {
      this.cashierservice.TransactionHistory().subscribe(data => this.transactionHistoryResponse(data),
        err => {
          this.withdrawerrormessage = err.message;
        });
    } else {
    }
  }

  transactionHistoryResponse(data) {
    this.transcation = data;
    if (this.transcation.success == true) {
      this.withdrawerrormessage = null;
      this.WithdrawsResponse = this.transcation.withdrawsResponses;
    }else{
      this.WithdrawsResponse = null;
      if(this.transcation['description'] == 'NO_OPEN_WITHDRAWALS'){
this.withdrawerrormessage = 'No open Withdrawals';
        return;
      }
      this.withdrawerrormessage = "eror occured";
    }
  }

}
