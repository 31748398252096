import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { FormsModule, ReactiveFormsModule  } from '@angular/forms';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HttpClientModule } from '@angular/common/http';
import { NavigationComponent } from './skins/navigation/navigation.component';
import { SportsMenuComponent } from './skins/sports-menu/sports-menu.component';
import { CarouselComponent } from './skins/carousel/carousel.component';
import { BetslipComponent } from './skins/betslip/betslip.component';
import { LiveMatchComponent } from './skins/live-match/live-match.component';
import { PreMatchComponent } from './skins/pre-match/pre-match.component';
import { CasinoComponent } from './skins/casino/casino.component';
import { FooterComponent } from './skins/footer/footer.component';
import { TermsComponent } from './skins/footer/terms/terms.component';
import { PrivacyComponent } from './skins/footer/privacy/privacy.component';
import { PromotionsComponent } from './skins/footer/promotions/promotions.component';
import { ResponsiblesComponent } from './skins/footer/responsibles/responsibles.component';
import { BettingRulesComponent } from './skins/footer/betting-rules/betting-rules.component';
import { FaqComponent } from './skins/footer/faq/faq.component';
import { ContactComponent } from './skins/footer/contact/contact.component';
import { UpdateProfileComponent } from './skins/update-profile/update-profile.component';
import { ChangePasswordComponent } from './skins/change-password/change-password.component';
import { WithdrawalComponent } from './skins/withdrawal/withdrawal.component';
import { GameHistoryComponent } from './skins/game-history/game-history.component';
import { CashOutHistoryComponent } from './skins/cash-out-history/cash-out-history.component';
import { TransactionHistoryComponent } from './skins/transaction-history/transaction-history.component';
import { DepositComponent } from './skins/deposit/deposit.component';
import { BalanceComponent } from './skins/balance/balance.component';
import { PasswordsComponent } from './skins/passwords/passwords.component';
import { SportsComponent } from './skins/sports/sports.component';
import { LiveaddsComponent } from './skins/liveadds/liveadds.component';
import { BetemailComponent } from './skins/betslip/betemail/betemail.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { LoginEffects } from './source/appstates/loginstates/loginEffects';
import { PlayerEffects } from './source/appstates/playerstates/playerEffects';
import { CashierEffects } from './source/appstates/cashierstates/cashierEffects';
import { PlayerHistoryEffects } from './source/appstates/bethistory/betHistoryEffects';
import { resultEffects } from './source/appstates/betresult/betresultEffects';
import { tokenEffects } from './source/appstates/sblogin/sbloginEffects';
import * as appState from 'src/app/source/appstates/appState';
import { GoldenraceComponent } from './skins/goldenrace/goldenrace.component';
import {NgxPaginationModule} from 'ngx-pagination';
import { RegisterComponent } from './skins/register/register.component';
import { DepositresponseComponent } from './skins/depositresponse/depositresponse.component';
import { ResultsComponent } from './skins/results/results.component';
import { HistoryComponent } from './skins/history/history.component';
import { PagenotfoundComponent } from './skins/pagenotfound/pagenotfound.component';
import { PlaceholderDirectiveDirective } from 'src/app/source/directives/placeholder-directive.directive';

import {NgxPrintModule} from 'ngx-print';
import { ResetPasswordComponent } from "./skins/reset-password/reset-password.component";
import { AboutUsComponent } from './skins/footer/about-us/about-us.component';
import { BecomePartnerComponent } from './skins/footer/become-partner/become-partner.component';
import { BonusRulesComponent } from './skins/footer/bonus-rules/bonus-rules.component';
import { CasinoTCComponent } from './skins/footer/casino-tc/casino-tc.component';
import { VirtualTCComponent } from './skins/footer/virtual-tc/virtual-tc.component';
import { TermsAndCondtionsComponent } from './skins/footer/terms-and-condtions/terms-and-condtions.component';
import { HowRegisterComponent } from './skins/footer/how-register/how-register.component';
import { HowWithdrawComponent } from './skins/footer/how-withdraw/how-withdraw.component';
import { HowClaimDepositBonusComponent } from './skins/footer/how-claim-deposit-bonus/how-claim-deposit-bonus.component';
import { HowDepositComponent } from './skins/footer/how-deposit/how-deposit.component';
import { PaymentRulesComponent } from './skins/footer/payment-rules/payment-rules.component';
import { OurRightsComponent } from './skins/footer/our-rights/our-rights.component';
import { BankaccountComponent } from './skins/bankaccount/bankaccount.component';
import { SideSportMatchesComponent } from './skins/side-sport-matches/side-sport-matches.component';
import { PrintfeedComponent } from './skins/printfeed/printfeed.component';
import { BlackjackguideComponent } from './skins/footer/blackjackguide/blackjackguide.component';
import { EurorouletteguideComponent } from './skins/footer/eurorouletteguide/eurorouletteguide.component';
import { OasispokerguideComponent } from './skins/footer/oasispokerguide/oasispokerguide.component';
import { BaccaratguideComponent } from './skins/footer/baccaratguide/baccaratguide.component';
import { FraudsandactionsComponent } from './skins/footer/fraudsandactions/fraudsandactions.component';
import { RouteauthGuard } from './routeauth.guard';
import { EsoccerComponent } from './skins/esoccer/esoccer.component';
import { EsportsComponent } from './skins/esports/esports.component';
import { FilterpipePipe } from './source/pipes/filterpipe.pipe';
import { Ng2SearchPipeModule } from 'ng2-search-filter';
import { PromotionListComponent } from './skins/promotion-list/promotion-list.component';
import { SearchallgamesComponent } from './skins/searchallgames/searchallgames.component';
import { SafePipe } from './source/pipes/safe.pipe';
import { LivecasinoComponent } from './skins/livecasino/livecasino.component';

@NgModule({
  declarations: [
    AppComponent,
    ResultsComponent,
    PagenotfoundComponent,
    HistoryComponent,
    ResetPasswordComponent,
    NavigationComponent,
    SportsMenuComponent,
    CarouselComponent,
    PlaceholderDirectiveDirective,
    BetslipComponent,
    LiveMatchComponent,
    PreMatchComponent,
    CasinoComponent,
    FooterComponent,
    TermsComponent,
    PrivacyComponent,
    PromotionsComponent,
    ResponsiblesComponent,
    BettingRulesComponent,
    FaqComponent,
    ContactComponent,
    UpdateProfileComponent,
    ChangePasswordComponent,
    WithdrawalComponent,
    GameHistoryComponent,
    CashOutHistoryComponent,
    TransactionHistoryComponent,
    DepositComponent,
    BalanceComponent,
    PasswordsComponent,
    SportsComponent,
    LiveaddsComponent,
    BetemailComponent,
    GoldenraceComponent,
    RegisterComponent,
    DepositresponseComponent,
    AboutUsComponent,
    BecomePartnerComponent,
    BonusRulesComponent,
    CasinoTCComponent,
    VirtualTCComponent,
    TermsAndCondtionsComponent,
    HowRegisterComponent,
    HowWithdrawComponent,
    HowClaimDepositBonusComponent,
    HowDepositComponent,
    PaymentRulesComponent,
    OurRightsComponent,
    PrintfeedComponent,  
    BankaccountComponent, SideSportMatchesComponent, BlackjackguideComponent, EurorouletteguideComponent, OasispokerguideComponent, BaccaratguideComponent, FraudsandactionsComponent, EsoccerComponent, EsportsComponent, FilterpipePipe, PromotionListComponent, SearchallgamesComponent, SafePipe, LivecasinoComponent
    
  ],
  imports: [  
    Ng2SearchPipeModule,
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    FormsModule,
    NgxPaginationModule,
    NgxPrintModule,
    ReactiveFormsModule,
    BrowserAnimationsModule,
    StoreModule.forRoot(appState.AppReducer),
    EffectsModule.forRoot([LoginEffects, PlayerEffects, CashierEffects, PlayerHistoryEffects, resultEffects, tokenEffects])
  ],
  providers: [RouteauthGuard],
  bootstrap: [AppComponent],
  entryComponents: [
    BetemailComponent
  ],
})
export class AppModule { }
