import { Component, OnInit, ViewChild, HostListener } from '@angular/core';
import { CommondataService } from '../../source/services/sports/commondata.service';
import { SportsData, GameData, LeagueData, MatchData, HomeoddsData } from '../../source/modules/specific/matches.model';
import { oddMatch, oddData, Selection } from '../../source/modules/specific/oddMatches.model';
import { Betslip, OddsBetslip } from '../../source/modules/specific/odds.model';
import { sideSportService } from '../../source/services/sports/sportMenu.service';
import { sportMenuModel, Datum } from '../../source/modules/specific/sportMenu.model';
import { SportsMenuComponent } from '../sports-menu/sports-menu.component';
import { PasswordsComponent } from '../passwords/passwords.component';
declare var $: any;
@Component({
  selector: 'app-esoccer',
  templateUrl: './esoccer.component.html',
  styleUrls: ['./esoccer.component.css']
})
export class EsoccerComponent implements OnInit {
 leagueDetails: boolean = false;
  allSports: boolean = true;
  sportsData: SportsData;
  gameData: Array<GameData>;
  leagueData: LeagueData;
  matchData: Array<MatchData>;
  homeoddsData: HomeoddsData;
  CountryName: string;
  SportName: string;
  comingFromSportMenu: boolean = false;
  SportMenuOddsMatches: boolean = false;
  OddMatch: oddMatch;
  OddData: Array<oddData>;
  odds: Array<Selection>;
  oddType: string;
  matchid: number;
  betSlip: Array<Betslip>;
  bet: Betslip;
  sportMenu: sportMenuModel;
  DataRes: Array<Datum>;
  sportMenuErrorMessage: string;
  errorMessageResponse: string;
  leagueName: string;
  mchNM: string;
  dateTime: any;
  loaderdata:boolean= true;
  countryName:string="Esoccer";
  selectedIndex: number = 0;
  innerWidth: number;
  sName:string="Soccer";
  days:string ="all"; 
  Noofdata:number;
  loaderOddData: boolean = false;
  OddDataerrormessage: string; 
  searchText;
  @ViewChild(SportsMenuComponent, { static: false }) sideM: SportsMenuComponent;
  @ViewChild(PasswordsComponent, { static: false }) passwords: PasswordsComponent;


  constructor(private CommondataService: CommondataService, private _mainServe: sideSportService) {
    this.innerWidth = (window.innerWidth);
    if (this.innerWidth > 760) {
    } else {
    }
    //this._mainServe.sportMenuData().subscribe(sportData => this.sportMenuList(sportData));
    this._mainServe.getTodayMatches(this.sName, this.days).subscribe(matches => this.getMatches(matches));
    //this.CommondataService.matchData.subscribe(value => { this.getSportData(value) });
  }

  sportMenuList(sportData) {
    this.sportMenu = sportData;
    if (this.sportMenu.Status == 200) {
      this.DataRes = this.sportMenu.data;
    } else {
      this.sportMenuErrorMessage = this.sportMenu.message;
    }
  }


  oddMarkets(oddId) {
    this.OddMatch = oddId;
    if (this.OddMatch.Status == 200) {
      this.OddDataerrormessage = null;
      this.loaderOddData = false;
      this.OddData = this.OddMatch.data;
    } else {
      this.OddData = null;
      this.loaderOddData = false;
      this.OddDataerrormessage = this.OddMatch.Message;
    }
  }

  getSportData(value) {
    this.sportsData = value;
    if (this.sportsData.Status == 200) {
      this.gameData = this.sportsData.data;
      this.leagueData = this.gameData[0].league;
      this.leagueName = this.gameData[0].league.league;
      this.SportName = this.gameData[0].league.sport;
      this.CountryName = this.gameData[0].league.country;
      this.matchData = this.gameData[0].league.matches;
      this.comingFromSportMenu = true;
    }
  }

  ngOnInit() {
    $(document).ready(function () {
      $('.sportsULlist li').click(function () {
        // $('.sportsULlist li').removeClass('active');
        $(this).addClass('active');
        $('.sportsULlist li').removeClass('active');
      });
      $('.tab_match .match_ch').click(function () {
        $('.match_ch').removeClass('active');
        $(this).addClass('active')
      });
    });
  }

  j_hideMtch() {
    $(".j_markets").toggleClass('wid_50');
    $(".j_matches").toggle();
  }

  show_league(id, matchNM, date, time) {
    $(window).scrollTop(350);
    if (this.innerWidth > 767) {
      $(document).ready(function () {
        $('.j_matches, .j_markets').addClass("wid_50");
        $('.match_12').removeClass('col-sm-6').addClass("col-sm-12");
      });
    } else {
      $(document).ready(function () {
        $('.j_matches').hide();
        // $('.j_markets').show();
        $('.match_12').removeClass('col-sm-6').addClass("col-sm-12");
      });
    };  
     this.OddMatch = null;
    this.OddData=null;
    this.OddDataerrormessage=null;
    this.loaderOddData = true;
    this.leagueDetails = false;
    this.allSports = false;
    this.SportMenuOddsMatches = true;
    this._mainServe.sendOddsMatchId(id).subscribe(oddId => this.oddMarkets(oddId));
    this.mchNM = matchNM;
    this.matchid = id;
    this.dateTime = date+ "-" +time;
    this.sideM.sideMenuClose();
  }
  closeSportMenuOddsMatches() {
    this.SportMenuOddsMatches = false;
    this.leagueDetails = false;
    this.sideM.sideMenuOpen();
    if (this.innerWidth > 767) {
      $(".j_matches").removeClass('wid_50').removeClass('d-none');
      $('.match_12').toggleClass('col-sm-6').toggleClass("col-sm-12");
    } else {
      $(".j_matches").show();
    }
  }

  SideMenuMarkets() {
    this.leagueDetails = true;
  }

  showAll(data) {
    this.onclicksportdata();
    this.leagueDetails = false;
    this.allSports = true;
this.days = data;
    this._mainServe.getTodayMatches(this.sName, this.days).subscribe(matches => this.getMatches(matches));
  }
  showToday(data) {
    this.onclicksportdata();
    this.days = data;
    this._mainServe.getTodayMatches(this.sName, this.days).subscribe(matches => this.getMatches(matches));
  }
  showAllTomorrow(data) {
    this.onclicksportdata();
    this.days = data;
    this._mainServe.getTodayMatches(this.sName, this.days).subscribe(matches => this.getMatches(matches));
  }

  //  setIndex(index: number) {
  //     this.selectedIndex = index;
  //   }
  sportName(sportName, index: number) {
    this.closeSportMenuOddsMatches();
    this.sName = sportName;
    this.selectedIndex = index;
    this._mainServe.getTodayMatches(sportName, this.days).subscribe(matches => this.getMatches(matches));
    $(".j_matches").show();

  }
  getMatches(matches) {
    this.sportsData = matches;
if( this.sportsData.Status == 200){
  this.errorMessageResponse =null;
  this.loaderdata= false;
  for(let data of this.sportsData.data){
    if(data.league.country == this.countryName){
      this.Noofdata = data.league.country.length;
    }else{
      this.Noofdata = 0;
    }
  }
  this.matchData = this.gameData[0].league.matches;
   }else{
    this.gameData =null;
    this.loaderdata= false;
     this.errorMessageResponse =  this.sportsData.Message;
   }
  }

  homeodd(data, sdd) {
    //this.passwords.comingSoonPopOpen()
    this.bet = new Betslip(data, sdd, this.countryName);
    this.CommondataService.OddData(this.bet);
  }

  selectedodd(ods, type, title, matcnm, matchid) {
   //this.passwords.comingSoonPopOpen()
    this.bet = new OddsBetslip(ods, type, title, matcnm, matchid, this.dateTime, this.countryName);
    this.CommondataService.OddData(this.bet);
  }
  onclicksportdata(){
    this.errorMessageResponse =null;
    this.gameData=null;
    this.OddMatch = null;
    this.OddData=null;
    this.OddDataerrormessage=null;
    this.closeSportMenuOddsMatches();
    this.loaderdata= true;
  }
  

}
