import { Component, OnInit } from '@angular/core';
import { SlotsService } from 'src/app/source/services/slots.service';
import { BehaviorSubject, Observable, Subscription } from 'rxjs';
import { Store } from '@ngrx/store';
import * as appState from 'src/app/source/appstates/appState';
import { slotsData, casinogmaelunch, KeyVsValue } from 'src/app/source/modules/slotsmodel/slotsdata';
import { RootObject, Game } from 'src/app/source/modules/slotsmodel/slotsvalue';
import { buildRootObject, buildGame } from 'src/app/source/modules/slotsmodel/builsslots';
import { environment } from '@app/env';
import { CoreEnvironment } from '@angular/compiler/src/compiler_facade_interface';
import * as cashierActions from 'src/app/source/appstates/cashierstates/cashierActions';
import * as loginActions from 'src/app/source/appstates/loginstates/loginActions';
import { tokenState } from '../../source/appstates/sblogin/sbloginState';
import { LoginState } from 'src/app/source/appstates/loginstates/loginState';
import { CasinoGames, Casino } from 'src/app/source/modules/goldenraceinterface/goldenrace';
import { AllGames, Response } from "src/app/source/modules/slotsmodel/allgames";
import { Router, ActivatedRoute } from '@angular/router';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { ProfileState } from 'src/app/source/appstates/playerstates/playerState';
import { CounterHandle } from 'src/app/source/services/common/CounterHandle.service';
declare var $: any;
@Component({
  selector: 'app-casino',
  templateUrl: './casino.component.html',
  styleUrls: ['./casino.component.css']
})
export class CasinoComponent implements OnInit {

  slotsfundata: slotsData;
  brandid: any;
  loginSub: Subscription;
  playerName: string;
  playerLoggedIn: boolean = false;
  private storeProfileSub: Subscription;
  private bettokenstoreSub: Subscription;
  private logoutstore: Subscription;
  private allGamesStore: Subscription;
  private allGamesLunchStore: Subscription;
  private profilestoreSubslots: Subscription;
  allGamesSlots: Response[];
  selectnum: number = 5;
  itemsperpagecount = [
    { num: 5 },
    { num: 10 },
    { num: 15 },
    { num: 20 },
    { num: 25 }
  ];
  p: number = 1;
  casinomessage: string;
  casinogamessuccessPop: boolean = false
  casinogames: CasinoGames;
  slotsdata: slotsData;
  rootObject: RootObject;
  tableObject: RootObject;
  buildRootObject: buildRootObject;
  buildGame: buildGame;
  game: Array<Game>;
  tablegame: Array<Game>;
  Casinresponse: Array<Casino>;
  KeyVsValue: KeyVsValue;
  balackjackplayerContextId: string;
  baccaratplayerContextId: string;
  europeanrouletteplayerContextId: string;
  OasispokerplayerContextId: string;
  sessionid: any;
  casinogmaelunch: casinogmaelunch;
  imagespath = environment.slotsiamgepath;
  keyname: string;
  tokendata: any = null;
  gameLunchUrl: any;
  urlSafe: SafeResourceUrl;
  mrslottygametype: string = 'mr1';
  constructor(public sanitizer: DomSanitizer, private route: ActivatedRoute,
    private router: Router, private slotsservice: SlotsService,
    private store: Store<appState.AppState>, private counterHandler: CounterHandle) {

    this.hitfunService();
    this.store.dispatch(new cashierActions.CashierGetBalanceStart());
    this.store.dispatch(new loginActions.allGamesStart());
    this.slotsservice.CasinogetJSON().subscribe(data => { this.Casinoimages(data) });
    this.slotsservice.getJSON().subscribe(slotdata => {
      this.slotsvaluelunch(slotdata);
    });
    this.slotsservice.getTablegamesJSON().subscribe(tabledata => {
      this.tablevaluelunch(tabledata);
    });
  }

  slotsvaluelunch(slotdata) {
    this.rootObject = slotdata;
    if (this.rootObject) {
      this.game = this.rootObject.Games;
    }
  }
  tablevaluelunch(tabledata) {
    this.tableObject = tabledata;
    if (this.tableObject) {
      this.tablegame = this.tableObject.Games;
    }
  }
  ngOnInit() { 

    this.store.dispatch(new loginActions.ResetState());
    this.loginSub = this.store
      .select("loginState")
      .subscribe((loginState: LoginState) => {
        if (loginState.playerLoggedIn) {
          this.playerLoggedIn = loginState.playerLoggedIn.loggedIn;
        }
      });

    this.allGamesStore = this.store.select("loginState").subscribe(
      (loginState: LoginState) => {
        if (loginState.allgames) {
          this.allGamesSlots = loginState.allgames.response;

        }
        if (loginState.playerSlotty) {

          this.urlSafe = null;
          localStorage.setItem('mrslottyid', loginState.playerSlotty.idvalue)
          this.mrslottygametype = loginState.playerSlotty.idvalue;
        }
      }

    );
    this.storeProfileSub = this.store.select("playerState").subscribe(
      (profileState: ProfileState) => {
        if (profileState.profile) {
          if (profileState.profile.success === true) {
            if (profileState.profile.login) {
              this.playerName = profileState.profile.login;
              this.hitService(this.playerName);
            }
          } else if (profileState.profile.success === false) {
          }
        }
      })

    this.mrslottygametype = localStorage.getItem('mrslottyid');
    this.allGamesLunchStore = this.store.select("loginState").subscribe(
      (loginState: LoginState) => {
        if (loginState.allGamesLunch) {
          if (loginState.allGamesLunch.status == 200) {
            this.casinomessage = "";
            this.gameLunchUrl = loginState.allGamesLunch.gameUrl;
            if (this.gameLunchUrl) {
              this.slotsGameLunch();
            }

          } else if (loginState.allGamesLunch.status != 200) {
            this.casinogamessuccessPop = true;
            if (loginState.allGamesLunch.description) {
              this.casinomessage = loginState.allGamesLunch.description;
            } else {
              this.casinomessage = "Unknown Error"
            }
          }
        }
        if (loginState.allGamesLunchError) {
          this.casinogamessuccessPop = true;
          if (loginState.allGamesLunchError.message) {
            this.casinomessage = loginState.allGamesLunchError.message;
          } else {
            this.casinomessage = "Unknown Error"
          }
        }
      }
    )
    if (this.playerLoggedIn == true) {
      this.store.dispatch(new cashierActions.CashierGetBalanceStart());
      //  this.hitService();
    } else if (this.playerLoggedIn == false) {
    }

  }

  onActivate(event) {
    window.scroll(0,0);
    //or document.body.scrollTop = 0;
    //or document.querySelector('body').scrollTo(0,0)
}


  Casinoimages(data) {
    this.casinogames = data;
    if (this.casinogames) {
      this.Casinresponse = this.casinogames.Casino;
    }
  };
  casinoGamesSubmit(id: any) {
    if (this.playerLoggedIn == true) {
      this.store.dispatch(new cashierActions.CashierGetBalanceStart());
      this.sessionid = sessionStorage.getItem('wsession').substr(sessionStorage.getItem('wsession').indexOf(".") + 1);
      if (id == 101) {
        this.slotsservice.casinoEuropeanrouletteLunch().subscribe(data => { this.casinoEuropeanroulette(data) });
      } else if (id == 102) {
        this.slotsservice.casinoBaccaratLunch().subscribe(data => { this.casinoBaccarat(data) });
      } else if (id == 103) {
        this.slotsservice.casinoBlackJackLunch().subscribe(data => { this.casinoBlackJack(data) });
      } else if (id == 104) {
        this.slotsservice.casinoOasispokerLunch().subscribe(data => { this.casinoOasispoker(data) });
      }
    } else if (this.playerLoggedIn == false) {
      this.casinogamessuccessPop = true;
    }

  };
  responseofsession() {

  };

  casinoBlackJack(data) {
    this.store.dispatch(new loginActions.ResetState());
    this.casinogmaelunch = data;
    if (this.casinogmaelunch.success == true) {
      if (this.casinogmaelunch.keyVsValue.playerContextId != null) {
        this.balackjackplayerContextId = this.casinogmaelunch.keyVsValue.playerContextId;
        return window.open("http://redsonbetp4m.czargaming.com/htmlgames/blackjack/index.html?gameName=BLACKJACK&language=EN&wallet=" + sessionStorage.getItem('PlayerCurrency') + "&sessionId=" + this.sessionid + "&gameSession=" + this.balackjackplayerContextId, '_blank');
      }
    } else {
      this.casinomessage = this.casinogmaelunch.description;
    }
  };
  casinoBaccarat(data) {
    this.store.dispatch(new loginActions.ResetState());
    this.casinogmaelunch = data;
    if (this.casinogmaelunch.success == true) {
      if (this.casinogmaelunch.keyVsValue.playerContextId != null) {
        this.baccaratplayerContextId = this.casinogmaelunch.keyVsValue.playerContextId;
        return window.open("http://redsonbetp4m.czargaming.com/htmlgames/baccarat/index.html?gameName=BACCARAT&language=EN&wallet=" + sessionStorage.getItem('PlayerCurrency') + "&sessionId=" + this.sessionid + "&gameSession=" + this.baccaratplayerContextId, '_blank');
      }
    } else {
      this.casinomessage = this.casinogmaelunch.description;
    }
  };
  casinoEuropeanroulette(data) {
    this.store.dispatch(new loginActions.ResetState());
    this.casinogmaelunch = data;
    if (this.casinogmaelunch.success == true) {
      if (this.casinogmaelunch.keyVsValue.playerContextId != null) {
        this.europeanrouletteplayerContextId = this.casinogmaelunch.keyVsValue.playerContextId;
        return window.open("http://redsonbetp4m.czargaming.com/htmlgames/europeanroulette/index.html?gameName=EUROPEANROULETTE&language=EN&wallet=" + sessionStorage.getItem('PlayerCurrency') + "&sessionId=" + this.sessionid + "&gameSession=" + this.europeanrouletteplayerContextId, '_blank');
      }
    } else {
      this.casinomessage = this.casinogmaelunch.description;
    }
  }
  casinoOasispoker(data) {
    this.casinogmaelunch = data;
    if (this.casinogmaelunch.success == true) {
      if (this.casinogmaelunch.keyVsValue.playerContextId != null) {
        this.OasispokerplayerContextId = this.casinogmaelunch.keyVsValue.playerContextId;
        return window.open("http://redsonbetp4m.czargaming.com/htmlgames/oasispoker/index.html?gameName=OASISPOKER&language=EN&wallet=" + sessionStorage.getItem('PlayerCurrency') + "&sessionId=" + this.sessionid + "&gameSession=" + this.OasispokerplayerContextId, '_blank');
      }
    } else {
      this.casinomessage = this.casinogmaelunch.description;
    }
  }
  hitService(profile) {
    // let profile = sessionStorage.getItem('profile');
    this.slotsservice.httpClient(profile).subscribe(data => { this.slotslunch(data) });
  };
  hitfunService() {
    //let profile = sessionStorage.getItem('profile');
    this.slotsservice.httpClientfun().subscribe(data => { this.slotslunchfun(data) });
  };
  slotsreal(data) {
    this.urlSafe = null;
    this.store.dispatch(new loginActions.ResetState());
    if (this.playerLoggedIn == true) {
      this.store.dispatch(new cashierActions.CashierGetBalanceStart());
      this.keyname = data;
      if (this.tokendata == null) {
        let profile = sessionStorage.getItem('profile');
        this.hitService(profile);
      } else if (this.tokendata != null) {
        this.gameLunchUrl = this.slotsdata.HABANERO_GAMING_URL + 'brandid=' + this.slotsdata.BRAND_ID + '&keyname=' + this.keyname + '&token=' + this.slotsdata.TOKEN + '&mode=real&locale=en';
        if (this.gameLunchUrl) {
          this.slotsGameLunch();
        }
        return;
      }
    } else if (this.playerLoggedIn == false) {

      this.casinomessage = "Please login";
      this.casinogamessuccessPop = true;
    }
  }
  slotsfun(data) {
    this.urlSafe = null;
    this.keyname = data;
    if (this.brandid == null) {
      this.hitfunService();
    } else if (this.brandid != null) {
      this.gameLunchUrl = this.slotsfundata.HABANERO_GAMING_URL + 'brandid=' + this.slotsfundata.BRAND_ID + '&keyname=' + this.keyname + '&mode=fun&locale=en';
      if (this.gameLunchUrl) {
        this.slotsGameLunch();
      }
      return;
    }

  }

  slotslunch(data) {
    if (data) {
      this.slotsdata = data;
      this.tokendata = this.slotsdata.TOKEN;
      // this.gamelunchurl= this.slotsdata.HABANERO_GAMING_URL+'brandid='+this.slotsdata.BRAND_ID+'&keyname='+this.keyname+'&token='+this.slotsdata.TOKEN+'&mode=real&locale=en';
      // this.urlSafe= this.sanitizer.bypassSecurityTrustResourceUrl(this.gamelunchurl);
      // window.scroll(0,450);
      // return;
    }
  }
  slotslunchfun(data) {
    if (data) {
      this.slotsfundata = data;
      this.brandid = this.slotsfundata.BRAND_ID;
      //  this.gamelunchurl= this.slotsdata.HABANERO_GAMING_URL+'brandid='+this.slotsdata.BRAND_ID+'&keyname='+this.keyname+'&token='+this.slotsdata.TOKEN+'&mode=fun&locale=en';
      // this.urlSafe= this.sanitizer.bypassSecurityTrustResourceUrl(this.gamelunchurl);
      //  window.scroll(0,450);
      //  return;
    }
  }
  slotsGameLunchfun(id: any, providername: any) {
    this.urlSafe = null;
    this.store.dispatch(new loginActions.ResetState());
    const body = {
      gameId: id,
      gameMode: 'demo_play',
      provider: providername
    }
    this.store.dispatch(new loginActions.allGamesLunchStart(body));
  }

  slotsGameLunchreal(id: any, providername: any) {
    this.urlSafe = null;
    this.store.dispatch(new loginActions.ResetState());
    if (this.playerLoggedIn == true) {
      const body = {
        gameId: id,
        gameMode: 'real_play',
        provider: providername
      }
      this.store.dispatch(new cashierActions.CashierGetBalanceStart());
      this.store.dispatch(new loginActions.allGamesLunchStart(body));
    } else if (this.playerLoggedIn == false) {
      this.casinogamessuccessPop = true;
      this.casinomessage = 'Please Login';
    }
  }
  casinogamessuccessPopClose() {
    this.casinogamessuccessPop = false;
    this.casinomessage = "";
  }
  slotsGameLunch() {
    window.scroll(0,20);
    this.urlSafe = this.sanitizer.bypassSecurityTrustResourceUrl(this.gameLunchUrl);
    return;
  }
  onChange(data) {
    this.selectnum = data;
  }
  gameStarted() {
    this.counterHandler.stopCounter();
  }



  closegame(){
    this.urlSafe = "";
  }
}
