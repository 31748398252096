import { Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import * as appState from 'src/app/source/appstates/appState';
import { FormGroup, FormControl, Validators, NgForm } from '@angular/forms';
import { Subscription } from 'rxjs';
import * as cashierActions from 'src/app/source/appstates/cashierstates/cashierActions';
import { PlayerGetGameHistory, PlayerGetRemoteGameHistory } from 'src/app/source/appstates/playerstates/playerActions';
import * as moment from 'moment';
import * as loginActions from 'src/app/source/appstates/loginstates/loginActions';
//import { GameHistory } from 'src/app/source/modules/player/gameHistory';
import { ProfileState } from 'src/app/source/appstates/playerstates/playerState';
import { RemoteGamesHistory } from 'src/app/source/modules/remotegamehistory/remotegamehistory';
import { LoginState } from 'src/app/source/appstates/loginstates/loginState';
import { Response } from 'src/app/source/modules/slotsmodel/allgames';
import { SlotsService } from 'src/app/source/services/slots.service';
import { RootObject, Game } from 'src/app/source/modules/slotsmodel/slotsvalue';
import { LoginService } from 'src/app/source/services/login/login.service';

@Component({
  selector: 'app-game-history',
  templateUrl: './game-history.component.html',
  styleUrls: ['./game-history.component.css'],
})
export class GameHistoryComponent implements OnInit {
  private profilestoreSub: Subscription;
  private profileRemotestoreSub: Subscription;
  private allGamesStore: Subscription;
  gamehistory: any;
 form: FormGroup;
  remoteform:FormGroup;
  allGamesSlots: Response[];
 // tablegame:Array<Tablegame>;
 gameHistoryErrorMessage:string="";
  profile:string;
  startDate:Date;
  endDate:Date;
  casinoobject: RootObject;
  tableObject: RootObject;
  casinogame: Array<Game>;
  tbgame: Array<Game>;
  RemoteGamesHistory:RemoteGamesHistory;
  remotehistory:any;
  totaldata:number;
  description:string;


  pageSize: any = "5";
  page: number = 1;
  count: number;
  indexs: number;
  index: string;
  limit: string;
  sDate: any;
  eDate: any;


  p:number = 1;
  n:number = 1;
  remoteGameHistoryErrorMessage:string;

  totalrecords:number;

  descriptiondata:string;
  gameName=["BLACKJACK", "BACCARAT", "EUROPEANROULETTE", "OASISPOKER"];

  constructor(private slotsservice: SlotsService, private store: Store<appState.AppState>, private loginservice: LoginService) {

    this.store.dispatch(new cashierActions.CashierGetBalanceStart());

    this.store.dispatch(new loginActions.allGamesStart());
    this.slotsservice.getJSON().subscribe(slotdata => {
      this.casinovaluelunch(slotdata);
    });
    this.slotsservice.getTablegamesJSON().subscribe(slotdata => {
      this.tablevaluelunch(slotdata);
    });
   }

 
  ngOnInit() {
    
    this.getIndex();

    this.profile = sessionStorage.getItem('profile');
    // this.endDate = new Date();
    // this.startDate =  new Date();
    // this.form = new FormGroup({
    //   "currency": new FormControl(sessionStorage.getItem('PlayerCurrency')),
    //   "startDate": new FormControl(null, Validators.required),
    //   "endDate": new FormControl(null, Validators.required),
    //   "name": new FormControl(null, Validators.required),
    //   "limit": new FormControl(this.pageSize),
    //   "index": new FormControl("0"),
    // });

    // this.remoteform = new FormGroup({
    //   "currency": new FormControl(sessionStorage.getItem('PlayerCurrency')),
    //   "startDate": new FormControl(null, Validators.required),
    //   "endDate": new FormControl(null, Validators.required),
    //   "name": new FormControl(null),
    //   "limit": new FormControl(this.pageSize),
    //   "index": new FormControl("0"),
    // });


    this.allGamesStore = this.store.select("loginState").subscribe(
      (loginState: LoginState) => {
        if (loginState.allgames) {
          this.allGamesSlots = loginState.allgames.response;
          //this.tablegame =loginState.allgames.games.tablegames;
        }
      }
    );
    this.profilestoreSub = this.store.select("playerState").subscribe(
      (playerState: ProfileState) => {
        if (playerState.GameHistory) {
          if (playerState.GameHistory.success == true) {
            this.totaldata = playerState.GameHistory.total;
            this.gamehistory = playerState.GameHistory.values;
            this.descriptiondata = "No Records Found";
            this.gameHistoryErrorMessage=null;
          }else if(playerState.GameHistory.success==false){
            this.gamehistory=null;
            this.descriptiondata=null;
            this.gameHistoryErrorMessage = playerState.GameHistory.description;
          }
        }
        if(playerState.GameHistoryError){
          this.gamehistory=null;
          this.descriptiondata=null;
          this.gameHistoryErrorMessage = playerState.GameHistoryError.message;
        }
      }
    );
     // this.onremoteFormSubmit();
     this.profileRemotestoreSub = this.store.select("playerState").subscribe(
      (playerState: ProfileState) => {
        if (playerState.remotegameshistory) {
          if (playerState.remotegameshistory.success == true) {
            if (playerState.remotegameshistory.total == 0) {
              this.totalrecords = playerState.remotegameshistory.total;
              this.remoteGameHistoryErrorMessage = "There is no History  for the selected Period";
            } else {
              this.totalrecords = playerState.remotegameshistory.total;
              this.count = this.totalrecords;
              this.remotehistory = playerState.remotegameshistory.values;
            }
          } else if (playerState.remotegameshistory.success == false) {
            if(playerState.remotegameshistory.description == 'NO_DATA_FOUND'){
              this.remoteGameHistoryErrorMessage = "There is no History  for the selected Period";
              return;
            }
            this.remoteGameHistoryErrorMessage = playerState.remotegameshistory.description;
          }
        }
        if (playerState.remotegameshistoryError) {
          this.remoteGameHistoryErrorMessage = playerState.remotegameshistoryError.message;
        }
      }
    )
    // this.profileRemotestoreSub = this.store.select("playerState").subscribe(
    //   (playerState: ProfileState) => {
    //     if (playerState.remotegameshistory) {
    //       if (playerState.remotegameshistory.success == true) {
    //         this.totalrecords= playerState.remotegameshistory.total;
    //         this.count = this.totalrecords;
    //         this.remotehistory = playerState.remotegameshistory.values;
    //         this.descriptiondata = "No Records Found";
    //         this.remoteGameHistoryErrorMessage=null;
    //       }else if(playerState.remotegameshistory.success==false){
    //         this.remotehistory=null;
    //         this.descriptiondata=null;
    //         this.remoteGameHistoryErrorMessage = playerState.remotegameshistory.description;
    //       }
    //     }
    //     if(playerState.remotegameshistoryError){
    //       this.remotehistory=null;
    //       this.descriptiondata=null;
    //       this.remoteGameHistoryErrorMessage = playerState.remotegameshistoryError.message;
    //     }
    //   }
    // )
  }
  // onFormSubmit() {
  //   this.form.value.startDate = moment(this.form.value.startDate).format('DD-MM-YYYY');
  //   this.form.value.endDate = moment(this.form.value.endDate).format('DD-MM-YYYY');
  //   this.store.dispatch(new cashierActions.CashierGetBalanceStart());
  //   this.store.dispatch(new PlayerGetGameHistory(this.form.value));
  // }


  getIndex(){
    this.loginservice.indexsForReport().subscribe(
      response => {
        this.getIndexResponseHandler(response);
      }
    )
  }

  getIndexResponseHandler(response){
    this.indexs = response.values;
  }


  onremoteFormSubmit(form : NgForm){
     this.nullmsg();  
    this.sDate = moment(form.value.startDate).format('DD-MM-YYYY');
    this.eDate = moment(form.value.endDate).format('DD-MM-YYYY');
    this.page = 1;
    let data = {
    startDate: this.sDate,
    endDate: this.eDate,
    name: null,
    limit: this.pageSize,
    index: "0"
    }
   this.store.dispatch(new cashierActions.CashierGetBalanceStart());
   this.store.dispatch(new PlayerGetRemoteGameHistory(data));
   
 }


    changePageSize(pageSizeSelected) {
    //  alert("change limit worked" + JSON.stringify(pageSizeSelected.target.value));
    this.page = 0;
    this.pageSize = pageSizeSelected.target.value;
    this.limit = this.pageSize;
    this.startDate;
    this.endDate;
    let data = {
      startDate: this.sDate,
      endDate: this.eDate,
      name: null,
      limit: this.pageSize,
      index: ""
      }
      this.store.dispatch(new cashierActions.CashierGetBalanceStart());
      this.store.dispatch(new PlayerGetRemoteGameHistory(data));
  } 


  pageChange(i) {
// this.nullmsg();
    this.page = i;
    // alert(JSON.stringify(this.page));
    this.limit = this.pageSize;
    this.index = String((this.page - 1) * this.pageSize);
    let data = {
      startDate: this.sDate,
      endDate: this.eDate,
      name: null,
      limit: this.pageSize,
      index: this.index
      }
    this.store.dispatch(new PlayerGetRemoteGameHistory(data));
  }
  casinovaluelunch(slotdata) {
    this.casinoobject = slotdata;
    if (this.casinoobject) {
      this.casinogame = this.casinoobject.Games;
    }
  }
  tablevaluelunch(slotdata) {
    this.tableObject = slotdata;
    if (this.tableObject) {
      this.tbgame = this.tableObject.Games;
    }
  }
  ngOnDestroy(){
    if(this.profilestoreSub){
      this.profilestoreSub.unsubscribe();
    }
    if(this.profileRemotestoreSub){
      this.profileRemotestoreSub.unsubscribe();
    }
  }
nullmsg(){
  
  this.totalrecords = null;
  this.remotehistory = null;
  this.remoteGameHistoryErrorMessage=null;
}
}
