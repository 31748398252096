import { Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { Subscription } from 'rxjs';
import * as appState from 'src/app/source/appstates/appState';
import * as cashierActions from 'src/app/source/appstates/cashierstates/cashierActions';
import { LoginState } from 'src/app/source/appstates/loginstates/loginState';
import { LivedealerService } from 'src/app/source/services/livedealer/livedealer.service';

@Component({
  selector: 'app-livecasino',
  templateUrl: './livecasino.component.html',
  styleUrls: ['./livecasino.component.css']
})
export class LivecasinoComponent implements OnInit {

  playerLoggedIn: boolean = false;
  private loginstoreSub: Subscription;
  casinogamessuccessPop: boolean = false;
  casinomessage: string = "";
  constructor(private store: Store<appState.AppState>,private Livedealerservice: LivedealerService,) { }

  ngOnInit() {
    this.loginstoreSub = this.store.select("loginState").subscribe(
      (loginState: LoginState) => {
        if (loginState.playerLoggedIn) {
          this.playerLoggedIn = loginState.playerLoggedIn.loggedIn;
        }
      }
    )
  }
  liveDealer() {
    this.store.dispatch(new cashierActions.CashierGetBalanceStart());
    if (this.playerLoggedIn == true) {
      let logiName = sessionStorage.getItem('profile');
      this.Livedealerservice.liveDealerServiceData(logiName).subscribe(liveDealerdata => {
        this.liveDealerunch(liveDealerdata);
      });
    } else if (this.playerLoggedIn == false) {
      this.casinomessage = "Please login";
      this.casinogamessuccessPop = true;
    }
  }

  liveDealerunch(data) {
    let ezugiLunch = data.EZUGI_GAME_LAUNCH_URL;
    window.open(ezugiLunch, '_blank');
  }
  casinogamessuccessPopClose() {
    this.casinogamessuccessPop = false;
    this.casinomessage = "";
  }
}
