import { Actions, Effect, ofType } from '@ngrx/effects';
import { Injectable } from '@angular/core';
import * as loginActions from './loginActions';
import { forgotResponse } from '../../modules/forgotpassword';
import {catchError, map, switchMap, tap, exhaustMap} from "rxjs/internal/operators";
//import { Jsonxml } from '../../utils/jsonxml';
import { LoginService } from '../../services/login/login.service';
import { Router, ActivatedRoute } from '@angular/router';
import { of } from 'rxjs';
import { HttpErrorResponse } from '@angular/common/http';
import { Login } from '../../modules/login/login';
import { on } from '@ngrx/store';
import { Countries } from '../../modules/login/countries';

//import { ToastrService } from 'ngx-toastr';
import { LoginOut } from '../../modules/login/logout';
import { ResetPassword } from '../../modules/login/resetpassword';
import { OtpRequest } from '../../modules/login/otprequest';
import { PrintLeaguesRes } from '../../modules/common/printfeedleagues';
import { PrintFeedOddsRes } from '../../modules/common/printfeedodds';
import { AllGames } from '../../modules/slotsmodel/allgames';
import { AllGameLunch } from '../../modules/slotsmodel/allgamelunch';
const handleError = (error: any, actions:any) => {
    if (error.error instanceof Error) {
        return of(new actions({"message":error.error.message}));
      } else {
        try{
            if(error.error.message){
                return of(new actions({"message":error.error.message}));
            }else if(error.message){
                return of(new actions({"message":error.message}));
            }
            return of(new actions({"message":"Something went wrong please contact admin"}));
        }catch(error){
        }
      }
      return of(new actions({"message":"Something went wrong please contact admin"}));
}
@Injectable()
export class LoginEffects{
    isLoggedIn:boolean;
    token:any;
    constructor(
        private actions$:Actions, 
        private loginservice:LoginService,
       // private toastr : ToastrService,
        private router: Router,
        private route: ActivatedRoute){}

    @Effect()
    loginStart = this.actions$.pipe(
        ofType(loginActions.LOGIN_START),
        exhaustMap((onLoginStart:loginActions.LoginStart) => 
        this.loginservice.onLogin(onLoginStart.payload)
        .pipe(
            map((response:Login) => {
                return new loginActions.LoginSuccess(response);
            }),
            catchError((error:HttpErrorResponse)=>{
                return handleError(error, loginActions.LoginFail);
                })
        )
        )
    )

    @Effect({dispatch:false})
    loginSuccess = this.actions$.pipe(
        ofType(loginActions.LOGIN_SUCCESS),
        tap((onLoginSuccess:loginActions.LoginSuccess)=>{
            if(onLoginSuccess.payload.success === true){
                sessionStorage.setItem('wsession', onLoginSuccess.payload.sessionId);
                 this.loginservice.getMyprofile();
                 //this.router.navigate(['/preMatch']);
                 return;
            }
            this.loginservice.onplayerLoggedIn(false);
        })
    )

    @Effect({dispatch:false})
    loginFail = this.actions$.pipe(
        ofType(loginActions.LOGIN_FAIL),
        tap((onLoginFailed:loginActions.LoginFail)=>{
        })
    )


    @Effect()
    registerStart = this.actions$.pipe(
        ofType(loginActions.REGISTER_START),
        exhaustMap((onRegisterStart:loginActions.RegisterStart) => 
        this.loginservice.onRegister(onRegisterStart.payload)
        .pipe(
            map(response => {
                return new loginActions.RegisterSuccess(response);
            }),
            catchError((error:HttpErrorResponse)=>{
                return handleError(error,loginActions.RegisterFail);
            })
        )
        )
    )


    @Effect({dispatch:false})
    registerSuccess = this.actions$.pipe(
        ofType(loginActions.REGISTER_SUCCESS),
        tap((onRegisterSuccess:loginActions.RegisterSuccess)=>{
            if(onRegisterSuccess.payload.success === true){
                sessionStorage.setItem('wsession', onRegisterSuccess.payload.loginResponse.sessionId);
                this.loginservice.getMyprofile(); 
                this.loginservice.registerMessage();
                this.router.navigate(['/preMatch']);
            }else {
                this.loginservice.onplayerLoggedIn(false);
            }
        })
    )

    @Effect()
    registerGetCountry = this.actions$.pipe(
        ofType(loginActions.REGISTER_GET_COUNTRY),
        exhaustMap((onRegisterGetCountry:loginActions.RegisterGetCountry) => 
        this.loginservice.onGetRegisterCountry()
        .pipe(
            map((response:Countries) => {
                return new loginActions.RegisterGetCountrySuccess(response);
            }),
            catchError((error:HttpErrorResponse)=>{
                //this.toastr.error(error.message);
                return handleError(error,loginActions.RegisterGetCountryFail);
            })
        )
        )
    )




    @Effect()
    logoutStart = this.actions$.pipe(
        ofType(loginActions.LOGOUT_START),
        exhaustMap((onLogOutStart:loginActions.LogoutStart) => 
        this.loginservice.onLogOut()
        .pipe(
            map((response:LoginOut) => {
                return new loginActions.LogoutSuccess(response);
            }),
            catchError((error:HttpErrorResponse)=>{
                return handleError(error,loginActions.LogoutFail);
            })
        )
        )
    )

    @Effect({dispatch:false})
    logoutSuccess = this.actions$.pipe(
        ofType(loginActions.LOGOUT_SUCCESS),
        tap((onLogOutSuccess:loginActions.LogoutSuccess)=>{
            if(onLogOutSuccess.payload.success === true){
               sessionStorage.removeItem('accessToken');
                sessionStorage.removeItem('wsession');
                sessionStorage.removeItem('profile');
                sessionStorage.removeItem('playermobilenumber');
                this.loginservice.onplayerLoggedIn(false);
               window.location.reload();
               this.router.navigate(['/preMatch']);
               window.scroll(0, 0);
            }
        })
    )
    @Effect({dispatch:false})
    LogoutFail = this.actions$.pipe(
        ofType(loginActions.LOGOUT_FAIL),
        tap((onLogOutFail:loginActions.LogoutFail)=>{
            if(onLogOutFail.payload["success"] === false || onLogOutFail.payload["code"] === "SESSION_EXPIRED"){
               
                sessionStorage.removeItem('accessToken');
                sessionStorage.removeItem('wsession');
                sessionStorage.removeItem('profile');
                sessionStorage.removeItem('playermobilenumber');
                this.loginservice.onplayerLoggedIn(false);
                window.location.reload(); 
                this.router.navigate(['/preMatch']);
                window.scroll(0, 0);
            }
        })
    )

    @Effect()
    forgotPasswordStart = this.actions$.pipe(
        ofType(loginActions.FORGOTPASSWORD_START),
        exhaustMap((onForgoutPasswordStart:loginActions.ForgotPasswordStart) => 
        this.loginservice.onForgotPassword(onForgoutPasswordStart.payload)
        .pipe(
            map((response:forgotResponse) => {
               // this.toastr.success("Details Sent to your registered Email Id ");
                return new loginActions.ForgotPasswordSuccess(response);
            }),
            catchError((error:HttpErrorResponse)=>{
                //this.toastr.error(error.message);
                return handleError(error,loginActions.ForgotPasswordFail);
            })
        )
        )
    )
    @Effect()
    ResetPasswordStart = this.actions$.pipe(
        ofType(loginActions.RESETPASSWORD_START),
        exhaustMap((onResetPasswordStart:loginActions.ResetPasswordStart) => 
        this.loginservice.onResetPassword(onResetPasswordStart.payload)
        .pipe(
            map((response :ResetPassword)=> {
               // this.toastr.success("Details Sent to your registered Email Id ");
                return new loginActions.ResetPasswordSuccess(response);
            }),
            catchError((error:HttpErrorResponse)=>{
                //this.toastr.error(error.message);
                return handleError(error,loginActions.ResetPasswordFail);
            })
        )
        )
    )
    @Effect({dispatch:false})
    forGotPasswordSuccess = this.actions$.pipe(
        ofType(loginActions.FORGOTPASSWORD_SUCCESS),
        tap((onForgotPasswordSuccess:loginActions.ForgotPasswordSuccess)=>{
            if(onForgotPasswordSuccess.payload.success === true){
            } else if (onForgotPasswordSuccess.payload.success === false) {
            }
        })
    )
    @Effect({dispatch:false})
    ForgotPasswordFail = this.actions$.pipe(
        ofType(loginActions.FORGOTPASSWORD_FAIL),
        tap((onForgotPasswordFail:loginActions.ForgotPasswordFail)=>{
            if(onForgotPasswordFail.payload.success === true){
            } else if (onForgotPasswordFail.payload.success===false) {
                
            }
        })
    )


    @Effect()
    registerGenarateOTP = this.actions$.pipe(
        ofType(loginActions.REGISTER_GENERATE_OTP),
        exhaustMap((onRegisterGenarateOTPStart: loginActions.RegisterGenarateOTP) =>
            this.loginservice.onRegisterGenarateOTPStart(onRegisterGenarateOTPStart.payload)
                .pipe(
                    map((response: OtpRequest) => {
                        return new loginActions.RegisterGenarateOTPSuccess(response);
                    }),
                    catchError((error: HttpErrorResponse) => {
                        return handleError(error, loginActions.RegisterGenarateOTPFail);
                    })
                )
        )
    )

    @Effect()
    PrintFeedOfleagues = this.actions$.pipe(
        ofType(loginActions.PRINT_FEED_LEAGUES_START),
        exhaustMap((onPrintFeedOfleagues: loginActions.PrintFeedOfleagues) => 
        this.loginservice.printFeedLeaguesData(onPrintFeedOfleagues.payload)
                .pipe(
                    map((response:PrintLeaguesRes) => {
                        return new loginActions.PrintFeedOfleaguesSuccess(response);
                    }),
                    catchError((error: HttpErrorResponse) => {
                        return handleError(error, loginActions.PrintFeedOfleaguesFail);
                    })
                )
        )
    )
    // @Effect()
    // PrintFeedOfOdds = this.actions$.pipe(
    //     ofType(loginActions.PRINT_ODDS_START),
    //     exhaustMap((onPrintFeedOfOdds: loginActions.PrintFeedOfOdds) => 
    //     this.loginservice.printFeedOddsData(onPrintFeedOfOdds.payload)
    //             .pipe(
    //                 map((response :PrintFeedOddsRes)=> {
    //                     return new loginActions.PrintFeedOfOddsSuccess(response);
    //                 }),
    //                 catchError((error: HttpErrorResponse) => {
    //                     return handleError(error, loginActions.PrintFeedOfOddsFail);
    //                 })
    //             )
    //     )
    // )

    @Effect()
    allGamesStart = this.actions$.pipe(
        ofType(loginActions.ALL_GAMES_START),
        exhaustMap((onallGamesStart: loginActions.allGamesStart) => 
        this.loginservice.AllGamesData()
                .pipe(
                    map((response:AllGames)=> {
                        return new loginActions.allGamesSuccess(response);
                    }),
                    catchError((error: HttpErrorResponse) => {
                        return handleError(error, loginActions.allGamesFail);
                    })
                )
        )
    )
    @Effect()
    allGamesLunchStart = this.actions$.pipe(
        ofType(loginActions.ALL_GAMES_LUNCH_START),
        exhaustMap((onallGamesLunchStart: loginActions.allGamesLunchStart) => 
        this.loginservice.AllGamesLunch(onallGamesLunchStart.payload)
                .pipe(
                    map((response:AllGameLunch)=> {
                        return new loginActions.allGamesLunchSuccess(response);
                    }),
                    catchError((error: HttpErrorResponse) => {
                        return handleError(error, loginActions.allGamesLunchFail);
                    })
                )
        )
    )
}