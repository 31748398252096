import { Component, HostListener, OnInit } from '@angular/core';
import { Sports } from '../../source/modules/common/livedata/sports';
import { CommondataService } from '../../source/services/sports/commondata.service';
import { WebsocketService } from '../../source/services/sports/websocket.service';
import { WebService } from '../../source/services/sports/web.service';
import { __core_private_testing_placeholder__ } from '@angular/core/testing';
import { LiveBetslip } from '../../source/modules/common/livedata/live-betslip';
import { BindingFlags } from '@angular/compiler/src/core';
import { environment } from '../../../environments/environment';
declare let $: any;
@Component({
  selector: 'app-live-match',
  templateUrl: './live-match.component.html',
  styleUrls: ['./live-match.component.css']
})
export class LiveMatchComponent implements OnInit {
  livedata: Sports;
  leaguesdata: any;
  odds: any;
  matchName1: string;
  matchName2: string;
  allsports: string;
  sportName: String;
  zeroIndexMarkets = true;
  allMarkets = false;
  oddType: string = 'eu';
  matchId: number;
  bet: LiveBetslip;
  leagueIndex = 0;
  selectOdd: any;
  innerWidth: number;
  matchindex = true;
  withoutmatchindex = false;
  sideView_F = false;
  sideView_T = true;
  leaguevalue: string;
  selectedIndex: number = -1;
  loaderdata:boolean=true;
  nodata:string;
  
  SelectedOddTypeName: any = {};
  SelectedLeagueName: any = {};
  selectedbetslipmaps: any = {};
  betMatchId: any = {};
  betOddType: any = {};
  betOddTittle: any = {};
  betArray: any;
  oddtypename: string;
  objectKeys = Object.keys;
  constructor(private CommondataService: CommondataService, private socketService: WebsocketService, private webservice: WebService) {
    this.CommondataService.betArrayOdd.subscribe(value => { this.assignToBetData(value)});
    webservice.send();
    
    // this.CommondataService.Isselectedoddtype.subscribe(data => this.handleOddType(data));
    
    let getvalue = true;
    this.CommondataService.getbetArrayfind(getvalue);
  }
  ngOnInit(): void {
    this.CommondataService.live$.subscribe(data => {
      this.handleSuccessfulResponse(data);
    });
    this.CommondataService.liveonclosed$.subscribe(data => {this.handleoncloseResponse(data)});
    this.allfun();
    // throw new Error("Method not implemented.");
  }

  @HostListener('window:scroll', ['$event']) 
    scrollHandler(event) {
    let doc = document.getElementById('prematch_80vh');
    let y = doc.scrollTop;
    if( y > 100){
        document.getElementById('testIng').style.display = 'none';
      } else {
        document.getElementById('testIng').style.display = 'block';
      }
    }

  types = [{ 'name': 'EU', 'value': "value_eu" }, { 'name': 'NA', 'value': "value_na" }, { 'name': 'US', 'value': "value_us" }]
  forallmarkets(mn1, mn2) {
    //this.zeroIndexMarkets = false;
    this.allMarkets = true;
    this.matchName1 = mn1;
    this.matchName2 = mn2;
	$(window).scrollTop(150);
    this.innerWidth = (window.innerWidth);
    if (this.innerWidth > 760) {
      this.sideView_T = false;
      this.sideView_F = false;
      // $(document).ready(function () {
      //   $('.j_matches, .j_markets').addClass("wid_50");
      //   $('.match_12').removeClass('col-sm-6').addClass("col-sm-12");
      // });
    } else {
      this.sideView_T = false;
      this.sideView_F = false;
    }
  }
  closeSportMenuOddsMatches() {
    this.sideView_T = true;
    this.sideView_F = false;
    this.allMarkets = false;
    
  this.SelectedOddTypeName = {};
  }
   
  getLeagues(value: any, i: any) {
    //   this.zeroIndexMarkets = true;
    //this.allMarkets = false;
    this.leagueIndex = i;
    this.leaguevalue = value;
    if (value in this.SelectedLeagueName) {
      delete this.SelectedLeagueName[value];
    } else {
      this.SelectedLeagueName[value] = this.leaguevalue;

    }
  }
  handleSuccessfulResponse(data) {
    this.livedata = data;
    if(this.livedata['status'] == 1){
      this.setError("Something went wrong with live data");
      return;
    }
    if(Object.keys(this.livedata.data).length === 0
    && this.livedata.data.constructor === Object){
      this.setError("NO Data Found");
     }else{
      this.nodata=""
      this.leaguesdata = this.livedata.data;  
        this.CommondataService.betArrayOdd.subscribe(value => { this.assignToBetData(value)});
      this.loaderdata=false; 
     }
  }
  handleoncloseResponse(data){

    this.loaderdata=false;
  }
  // handleOddType(value) {
  //   if (value == 'EU') {
  //     this.oddType = 'eu';
  //   } else if (value == 'NA') {

  //     this.oddType = 'na';
  //   } else if (value == 'US') {

  //     this.oddType = 'us';
  //   } else {

  //     this.oddType = 'eu';
  //   }
  // }
  setMoreMarkets(id) {
    this.matchId = id;
  }
  keepOrder = (a, b) => { return a; }


  clickLiveSport(name: string, index: number) {
    this.sportName = name;
    this.selectedIndex = index;
    this.allsports = ''
    this.sideView_T = true;
    this.sideView_F = false;
    this.allMarkets = false;
  }
  allfun() {
    this.selectedIndex = -1
    this.allsports = 'all'
  }
  clickBet(oddData, title, type, participants, $event) {
    let div = $event.currentTarget;
    let isActive = div.classList.contains('active');
    if (isActive) {
      div.classList.remove("active");
    } else {
      div.classList.add("active");
    }
    this.bet = new LiveBetslip(oddData, title, type, participants);
    this.CommondataService.OddData(this.bet);

  }

  oddinfo(oddtype) {
    this.oddtypename = oddtype;
    if (oddtype in this.SelectedOddTypeName) {
      delete this.SelectedOddTypeName[oddtype];
    } else {
      this.SelectedOddTypeName[oddtype] = this.oddtypename;

    }
  }
  assignToBetData(data) {
    this.selectedbetslipmaps = {};
    this.betMatchId = {};
    this.betOddType = {};
    this.betOddTittle = {};
    this.betArray = data;
    for(let every of this.betArray){
         this.selectedbetslipmaps[every.oddId] = true;
        // this.betMatchId[every.matchId] = true;
        // this.betOddType[every.oddstype] = true;
        // this.betOddTittle[every.oddTitle] = true;
    }
  
  }
  setError(error){
    const body = {
      code: 1
    }
    this.CommondataService.setLiveonclosed(body);
    this.leaguesdata=null;
    this.loaderdata=false;
    this.nodata = error;
  }
}
