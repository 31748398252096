import { Component, OnInit, OnDestroy } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Store } from '@ngrx/store';
import * as appState from 'src/app/source/appstates/appState';
import * as loginActions from 'src/app/source/appstates/loginstates/loginActions';
import { Subscription, interval } from 'rxjs';
import { LoginState } from 'src/app/source/appstates/loginstates/loginState';
import { Countries, Country } from 'src/app/source/modules/login/countries';
import { tokenState } from '../../source/appstates/sblogin/sbloginState';
import { environment } from '@app/env';
import { map, take } from 'rxjs/internal/operators';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-carousel',
  templateUrl: './carousel.component.html',
  styleUrls: ['./carousel.component.css']
})
export class CarouselComponent implements OnInit {
  formRegister: FormGroup;
  playerLoggedIn: boolean;
  selectedcountry;
  errorMessage: string;
  phonevalue: any;
  undefiend: any;
  selecteddata: any;
  emailvalue: any;
  forgotform: FormGroup;
  registerloader: boolean = false;
  private storeSub: Subscription;
  private tokenstoreSub: Subscription;
  private countries: Countries;
  country: Country[];
  registerPopup: boolean = false;
  otpRequesterrorMessage: string;
  registerpopmessage: string;
  registersuccessPop: boolean = false;
  urlof: string; reID: any;
  reType: any;
  selectedcurrency:string;
  currencyvalues = [
    {id:'+66', value:"764", name:'THB'},
    {id:'+60', value:"458", name:'MYR'},
    {id:'+62', value:"360", name:'IDR'},
    {id:'+84', value:"704", name:'VND'},
    {id:'+91', value:"356", name:'INR'}
  ];
  countryvalues= [
    {id:'+66', value:"764", name:'Thailand'},
    {id:'+60', value:"458", name:'Malaysia'},
    {id:'+62', value:"360", name:'Indonesia'},
    {id:'+84', value:"704", name:'Vietnam'},
    {id:'+91', value:"356", name:'India'}
  ];
  constructor(private route: ActivatedRoute, private router: Router, private store: Store<appState.AppState>) {

    this.store.dispatch(new loginActions.ResetState());
  }

  ngOnInit() {
    this.urlof = this.router.url.substr(0, this.router.url.indexOf("?"));
    if (this.urlof == '/register') {
      this.openRegisterPop();
      this.route.queryParams.subscribe(params => {
        this.reID = params.ID;
        this.reType = params.type;
      });

      if (this.reID != null) {
        this.store.dispatch(new loginActions.ResetState());
      }
    }
    this.store.dispatch(new loginActions.ResetState());

    this.formRegister = new FormGroup({
      "register": new FormGroup({
        "login": new FormControl(null, [Validators.required, Validators.minLength(4)]),
        "nickname": new FormControl(null),
        "email": new FormControl(null, [Validators.required, Validators.email]),
        //"firstName": new FormControl(null),
        //"lastName": new FormControl(null),
        //  "otp": new FormControl(null, Validators.required),
        "campaignCode": new FormControl(this.reID),
        "referralType": new FormControl(this.reType),
        "password": new FormControl(null, [Validators.required, Validators.minLength(6)]),
        "currency": new FormControl(null),
        "address": new FormGroup({
          // "city": new FormControl(null),
          "phone": new FormControl(null, [Validators.required, Validators.pattern('[4-9]\\d{9}')]),
          //"country": new FormControl(),
          "country": new FormControl(null, Validators.required),
        })
      }),
      "acceptTerms": new FormControl(null, [Validators.required, Validators.requiredTrue])
    })


   
    this.storeSub = this.store.select("loginState").subscribe(
      (loginState: LoginState) => {
        if (loginState.countries) {
          if (loginState.countries.success === true) {
            this.countries = loginState.countries;
            this.country = this.countries.values;
          } else if (loginState.countries.success === false) {
            this.errorMessage = loginState.loginResponse.description;
          }
        }

        if (loginState.loginResponse) {
          if (loginState.loginResponse.success === true) {
          } else if (loginState.loginResponse.success === false) {
            this.registerloader = false;
            this.errorMessage = loginState.loginResponse.description;
          }
        }
        if (loginState.loginErrorResponse) {
          this.registerloader = false;
          this.errorMessage = loginState.loginErrorResponse.message;
        }
        if(loginState.playerLoggedIn){
          if(loginState.playerLoggedIn){
            this.playerLoggedIn=loginState.playerLoggedIn.loggedIn;
          }
        }
      }
    )
    this.tokenstoreSub = this.store.select("tokenState").subscribe(
      (tokenState: tokenState) => {
        if (tokenState.sBookLogin) {
          if (tokenState.sBookLogin.accessToken != null) {
            this.formRegister.reset();
            this.registerPopup = false;
          } else if (tokenState.sBookLogin.accessToken == null) {
            this.registerloader = false;
          }
        }
        if (tokenState.sBookLoginErrorResponse) {
          this.registerloader = false;
          this.errorMessage = tokenState.sBookLoginErrorResponse.message;
        }
      }
    )
    this.storeSub = this.store.select("loginState").subscribe(
      (loginState: LoginState) => {
        if (loginState.messageforsuccess == true) {
          this.registerloader = false;
          this.registersuccessPop = true;
        } else if (loginState.messageforsuccess == false) {

          this.registerloader = false;
        }


      }
    )
    //this.store.dispatch(new loginActions.RegisterGetCountry());
  }
  generateOTP($event) {
    this.storeSub = this.store.select("loginState").subscribe(
      (loginState: LoginState) => {
        if (loginState.OtpRequestResponse) {
          if (loginState.OtpRequestResponse.success == true) {
            this.otpRequesterrorMessage = "";
            $event.target.disabled = true;
            let numbers = interval(1000);
            let takeFourNumbers = numbers.pipe(take(60));
            takeFourNumbers.subscribe(x => {
              $event.target.innerHTML = x;
              if (x == 59) {
                $event.target.disabled = false;
                $event.target.innerHTML = "Resend OTP";
              }
            });
          } else if (loginState.OtpRequestResponse.success == false) {
            this.otpRequesterrorMessage = loginState.OtpRequestResponse.description;
          }
        }

        if (loginState.OtpRequestErrorResponse) {
          this.otpRequesterrorMessage = loginState.OtpRequestErrorResponse.message;
        }
      }
    )
    this.store.dispatch(new loginActions.RegisterGenarateOTP({ language: 'en', mobile: this.formRegister.get('register.address.phone').value }));
  }
  onFormSubmit() {

    this.registerloader = true;
    this.store.dispatch(new loginActions.ResetState());
    this.formRegister.value.register.nickname = this.formRegister.value.register.login;
    this.store.dispatch(new loginActions.RegisterStart(this.formRegister.value.register));
 

  }
  ngOnDestroy() {
    if (this.storeSub) {
      this.storeSub.unsubscribe();
    }
    if (this.tokenstoreSub) {
      this.tokenstoreSub.unsubscribe();
    }
  }
  closeRegisterPop() {
    this.formRegister.reset();
    this.store.dispatch(new loginActions.ResetState());
    this.registerPopup = false;
  }
  openRegisterPop() {
    this.registerPopup = true;
  }
  registersuccessPopClose() {
    this.store.dispatch(new loginActions.ResetState());
    this.registersuccessPop = false;
  }
  onChange(data) {
    this.selectedcountry = data;
    for(let currencydata of this.currencyvalues){
      if(this.selectedcountry == currencydata.value){
       this.selectedcurrency= currencydata.name;
      }
    }
  }
  
}
