import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-fraudsandactions',
  templateUrl: './fraudsandactions.component.html',
  styleUrls: ['./fraudsandactions.component.css']
})
export class FraudsandactionsComponent implements OnInit {

  constructor() { }

  ngOnInit() {
    
    window.scroll(0,0);
  }

}
