import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { WebsocketService } from './websocket.service';
import { ConditionalExpr } from '@angular/compiler';
import {environment} from '@app/env';
@Injectable({
  providedIn: 'root'
})
export class WebService {
  hostName = window.location.host;
  siteid = environment.skinId;
  folder = 'en';
  //folder = localStorage.getItem('locale');
   //hostName = window.location.host;
   protocal = window.location.protocol;
   //socketUrl = CHAT_URL;
   //socketUrl="ws://"+this.hostName+"/ws";
    socketUrl:string;
  constructor(private wsService: WebsocketService) {
    if (this.protocal == 'https:') {
      this.socketUrl ="wss://"+this.hostName+"/ws?"+this.siteid+","+this.folder
    } else {
      this.socketUrl ="ws://"+this.hostName+"/ws?"+this.siteid+","+this.folder;
      // this.socketUrl ="wss://www.riverpokers.org/ws?rp1,en";
      // this.socketUrl ="ws://192.168.1.108:9090/ws?rp1,en";
      // this.socketUrl ="wss://rp3.czargaming.com//ws?rp1,en";
      // this.socketUrl ="ws://wisdombets-dev.czargaming.com/ws?redsonbet-dev,en";
      //  this.socketUrl ="ws://redsonbet-dev.czargaming.com/ws?redsonbet-dev,en";
      // this.socketUrl ="wss://river.czargaming.com/ws?wisdom,"+this.folder
      
     
    }
    // if(this.protocal=='https:'){
    //   this.socketUrl="wss://"+this.hostName+"/ws";
    // }else{
    //    this.socketUrl="ws://"+this.hostName+"/ws";
    //   //this.socketUrl="ws://river.czargaming.com/ws";
    // }
  }
  send(){
    localStorage.setItem('websocketurl', this.socketUrl);
  this.wsService.connect(this.socketUrl);
  }

}
