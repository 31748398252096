import { Component, OnInit, HostListener, Input, ViewChild, ComponentFactoryResolver } from '@angular/core';
import { SportsMenuComponent } from '../sports-menu/sports-menu.component';
import { UpdateProfileComponent } from '../update-profile/update-profile.component';
import { PasswordsComponent } from '../passwords/passwords.component';
import { Store } from '@ngrx/store';
import * as appState from 'src/app/source/appstates/appState';
import * as cashierActions from 'src/app/source/appstates/cashierstates/cashierActions';
import { LivedealerService } from 'src/app/source/services/livedealer/livedealer.service';
import { MeightService } from 'src/app/source/services/meightservice/meight.service';
import { meightObeject } from 'src/app/source/modules/meight/meightmodal';
import { CockfightService } from 'src/app/source/services/cockfight/cockfight.service';
import { ChangePasswordComponent } from '../change-password/change-password.component';
import { interval, Subscription } from 'rxjs';
import { RegisterComponent } from '../register/register.component';
import * as loginActions from 'src/app/source/appstates/loginstates/loginActions';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { LoginState } from 'src/app/source/appstates/loginstates/loginState';
import { ProfileState } from 'src/app/source/appstates/playerstates/playerState';
import { Profile } from 'src/app/source/modules/player/profile';
import { tokenState } from '../../source/appstates/sblogin/sbloginState';
import { CashierState } from 'src/app/source/appstates/cashierstates/cashierState';
import { Balance, WalletInfo } from 'src/app/source/modules/cashier/balance';
import { ResetPasswordComponent } from '../reset-password/reset-password.component';
import { Router, ActivatedRoute } from '@angular/router';

import { CommondataService } from '../../source/services/sports/commondata.service';
import { SlotsService } from 'src/app/source/services/slots.service';
import { RootObject, Game } from 'src/app/source/modules/slotsmodel/slotsvalue';
import { slotsData } from 'src/app/source/modules/slotsmodel/slotsdata';
import { AllGames, Response } from "src/app/source/modules/slotsmodel/allgames";
import { playerSlotty } from 'src/app/source/appstates/loginstates/loginActions';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { GoldenraceService } from 'src/app/source/services/goldenraceservice/goldenrace.service';
import { GoldenRaceGameLaunch } from 'src/app/source/modules/goldenraceinterface/goldenrace';
declare var $: any;
@Component({
  selector: 'app-navigation',
  templateUrl: './navigation.component.html',
  styleUrls: ['./navigation.component.css']
})
export class NavigationComponent implements OnInit {
  private loginstoreSub: Subscription;
  private tokenstoreSub: Subscription;
  private profilestoreSub: Subscription;
  private storeSub: Subscription;
  playerLoggedIn: boolean = false;
  Login: boolean = true;
  dropMenu: boolean = false;
  openUpdate: boolean = false;
  fieldTextType: boolean;
  text: any;
  reID: any;
  searchdata: boolean = false;
  reType: any;
  balance: any;
  playertype: any;
  cashier: Balance;
  playerbonus: any;
  walleteInfo: WalletInfo[];
  profile: Profile;
  errorMessage: string;
  loginloader: boolean = false;
  form: FormGroup;
  profileName: string;
  private closeSub: Subscription;
  meightObeject: meightObeject;
  token: any;
  urlof: string;
  searchgamesdata;
  navigationsuccessPop: boolean = false;
  gamelunchmessage: string = "";
  rootObject: RootObject;
  game: Array<Game>;
  slotsdata: slotsData;
  tokendata: any = null;
  keyname: string;
  tablegame: Response[];
  
  srcurl:string;
  gamelaunch: GoldenRaceGameLaunch;
  urlSafe:SafeResourceUrl;
  private logoutstore: Subscription;
  @ViewChild(SportsMenuComponent, { static: false })
  sportComp: SportsMenuComponent;

  @ViewChild(UpdateProfileComponent, { static: false }) updatePro: UpdateProfileComponent;
  @ViewChild(PasswordsComponent, { static: false }) passwords: PasswordsComponent;
  @ViewChild(ChangePasswordComponent, { static: false }) changepasswords: ChangePasswordComponent;
  @ViewChild(RegisterComponent, { static: false }) register: RegisterComponent;
  @ViewChild(ResetPasswordComponent, { static: false }) resetPasswordcomp: ResetPasswordComponent;


  constructor(public sanitizer: DomSanitizer, private goldenraceservice: GoldenraceService, private route: ActivatedRoute, private router: Router,
    private CommondataService: CommondataService, private slotsservice: SlotsService,
    private componentFactoryResolver: ComponentFactoryResolver,
    private Cockfightservice: CockfightService, private meightservice: MeightService,
    private store: Store<appState.AppState>, private Livedealerservice: LivedealerService,) {
    this.store.dispatch(new cashierActions.CashierGetBalanceStart());
    this.store.dispatch(new loginActions.allGamesStart());
    this.token = sessionStorage.getItem('accessToken');
    //this.generateCaptchaReset();

    this.slotsservice.getJSON().subscribe(slotdata => {
      this.slotsvaluelunch(slotdata);
    });
  }

  ngOnInit() {
    this.store.dispatch(new loginActions.ResetState());
    this.profileName = sessionStorage.getItem('profile');
    this.form = new FormGroup({
      "login": new FormControl('', Validators.required),
      "password": new FormControl(null, [Validators.required, Validators.minLength(6)]),
      // "captcha": new FormControl(null, Validators.required)
    })
    /************************** Jquery start *****************************/
    $(document).ready(function () {

      $(".liveSport").click(function () {
        $('.liveList').slideDown();
      });

      $(".liveList").mouseleave(function () {
        $('.liveList').delay(1000).slideUp();
      });
      $(".search_sp").click(function () {
        $('.searchDiv').show();
        $('.searchDiv_hide').hide();
      });
      $(".searchClose").click(function () {
        $('.searchDiv').hide();
        $('.searchDiv_hide').show();
      });
      $(".games_span").click(function () {
        $('.topGameList').show();
      });
      $(".down_topgames").click(function () {
        $('.topGameList').hide();
      });

      $(".menu_lines").click(function () {
        $('.coverBlack').show();
        $('.sideMenu').addClass("active");
      });

      $(".coverBlack, .closeMenu").click(function () {
        $('.coverBlack').hide();
        $('.sideMenu').removeClass("active");
      });

      $(".sideMenu ul li").click(function () {
        $('.coverBlack').hide();
        $('.sideMenu').removeClass("active");
      });
      $(".logInpOpen").click(function () {
        $('.logUL').show();
      });
      $(".logInpClose").click(function () {
        $('.logUL').hide();
      });


      $(".slots.nav li").click(function () {
        $('.slots.nav li').removeClass("active");
        $(this).addClass("active");
      });


      var docHeight = $(document).height();
      if ($(docHeight).height < 100) {
      }


      $('.drop_Menu').click(function () {
        $('.drop_Menu').hide();
      });

      $(".navUL li").click(function () {
        $(".navUL li").removeClass("active");
        $(this).addClass("active");
      });
      if ($(window).width() < 768) {
        $('.img_Cas').click(function () {
        $('.subList').toggle();
        })
        }

    });
    /************************** Jquery end *****************************/
    this.loginstoreSub = this.store.select("loginState").subscribe(
      (loginState: LoginState) => {
        if (loginState.loginResponse) {
          if (loginState.loginResponse.success === true) {
            this.errorMessage = null;
            if (loginState.loginResponse.description == "LOGIN_TMP_PASSWORD") {
              this.changePassword();
            }
          } else if (loginState.loginResponse.success === false) {
            this.errorMessage = loginState.loginResponse.description;
            this.loginloader = false;
            interval(200 * 60).subscribe(x => {
              this.errorMessage = "";
              this.store.dispatch(new loginActions.ResetState());
            });
            //this.generateCaptchaReset();
            //this.toastr.error(loginState.loginResponse.description);
          }
        }
        if (loginState.loginErrorResponse) {
          this.errorMessage = loginState.loginErrorResponse.message;
          this.loginloader = false;
          interval(200 * 60).subscribe(x => {
            this.errorMessage = "";
            this.store.dispatch(new loginActions.ResetState());
          });
        }
        if (loginState.playerLoggedIn) {
          this.playerLoggedIn = loginState.playerLoggedIn.loggedIn;
        }
      }
    )
    this.logoutstore = this.store.select("loginState").subscribe(
      (loginState: LoginState) => {
        if (loginState.allgames) {
          if (loginState.allgames) {
            this.tablegame = loginState.allgames.response;
          }
        }
      }
    )
    this.profilestoreSub = this.store.select("playerState").subscribe(
      (playerState: ProfileState) => {
        if (playerState.profile) {
          this.profile = playerState.profile;
          this.profileName = this.profile.login;
        }
      }
    )
    this.tokenstoreSub = this.store.select("tokenState").subscribe(
      (tokenState: tokenState) => {
        if (tokenState.sBookLogin) {
          if (tokenState.sBookLogin.accessToken != null) {
            this.form.reset();
            this.errorMessage = null;
            this.loginloader = false;
            //this.store.dispatch(new cashierActions.CashierGetBalanceStart());
            //this.store.dispatch(new loginActions.ResetState());
          } else if (tokenState.sBookLogin.accessToken == null) {
            this.loginloader = false;
            this.errorMessage = tokenState.sBookLogin.message;
          }
        }

        if (tokenState.sBookLoginErrorResponse) {

          this.loginloader = false;
          this.errorMessage = tokenState.sBookLoginErrorResponse.message;
        }
      }
    )
    this.storeSub = this.store.select("cashierState").subscribe(
      (cashierState: CashierState) => {
        if (cashierState.balance) {
          if (cashierState.balance.success == true) {
            this.loginloader = false;
            this.cashier = cashierState.balance;
            this.walleteInfo = cashierState.balance.values;
            for (let wallet of this.walleteInfo) {
              if (wallet.wallet.name != 'PLAYMONEY') {
                this.balance = wallet.cash.value + wallet.bonus.value;
                this.playertype = wallet.wallet.name;
                sessionStorage.setItem("PlayerCurrency", this.playertype);
                this.playerbonus = wallet.bonus.value;
              } else {
              }
            }
          } else if (cashierState.balance.success == false) {

            this.loginloader = false;
          }
        }

      }
    )
    this.loginstoreSub = this.store.select("loginState").subscribe(
      (loginState: LoginState) => {
        if (loginState.logoutResponse) {
          if (loginState.logoutResponse.success === true) {
            this.loginloader = false;
          } else if (loginState.logoutResponse.success === false) {
            this.loginloader = false;
            //alert('logoutsdgdgdg');
          }
        }


        if (loginState.logoutResponseError) {
          this.loginloader = false;
          // alert('logout');
          this.router.navigate(['/preMatch']);
          sessionStorage.removeItem('accessToken');
          sessionStorage.removeItem('wsession');
          sessionStorage.removeItem('profile');
          sessionStorage.removeItem('playermobilenumber');
          window.location.reload();
        }
      }


    )


  }


  login() {
    this.playerLoggedIn = true;
    this.Login = false;
  }
  proDrop() {
    this.dropMenu = true;
  }

  scrlTop() {
    $(window).scrollTop(450);
  }
  dropHide() {
    this.dropMenu = false;
    $(window).scrollTop(450);
  }
  onClickLogout() {
    this.store.dispatch(new loginActions.ResetState());
    this.store.dispatch(new loginActions.LogoutStart());
    this.router.navigate(['/preMatch']);
  }

  toggleFieldTextType() {
    this.fieldTextType = !this.fieldTextType;
  }
  updateProfile() {
    this.updatePro.openUpdatePop();
  }
  changePassword() {
    this.changepasswords.openPasswordPop();
  }
  forgotPopup() {
    this.passwords.openForgotPasswordPop();
  }
  registrationPopup() {

    this.store.dispatch(new loginActions.ResetState());
    this.register.openRegisterPop()
  }
  resetPassword() {
    this.resetPasswordcomp.openrestpassword();
  }
  onSubmit() {
    this.errorMessage = "";
    this.loginloader = true;
    this.store.dispatch(new loginActions.ResetState());
    this.store.dispatch(new loginActions.LoginStart(this.form.value));

  }
  @HostListener("click", ["$event"])
  public onClick(event: any) {
    this.dropMenu = false;
    $('.CountriesOpen').hide();
    $('.country_List').hide();
    $('.LeaguesOpen').hide();
  }


  @HostListener('window:scroll', ['$event'])
  onWindowScroll(e) {
    if (window.pageYOffset > 150) {
      let Fixit = document.getElementById('mySidenav');
      Fixit.classList.add('fixMenu');
    } else {
      let Fixit = document.getElementById('mySidenav');
      Fixit.classList.remove('fixMenu');
    }
  }
  liveDealer() {
    this.store.dispatch(new cashierActions.CashierGetBalanceStart());
    if (this.playerLoggedIn == true) {
      let logiName = sessionStorage.getItem('profile');
      this.Livedealerservice.liveDealerServiceData(logiName).subscribe(liveDealerdata => {
        this.liveDealerunch(liveDealerdata);
      });
    } else if (this.playerLoggedIn == false) {
      this.gamelunchmessage = "Please login";
      this.navigationsuccessPop = true;
    }
  }

  liveDealerunch(data) {
    let ezugiLunch = data.EZUGI_GAME_LAUNCH_URL;
    window.open(ezugiLunch, '_blank');
  }

  //m8 start

  meightBet() {

    this.store.dispatch(new cashierActions.CashierGetBalanceStart());
    if (this.playerLoggedIn == true) {
      let logiName = sessionStorage.getItem('profile');
      this.meightservice.meightServiceData(logiName).subscribe(meightdata => {
        this.meightLunch(meightdata);
      });
    } else if (this.playerLoggedIn == false) {
      this.gamelunchmessage = "Please login";
      this.navigationsuccessPop = true;
    }
  }
  meightLunch(data) {
    let ua = window.navigator.userAgent.toLowerCase();
    this.meightObeject = data;
    if (this.meightObeject.STATUS == 'FAILED') {
      this.gamelunchmessage = this.meightObeject.STATUS;
      this.navigationsuccessPop = true;
    } else {
      if (ua.match(/ipad/i) !== null)
        return window.open(this.meightObeject.mobileurlsecure, '_blank');
      if (ua.match(/iphone/i) !== null)
        return window.open(this.meightObeject.mobileurlsecure, '_blank');
      if (ua.match(/android/i) !== null)
        return window.open(this.meightObeject.mobileurlsecure, '_blank');
      if (ua.match(/chrome/gi) !== null)
        return window.open(this.meightObeject.weburlsecure, '_blank');
      if (ua.match(/firefox/gi) !== null)
        return window.open(this.meightObeject.weburlsecure, '_blank');
      if (ua.match(/webkit/gi) !== null)
        return window.open(this.meightObeject.weburlsecure, '_blank');
      if (ua.match(/gecko/gi) !== null)
        return window.open(this.meightObeject.weburlsecure, '_blank');
      if (ua.match(/opera/gi) !== null)
        return window.open(this.meightObeject.weburlsecure, '_blank');
      return null



    }
  }

  cockFight() {
    this.store.dispatch(new cashierActions.CashierGetBalanceStart());
    if (this.playerLoggedIn == true) {
      let logiName = sessionStorage.getItem('profile');
      this.Cockfightservice.cockFightServiceData(logiName).subscribe(cockfightdata => {
        this.cockFightLunch(cockfightdata);
      });
    } else if (this.playerLoggedIn == false) {
      this.gamelunchmessage = "Please login";
      this.navigationsuccessPop = true;
    }
  }
  cockFightLunch(data) {
    let sabongLunch = data.SABONG_GAME_LAUNCH_URL;
    window.open(sabongLunch, '_blank');
  }
  comingsoon() {
    this.passwords.comingSoonPopOpen()

  }

  /*
      function openNav() {
        document.getElementById("mySidenav").style.width = "200px";
      }
      function closeNav() {
        document.getElementById("mySidenav").style.width = "0";
      }*/


  navigationPopClose() {
    this.navigationsuccessPop = false;
  }


  glodenracegamelunch() {
    if (this.playerLoggedIn == true) {
      this.router.navigate(['/virtuals']);
    } else if (this.playerLoggedIn == false) {
      this.gamelunchmessage = "Please login";
      this.navigationsuccessPop = true;
    }
  }
  changeSearch(data) {
    if (data.length != 0) {
      this.searchdata = true;
    } else {
      this.searchdata = false;
    }
  }
  slotsvaluelunch(slotdata) {
    this.rootObject = slotdata;
    if (this.rootObject) {
      this.game = this.rootObject.Games;
    }
  }
  hitService() {
    let profile = sessionStorage.getItem('profile');
    this.slotsservice.httpClient(profile).subscribe(data => { this.slotslunch(data) });
  };
  slotsinlogout(data) {
    if (this.playerLoggedIn == true) {
      this.store.dispatch(new cashierActions.CashierGetBalanceStart());
      this.keyname = data;
      if (this.tokendata == null) {
        this.hitService();
      } else if (this.tokendata != null) {
        window.open(this.slotsdata.HABANERO_GAMING_URL + 'brandid=' + this.slotsdata.BRAND_ID + '&keyname=' + data + '&token=' + this.slotsdata.TOKEN + '&mode=real&locale=en', "_blank");
        return;
      }
    } else if (this.playerLoggedIn == false) {
      this.gamelunchmessage = "Please login";
      this.navigationsuccessPop = true;
    }
  }
  slotslunch(data) {
    if (data) {
      this.slotsdata = data;
      this.tokendata = this.slotsdata.TOKEN;
      return window.open(this.slotsdata.HABANERO_GAMING_URL + 'brandid=' + this.slotsdata.BRAND_ID + '&keyname=' + this.keyname + '&token=' + this.slotsdata.TOKEN + '&mode=real&locale=en', "_blank");

    }
  }
  closesearch() {
    this.searchdata = false;
  }
  tableGameLunch(id: any, providername: any) {
    if (this.playerLoggedIn == true) {
      const body = {
        gameId: id,
        gameMode: 'real_play',
        provider: providername
      }
      this.store.dispatch(new loginActions.allGamesLunchStart(body));
    } else {
      const body = {
        gameId: id,
        gameMode: 'demo_play',
        provider: providername
      }
      this.store.dispatch(new loginActions.allGamesLunchStart(body));
    }
  }
  casinogames(data) {
    this.store.dispatch(new loginActions.ResetState());
    this.store.dispatch(new playerSlotty({ idvalue: data }))
    this.router.navigate(["/casino"]);
  }
  
  hitgamelunch(id) {
    let gameid = id;
      let ua = window.navigator.userAgent.toLowerCase();
      if (ua.match(/ipad/i) !== null) {
        let devicename = 'mobile';
        this.hitservice(gameid, devicename);
      } else if (ua.match(/iphone/i) !== null) {
        let devicename = 'mobile';
        this.hitservice(gameid, devicename);
      } else if (ua.match(/android/i) !== null) {
        let devicename = 'mobile';
        this.hitservice(gameid, devicename);
      } else if (ua.match(/chrome/gi) !== null) {
        let devicename = 'desktop';
        this.hitservice(gameid, devicename);
      } else if (ua.match(/firefox/gi) !== null) {
        let devicename = 'desktop';
        this.hitservice(gameid, devicename);
      } else if (ua.match(/webkit/gi) !== null) {
        let devicename = 'desktop';
        this.hitservice(gameid, devicename);
      } else if (ua.match(/gecko/gi) !== null) {
        let devicename = 'desktop';
        this.hitservice(gameid, devicename);
      } else if (ua.match(/opera/gi) !== null) {
        let devicename = 'desktop';
        this.hitservice(gameid, devicename);
      } else {
        let devicename = 'desktop';
        this.hitservice(gameid, devicename);
      }
   
  }
  hitservice(id, devicename) {
    
    this.urlSafe="";
    if (this.playerLoggedIn == true) {
      this.store.dispatch(new cashierActions.CashierGetBalanceStart());
      let profile = sessionStorage.getItem('profile');
      this.goldenraceservice.httpClient(profile, id, devicename).subscribe((data) => { this.gamelunchurl(data) }, (err) => { this.gamelauncherror(err) });
    }else if(this.playerLoggedIn == false) {
       this.goldenraceservice.httpClientfun(id, devicename).subscribe((data) => { this.gamelunchurl(data) }, (err) => { this.gamelauncherror(err) });
    }
 
  }
  gamelunchurl(data) {
    this.gamelaunch = data;
    if (this.gamelaunch.Message == "Success") {
      this.gamelunchmessage="";
      this.srcurl = this.gamelaunch.launch;
      // this.urlSafe= this.sanitizer.bypassSecurityTrustResourceUrl(this.srcurl);
      window.open(this.gamelaunch.launch, '_blank');
      //window.location.replace(this.gamelaunch.launch);
    } else {
      this.urlSafe="";
      this.gamelunchmessage = this.gamelaunch.Message;
      this.navigationsuccessPop = true;
    }
  }
  gamelauncherror(error) {
    this.urlSafe="";
    this.gamelunchmessage = error;
    this.navigationsuccessPop = true;
  }
}
