import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import {environment} from '@app/env';
import { Countries } from '../../modules/login/countries';
import { Login } from '../../modules/login/login';
import { PlayerService } from '../player/player.service';
import { Store } from '@ngrx/store';
import * as appState from '../../appstates/appState';
import { playerLoggedIn, LogoutStart, registermessage } from '../../appstates/loginstates/loginActions';
import { PlayerGetProfile } from '../../appstates/playerstates/playerActions';
import { Observable } from 'rxjs';
import { CashierGetBalanceStart } from '../../appstates/cashierstates/cashierActions';
import { ResetPassword } from '../../modules/login/resetpassword';
import { GrGamesLunch } from '../../appstates/sblogin/sbloginAction';
import { PrintLeaguesRes } from '../../modules/common/printfeedleagues';
import { PrintFeedOddsRes } from '../../modules/common/printfeedodds';
import { AllGames } from '../../modules/slotsmodel/allgames';
import { AllGameLunch } from '../../modules/slotsmodel/allgamelunch';
import { Indexs } from '../../modules/login/indexs';
@Injectable({
  providedIn: 'root'
})
export class LoginService {

  private _jsonURL = 'assets/Print_json.json';
  
  private _AllGames = 'assets/MrSlottyGames.json';

  private _indexJson = 'assets/index.json'; 

  constructor(private httpClient: HttpClient,
    private playerService: PlayerService,
    private store: Store<appState.AppState>) { }


  httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
      'siteid': environment.skinId
    })
  };
  headers = new HttpHeaders({
    'Content-Type': 'application/json',
    'siteid': environment.skinId
  });

  indexsForReport() {
    return this.httpClient.get<Indexs>(this._indexJson);
  }

  onGetPlayerBalance(){
    this.store.dispatch(new CashierGetBalanceStart());
    this.store.dispatch(new GrGamesLunch());
  }
  sessinExpireToLogout(){
    this.store.dispatch(new LogoutStart());
  }
  onplayerLoggedIn(value: boolean) {
    this.store.dispatch(new playerLoggedIn({ loggedIn: value }));
  }

  onLogin(postdata) {
    return this.httpClient.post<Login>(`${environment.appApi.baseUrl}${environment.appApi.login}`, postdata, this.httpOptions);
  }
  // onGenerateCaptchaStart():Observable<Blob>{
  //   return this.httpClient.post(`${environment.appApi.baseUrl}${environment.appApi.generateCaptcha}`,
  //    {},{headers: this.headers, responseType:'blob'});
  // }
  onLogOut() {
    return this.httpClient.post(`${environment.appApi.baseUrl}${environment.appApi.logout}`, {}, this.playerService.httpOptions());
  }
  onRegister(postdata) {
    return this.httpClient.post<Login>(`${environment.appApi.baseUrl}${environment.appApi.register}`, postdata, this.httpOptions);
  }
  onGetRegisterCountry() {
    return this.httpClient.post<Countries>(`${environment.appApi.baseUrl}${environment.appApi.getCountries}`, {}, this.httpOptions);
  }
  onForgotPassword(postdata) {
    return this.httpClient.post(`${environment.appApi.baseUrl}${environment.appApi.fotgotPassword}`, postdata, this.httpOptions);
  }
  onResetPassword(postdata) {
    return this.httpClient.post<ResetPassword>(`${environment.appApi.baseUrl}${environment.appApi.resetPassword}`, postdata, this.httpOptions);
  }
  printFeedOddsData(postdata) {
    return this.httpClient.post<PrintFeedOddsRes>(`${environment.sbBook.sbBaseUrl}${environment.sbBook.printodds}`, postdata, this.httpOptions);
    //return this.httpClient.post<PrintFeedOddsRes>('http://10.10.20.54:9090/sbapi/pms/print/odds', postdata, this.httpOptions);
    
  }
  // public printFeedOddsData(postdata): Observable<any> {
  //   return this.httpClient.get(this._jsonURL);
  // }
  getMyprofile(){
    this.store.dispatch(new PlayerGetProfile());
  }
  registerMessage(){
    
    this.store.dispatch(new registermessage());
  }
  onRegisterGenarateOTPStart(postdata) {
    return this.httpClient.post(`${environment.appApi.baseUrl}${environment.appApi.generateOTP}`, postdata, this.httpOptions);
  }
  AllGamesLunch(postdata) {
    if(postdata.gameMode == 'real_play'){
      return this.httpClient.post<AllGameLunch>(`${environment.appApi.baseUrl}${environment.appApi.allGamesUrl}`, postdata, this.playerService.httpOptions());
    }else{
      return this.httpClient.post<AllGameLunch>(`${environment.appApi.baseUrl}${environment.appApi.funGamesUrl}`, postdata, this.httpOptions);
    }
  }
  printFeedLeaguesData(sportname){
    return this.httpClient.get<PrintLeaguesRes>(`${environment.sbBook.sbBaseUrl}${environment.sbBook.printleagues}/${sportname}`, this.httpOptions);
  }
  // AllGamesData(){
  //   return this.httpClient.get<AllGames>(`${environment.mrslotty}`);
  // }
  AllGamesData(){
    return this.httpClient.get<AllGames>(this._AllGames);
  }
  onContactUsSendStats(postdata){
    return this.httpClient.post(`${environment.appApi.baseUrl}${environment.appApi.contactus}`,postdata,this.httpOptions);
  }
}
