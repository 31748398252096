import { Component, OnInit } from '@angular/core';
import { tokenState } from '../../../source/appstates/sblogin/sbloginState';
import { LoginState } from 'src/app/source/appstates/loginstates/loginState';
import { Store } from '@ngrx/store';
import * as appState from 'src/app/source/appstates/appState';
import { Subscription } from 'rxjs';
@Component({
  selector: 'app-promotions',
  templateUrl: './promotions.component.html',
  styleUrls: ['./promotions.component.css']
})
export class PromotionsComponent implements OnInit {
  token: any;
  playerLoggedIn:boolean = false;
  
  private bettokenstoreSub: Subscription;
  private logoutstore: Subscription;

  constructor(private store: Store<appState.AppState>) { }

  ngOnInit() {
    window.scroll(0,0);
    this.logoutstore = this.store.select("loginState").subscribe(
      (loginState: LoginState) => {
      if (loginState.playerLoggedIn) {
      this.playerLoggedIn = loginState.playerLoggedIn.loggedIn;
      
      }
      }
      )
   
  }

}
