
import { Component, OnInit, OnDestroy } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Store } from '@ngrx/store';
import * as appState from 'src/app/source/appstates/appState';
import * as loginActions from 'src/app/source/appstates/loginstates/loginActions';
import { Subscription } from 'rxjs';
import { LoginState } from 'src/app/source/appstates/loginstates/loginState';
import { ResetPassword } from 'src/app/source/modules/login/resetpassword';
@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.css']
})
export class ResetPasswordComponent implements OnInit, OnDestroy {
  resetform:FormGroup;
  openpopup:boolean=false;
  private storeSub: Subscription;
  resetPasswordErrorMessage:string = "";
  ResetPassword:ResetPassword;
  resetPasswordSuccessmessage :string =  "";
  resetpsdsuccess:boolean=false;
  constructor(private store: Store<appState.AppState>) {
    
    this.store.dispatch(new loginActions.ResetState());
   }

  ngOnInit() {
    
    this.store.dispatch(new loginActions.ResetState());
    this.resetform = new FormGroup({
      "email": new FormControl(null, [Validators.required, Validators.email]),
      "login": new FormControl(null, Validators.required)
    })
    this.storeSub = this.store.select("loginState").subscribe(
      (loginState: LoginState) => {
        if (loginState.ResetPassword) {
          if (loginState.ResetPassword.success === true) {
            this.resetform.reset();
            this.openpopup = false;
          this.resetPasswordErrorMessage =  "";
          this.resetpsdsuccess=true;
          this.resetPasswordSuccessmessage = "Temporary Password has sent to your email, please login and update the password immediately.";
          } else if (loginState.ResetPassword.success === false) {
            this.resetpsdsuccess=false;
            this.resetPasswordSuccessmessage =  "";
          this.resetPasswordErrorMessage =  loginState.ResetPassword.description;
          //this.resetPasswordErrorMessage =  "Invalid Data";
          }
        }
        if(loginState.ResetPasswordError)
        {
          this.resetpsdsuccess=false;
          this.resetPasswordSuccessmessage =  "";
          this.resetPasswordErrorMessage =  loginState.ResetPasswordError.message;
        }
      }
    )
  }
  onResetPasswordFormSubmit() {
    this.store.dispatch(new loginActions.ResetState());
    this.store.dispatch(new loginActions.ResetPasswordStart(this.resetform.value))
  }
  ngOnDestroy() {
    if (this.storeSub) {
      this.storeSub.unsubscribe();
    }
  }
  openrestpassword(){
    this.openpopup=true;
  }
  closeresetPasswordPop(){
    this.openpopup=false;
  }
  resetpsdsuccessPopClose(){
    this.resetpsdsuccess=false;
    this.store.dispatch(new loginActions.ResetState());
  }
}
