import { Component, OnInit, OnDestroy } from '@angular/core';
import { Store } from '@ngrx/store';
import { AppState } from 'src/app/source/appstates/appState';
import { Subscription } from 'rxjs';
import { WalletInfo } from 'src/app/source/modules/cashier/balance';
import { CashierState } from 'src/app/source/appstates/cashierstates/cashierState';
import { CashierGetBalanceStart } from 'src/app/source/appstates/cashierstates/cashierActions';
import * as playerActions from 'src/app/source/appstates/playerstates/playerActions';
import { Router } from '@angular/router';
import { LoginState } from 'src/app/source/appstates/loginstates/loginState';

@Component({
  selector: 'app-balance',
  templateUrl: './balance.component.html',
  styleUrls: ['./balance.component.css']
})
export class BalanceComponent implements OnInit, OnDestroy {
  private storeSub: Subscription;
  private loginSub: Subscription;
  walleteInfo: WalletInfo[];
  preferredBalance: WalletInfo;
  playerLoggedIn: boolean = false;
  constructor(private store: Store<AppState>,
    private router: Router) { 
  
  }
  ngOnInit() {
    
    this.store.dispatch(new playerActions.ResetState());
    this.loginSub = this.store
    .select("loginState")
    .subscribe((loginState: LoginState) => {
      if (loginState.playerLoggedIn) {
        this.playerLoggedIn = loginState.playerLoggedIn.loggedIn;
      }
    });
    this.storeSub = this.store.select("cashierState").subscribe(
      (cashierState: CashierState) => {
        if (cashierState.balance) {
          // this.cashier = cashierState.balance;
          this.walleteInfo = cashierState.balance.values;
           for (let wallete of this.walleteInfo){
            if(wallete.preferred === true){
              this.preferredBalance = wallete;
              break;
            }
          } 
        }
      }
    )
    this.store.dispatch(new CashierGetBalanceStart());
  }

  ngOnDestroy() {
    if (this.storeSub) {
      this.storeSub.unsubscribe();
    }
  }
}
